<template>
  <div class="row row-cols-1 gy-3">
    <div class="col">
      <div v-if="inspection_item.success_options.length > 0">
        <button
          v-for="option in inspection_item.success_options"
          :key="option"
          class="btn btn-lg btn-success w-100 py-3 mb-2"
        >
          {{ option }}
        </button>
      </div>
      <button v-else class="btn btn-lg btn-success w-100 py-4">
        <i class="bi bi-check-circle-fill me-2"></i> 確認しました
      </button>
    </div>
    <div class="col">
      <div v-if="inspection_item.failure_options.length > 0">
        <button
          v-for="option in inspection_item.failure_options"
          :key="option"
          class="btn btn-lg btn-danger w-100 py-3 mb-2"
        >
          {{ option }}
        </button>
      </div>
      <button v-else class="btn btn-lg btn-danger w-100 py-4">
        <i class="bi bi-x-circle-fill me-2"></i> NGでした
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InspectionSelect',

  props: ['inspection_item'],
}
</script>
