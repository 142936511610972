<template>
  <Navbar />

  <div class="container pt-4">
    <div class="row row-cols-2 g-3">
      <div class="col">
        <router-link
          :to="{
            name: 'OperateOrderList',
          }"
          class="btn btn-lg btn-outline-primary w-100 py-3"
          ><i
            class="bi bi-box-arrow-in-right d-block"
            style="font-size: 2rem"
          ></i
          >受注処理</router-link
        >
      </div>
      <div class="col">
        <a
          @click="notAvailable()"
          class="btn btn-lg btn-outline-info w-100 py-3"
          ><i class="bi bi-box-arrow-right d-block" style="font-size: 2rem"></i
          >発注処理</a
        >
      </div>
      <div class="col">
        <router-link
          :to="{
            name: 'AppHomeOperate',
          }"
          class="btn btn-lg btn-outline-dark w-100 py-3"
          ><i class="bi bi-house d-block" style="font-size: 2rem"></i
          >ホームに戻る</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '../components/OrderNavbar.vue'

export default {
  name: 'OperateOrder',
  components: {
    Navbar,
  },

  created() {
    this.$store.dispatch('clearDataStores')
  },

  methods: {
    notAvailable() {
      this.$store.dispatch('alert/setInfoMessage', {
        message: 'この機能は現在利用できません。',
      })
    },
  },
}
</script>
