import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
  detail: {},
  list: [],
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  results: (state) => state.data.results,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  detail: (state) => state.detail,
  list: (state) => state.list,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = []),
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
  setList: (state, list) => (state.list = list),
  clearList: (state) => (state.list = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearDetail')
      commit('clearList')
      resolve()
    })
  },
  /**
   * リストデータを取得する
   *
   * @param {object} queryParams
   * @return {object}
   */
  async fetchList({ commit }, queryParams) {
    const response = await api.get('operate/stocks/list/', {
      params: queryParams,
    })
    commit('setList', response.data)
  },
  /**
   * 一覧取得
   *
   * @param {object} queryParams
   * @return {object}
   */
  async fetchAll({ commit }, queryParams) {
    const response = await api.get('operate/stocks/', {
      params: queryParams,
    })
    commit('setData', response.data)
  },
  /**
   * 詳細取得
   *
   * @param {integer} id
   * @return {object}
   */
  async fetchData({ state, commit }, id) {
    if (Object.keys(state.detail).length > 0 && state.detail.id == id) {
      return
    }

    const response = await api.get('operate/stocks/' + id + '/')
    commit('setDetail', response.data)
  },
  /**
   * データ再取得
   *
   * @param {integer} id
   * @return {object}
   */
  async refetchData({ commit }, id) {
    const response = await api.get('operate/stocks/' + id + '/')
    commit('setDetail', response.data)
  },
  /**
   * データ検索
   *
   * @param {object} queryParams { code: 123456 }
   * @return {object}
   */
  async searchData({ commit }, queryParams) {
    const response = await api.get('operate/stocks/search/', {
      params: queryParams,
    })
    commit('setDetail', response.data)
  },
  /**
   * 入庫
   *
   * @param {object}
   * @return {object}
   */
  async incoming({ state, dispatch }, formData) {
    if ('stock_location' in formData) {
      formData.stock_location_id = formData.stock_location.id
    }

    // 必要なデータのみにする
    const requiredKeys = ['quantity', 'reason', 'stock_location_id']
    const submitData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) =>
        requiredKeys.includes(key)
      )
    )

    try {
      const detail = state.detail
      await api.post('operate/stocks/' + detail.id + '/incoming/', submitData)
      await dispatch('refetchData', detail.id)
    } catch (error) {
      throw error
    }
  },
  /**
   * 出庫
   *
   * @param {object}
   * @return {object}
   */
  async outgoing({ state, dispatch }, formData) {
    if ('stock_location' in formData) {
      formData.stock_location_id = formData.stock_location.id
    }

    const requiredKeys = ['quantity', 'reason', 'stock_location_id']
    const submitData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) =>
        requiredKeys.includes(key)
      )
    )

    try {
      const detail = state.detail
      await api.post('operate/stocks/' + detail.id + '/outgoing/', submitData)
      await dispatch('refetchData', detail.id)
    } catch (error) {
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
