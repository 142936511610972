<template>
  <Navbar>
    <template v-slot:navbarTitle>製品検査</template>
  </Navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="d-flex gap-2 mb-3">
      <h5 class="m-0 p-0"><i class="bi bi-folder me-1"></i>検査種別一覧</h5>
      <div class="ms-auto">
        <a v-on:click.prevent="showTypeCreateForm()" class="btn btn-primary">
          <span class="d-block d-sm-none"><i class="bi bi-plus-lg"></i></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-plus-lg me-1"></i>検査種別を追加する</span
          >
        </a>
      </div>
    </div>

    <div class="d-flex justify-content-end gap-2 mb-2">
      <Pagination :prev="prev" :next="next" @move-page="movePage" />
    </div>

    <!-- results -->
    <div v-if="results && results.length > 0">
      <div class="table-responsive">
        <table class="table table-bordered bg-white">
          <thead>
            <tr>
              <th scope="col" class="col-sm-2">検査種別</th>
              <th scope="col" class="col-sm-3">番号生成ルール</th>
              <th scope="col" class="col-sm-1">有効</th>
              <th scope="col" class="col-sm-2">作成日時</th>
              <th scope="col" class="col-sm-2">更新日時</th>
              <th scope="col" class="col-sm-2"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="result in results" :key="result.id">
              <td scope="row" class="text-center">
                <a>{{ result.name }}</a>
              </td>
              <td>{{ result.inspection_result_number_rule_name }}</td>
              <td class="text-center">
                <ActiveStatus :is_active="result.is_active" />
              </td>
              <td class="text-end">{{ result.created_at }}</td>
              <td class="text-end">{{ result.updated_at }}</td>
              <td class="text-end">
                <a
                  href="#"
                  v-on:click.prevent="showTypeUpdateForm(result)"
                  class="text-success text-decoration-none"
                  >編集</a
                >｜<a
                  v-on:click.prevent="removeData(result.id)"
                  href="#"
                  class="text-danger text-decoration-none"
                  >削除</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>

    <InspectionTypeCreateForm ref="typeCreateForm" @insert-type="insertType" />
    <InspectionTypeUpdateForm ref="typeUpdateForm" @update-type="updateType" />
    <Confirm ref="confirmDataDelete" @confirm-true="confirmDataDelete" />
  </main>
</template>

<script>
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import ActiveStatus from '@/components/TheActiveStatus.vue'
import Confirm from '@/components/TheConfirm.vue'
import InspectionTypeCreateForm from '../components/InspectionTypeCreateForm.vue'
import InspectionTypeUpdateForm from '../components/InspectionTypeUpdateForm.vue'

export default {
  name: 'ManageInspectionType',
  components: {
    Navbar,
    Pagination,
    ActiveStatus,
    Confirm,
    InspectionTypeCreateForm,
    InspectionTypeUpdateForm,
  },

  data() {
    return {
      current_id: null,
      query: {},
    }
  },
  computed: {
    results() {
      return store.getters['inspection_type/results']
    },
    count() {
      return store.getters['inspection_type/count']
    },
    prev() {
      return store.getters['inspection_type/prev']
    },
    next() {
      return store.getters['inspection_type/next']
    },
    current_page() {
      return store.getters['inspection_type/current_page']
    },
    total_pages() {
      return store.getters['inspection_type/total_pages']
    },
  },

  async created() {
    await this.getData()
  },

  methods: {
    getData(query = {}) {
      return store.dispatch('inspection_type/fetchAll', query)
    },
    movePage(page) {
      this.getData(Object.assign(this.query, { page: page }))
    },
    getQueryResult() {
      this.getData(this.query)
    },
    showTypeCreateForm() {
      this.$refs.typeCreateForm.show()
    },
    showTypeUpdateForm(data) {
      this.$refs.typeUpdateForm.show(data)
    },
    insertType(data) {
      this.results.push(data)
    },
    updateType(data) {
      let index = this.results.findIndex(({ id }) => id === data.id)
      this.results[index] = data
    },
    removeData(id) {
      this.current_id = id
      this.$refs.confirmDataDelete.showConfirm(
        'データを削除',
        '検査種別を削除しますか？\nすでに使用している場合は削除できません。'
      )
    },
    async confirmDataDelete() {
      await store
        .dispatch('inspection_type/deleteData', this.current_id)
        .then(() => {
          let index = this.results.findIndex(({ id }) => id === this.current_id)
          this.results.splice(index, 1)
        })
        .catch((e) => {
          let message = '削除できませんでした。'
          if (e.status === 304) {
            message = 'この検査種別は使用しています。'
          }
          this.$store.dispatch('alert/setErrorMessage', {
            message: message,
          })
        })

      this.current_id = null
    },
  },
}
</script>
