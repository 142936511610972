<template>
  <VueMultiselect
    v-model="selectedValue"
    @update:modelValue="updateValue"
    :options="options"
    :multiple="true"
    :searchable="false"
    label="name"
    track-by="id"
    placeholder="選択してください"
    :selectLabel="''"
    :selectedLabel="'選択中'"
    :deselectLabel="'選択解除'"
    :showNoOptions="false"
    :showNoResults="false"
    :id="id"
    :class="{ 'is-invalid text-danger': error }"
  >
  </VueMultiselect>
  <div v-if="error" class="invalid-feedback">
    {{ error }}
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'

export default {
  name: 'FormMultiSelect',
  components: {
    VueMultiselect,
  },

  props: {
    modelValue: {
      type: Array,
      default: [],
    },
    options: {
      type: Array,
      default: [],
    },
    error: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue', 'getOptions'],

  data() {
    return {
      selectedValue: this.modelValue,
    }
  },
  watch: {
    modelValue(newVal) {
      this.selectedValue = newVal
    },
  },

  async created() {
    await this.$emit('getOptions')
  },

  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', value)
    },
  },
}
</script>
