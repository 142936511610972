<template>
  <nav class="navbar navbar-expand navbar-dark bg-dark">
    <div class="container">
      <span class="navbar-brand"
        ><slot name="navbarTitle">{{ title }}</slot>
      </span>
      <span class="ms-auto"><slot name="navbarButton"></slot></span>
      <span class="navbar-text"
        ><slot name="navbarSubTitle"
          ><i
            class="bi bi-person-circle ms-2"
            ref="popoverIcon"
            @click="togglePopover"
          ></i></slot
      ></span>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import { Popover } from 'bootstrap'

export default {
  name: 'OperateNavbar',
  components: {},

  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      popoverInstance: null,
    }
  },
  computed: {
    ...mapGetters({
      userData: 'auth/userData',
    }),
    display_name: function () {
      return this.userData('display_name')
    },
  },

  mounted() {
    this.popoverInstance = new Popover(this.$refs.popoverIcon, {
      container: 'body',
      content: this.display_name,
      placement: 'bottom',
      trigger: 'manual',
    })
    // ドキュメント全体にクリックイベントリスナーを追加
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount() {
    if (this.popoverInstance) {
      this.popoverInstance.dispose()
    }
  },

  methods: {
    togglePopover() {
      if (this.popoverInstance) {
        this.popoverInstance.toggle()
      }
    },
    handleClickOutside(event) {
      if (
        this.popoverInstance &&
        !this.$refs.popoverIcon?.contains(event.target) &&
        !document.querySelector('.popover')?.contains(event.target)
      ) {
        this.popoverInstance.hide()
      }
    },
  },
}
</script>

<style scoped>
.nav-item {
  margin-left: 0.5rem;
}
</style>
