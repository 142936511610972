<template>
  <div class="card mb-3">
    <h5 class="card-header bg-secondary">仕入管理</h5>
    <div class="card-body">
      <div class="row row-cols-1 row-cols-md-2 g-1">
        <div class="col">
          <router-link
            :to="{
              name: 'ManagePurchaseDefectiveItemList',
            }"
            class="btn btn-lg btn-secondary w-100 py-3"
            ><i class="bi bi-file-earmark-text d-block"></i
            >不良品情報</router-link
          >
        </div>
        <div class="col">
          <router-link
            :to="{
              name: 'ManagePurchaseDefectiveItemAggregate',
            }"
            class="btn btn-lg btn-secondary w-100 py-3"
            ><i class="bi bi-bar-chart d-block"></i>不良品集計</router-link
          >
        </div>
        <div class="col">
          <router-link
            :to="{
              name: 'ManagePurchaseDefectiveItemCauseList',
            }"
            class="btn btn-lg btn-outline-secondary w-100 py-3"
            >不良原因</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PurchaseMenu',
}
</script>
