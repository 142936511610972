/**
 * 文字列操作に関するユーティリティ関数
 * @module stringUtils
 */

/**
 * 8文字のランダムな文字列を生成する
 *
 * @param {number} [length=8] 生成する文字列の長さ
 * @returns {string} 8文字のランダムな文字列
 */
export const randomString = (length = 8) => {
  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789'
  let randStr = ''
  for (let i = 0; i < length; i++) {
    randStr += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return randStr
}

/**
 * 指定された長さより長い文字列を短くし、「...」を末尾に追加する
 *
 * @param {string} string 短縮する対象の文字列
 * @param {number} [length=8] 文字列を短縮する長さ
 * @returns {string} 短縮された文字列
 */
export const textWrap = (string, length = 8) => {
  if (typeof string == 'string' && string.length > length) {
    return string.substring(0, length) + '…'
  }
  return string
}

/**
 * 文字列の先頭から最初の空白までを残し、それ以降を削除する
 *
 * @param {string} string - 入力文字列
 * @returns {string} 先頭から最初の空白までの部分文字列
 */
export const trimToWhiteSpace = (string) => {
  if (typeof string == 'string') {
    return string.match(/^([\S]+.*?)+.*?$/u)[1]
  }
  return string
}

/**
 * 文字列からすべての空白文字を削除する
 *
 * @param {string} string - 入力文字列
 * @returns {string} 空白文字を削除した文字列
 */
export const removeWhitespace = (string) => {
  if (typeof string == 'string') {
    return string.replace(/\s+/g, '')
  }
  return string
}

/**
 * 文字列の末尾の空白を削除する
 *
 * @param {string} string - 入力文字列
 * @returns {string} 末尾の空白を削除した文字列
 */
export const removeTrailingWhitespace = (string) => {
  if (typeof string == 'string') {
    return string.replace(/\s+$/g, '')
  }
  return string
}

/**
 * 文字列の先頭から指定した桁数までを残し、それ以降を削除する
 *
 * @param {string} string - 入力文字列
 * @param {number} digits - 残す桁数
 * @returns {string} 指定した桁数までの部分文字列
 */
export const trimToDigits = (string, digits) => {
  if (typeof string === 'string' && typeof digits === 'number') {
    return string.slice(0, digits)
  }
  return string
}

/**
 * 文字列の末尾から指定した桁数分を削除する
 *
 * @param {string} string - 入力文字列
 * @param {number} digits - 削除する桁数
 * @returns {string} 指定した桁数分を削除した文字列
 */
export const removeLastDigits = (string, digits) => {
  if (typeof string === 'string' && typeof digits === 'number') {
    return string.slice(0, -digits)
  }
  return string
}

/**
 * URLの検証を行う関数
 * @param {string} url - 検証するURL文字列
 * @returns {boolean} - 有効なURLの場合はtrue、それ以外はfalseを返す
 */
export const isURL = (url) => {
  const urlPattern = /^https?:\/\/.+$/
  return urlPattern.test(url)
}

/**
 * クリップボードコピー関数
 * 入力値をクリップボードへコピーする
 *
 * @param string: 入力値
 * @return true: 成功、false: 失敗
 */
export const copyToClipboard = (string) => {
  let temp = document.createElement('div')
  temp.appendChild(document.createElement('pre')).textContent = string

  let s = temp.style
  s.position = 'fixed'
  s.left = '-100%'

  document.body.appendChild(temp)
  document.getSelection().selectAllChildren(temp)

  let result = document.execCommand('copy')
  document.body.removeChild(temp)
  // true なら実行できている falseなら失敗か対応していないか
  return result
}

/**
 * 文字列をキャメルケースに変換する
 *
 * @param {string} string - 入力文字列
 * @returns {string} キャメルケースに変換した文字列
 * @example toCamelCase('foo_bar') // fooBar
 */
export const toCamelCase = (string) => {
  return string.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', '')
  )
}

/**
 * 改行を<br>に変換する
 *
 * @param {string} string - 入力文字列
 * @returns {string} <br>に変換した文字列
 * @example replaceNewlinesWithBr('foo\nbar') // foo<br>bar
 */
export const replaceNewlinesWithBr = (string) => {
  if (string) {
    return string.replace(/\r?\n/g, '<br>')
  }
}

/**
 * 全角数字を半角に変換する
 *
 * @param {string} string - 入力文字列
 * @returns {string} 半角に変換した文字列
 */
export const toHalfWidthNumbers = (string) => {
  if (string) {
    return string.replace(/[０-９．]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 65248)
    })
  }
}

/**
 * 半角数字とハイフン以外を削除する
 *
 * @param {string} string - 入力文字列
 * @param {number} length - 文字列の長さ
 * @returns {string} 半角数字とハイフンの文字列
 */
export const removeNonNumericAndHyphen = (string, length) => {
  if (string) {
    // 全角の0〜9と長音符、ハイフンを半角に変換
    string = string.replace(/[０-９－ー]/g, function (s) {
      // 長音符を半角ハイフンにする
      if (s === 'ー') {
        return '-'
      } else {
        return String.fromCharCode(s.charCodeAt(0) - 65248)
      }
    })
    return string.replace(/[^0-9-]/g, '').slice(0, length)
  }
}

/**
 * 円表示に変換する
 *
 * @param {integer} num
 * @returns
 */
export const toCommaSeparatedNumber = (num) => {
  if (num !== null && num !== undefined) {
    return Number(num).toLocaleString('ja-JP')
  }
}

/**
 * バイトをKB,MB,GBに変換する
 *
 * @param {integer} byte
 * @returns
 */
export const toByteUnit = (byte) => {
  if (byte < 1024) {
    return byte + 'B'
  } else if (byte < 1024 * 1024) {
    return Math.round(byte / 1024) + 'KB'
  } else if (byte < 1024 * 1024 * 1024) {
    return Math.round(byte / 1024 / 1024) + 'MB'
  } else {
    return Math.round(byte / 1024 / 1024 / 1024) + 'GB'
  }
}

export default {
  randomString,
  textWrap,
  trimToWhiteSpace,
  removeWhitespace,
  removeTrailingWhitespace,
  trimToDigits,
  removeLastDigits,
  isURL,
  copyToClipboard,
  toCamelCase,
  replaceNewlinesWithBr,
  toHalfWidthNumbers,
  toCommaSeparatedNumber,
  toByteUnit,
}
