<template>
  <div class="table-responsive">
    <table class="table table-bordered bg-white">
      <thead>
        <tr>
          <th
            v-for="header in headers"
            :key="header.key"
            :class="header.class"
            scope="col"
          >
            {{ header.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td
            v-for="header in headers"
            :key="header.key"
            :class="getClassForTd(header.format)"
          >
            <slot :name="header.key" :item="item">
              {{ item[header.key] }}
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'DataTable',

  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },

  methods: {
    getClassForTd(format) {
      switch (format) {
        case 'string':
          return null
        case 'id':
          return 'text-center'
        case 'code':
          return 'text-center'
        case 'status':
          return 'text-center'
        case 'username':
          return 'text-center'
        case 'date':
          return 'text-center'
        case 'datetime':
          return 'text-end'
        case 'number':
          return 'text-end'
        case 'actions':
          return 'text-end'
        default:
          return null
      }
    },
  },
}
</script>
