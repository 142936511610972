<template>
  <CodeReader
    v-if="showCodeCamera"
    ref="codeReader"
    @decode="handleQRCodeData"
    @cancel="cancelCodeCamera"
    class="animate__animated animate__fadeIn"
  />

  <div v-else>
    <Navbar>
      <template v-slot:navbarTitle>
        <h1 class="h5 m-0">受注一覧</h1>
      </template>
      <template v-slot:navbarButton>
        <router-link
          :to="{
            name: 'OperateOrder',
          }"
          class="btn btn-dark"
          >戻る</router-link
        >
      </template>
    </Navbar>

    <main id="main" class="bg-light">
      <div class="container py-3 py-lg-4">
        <div class="row row-cols-1 gy-2 gy-lg-3 mb-2">
          <div class="col">
            <div class="card">
              <div class="card-body p-2 p-lg-3">
                <div class="input-group">
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    v-on:click.prevent="startCodeCamera"
                  >
                    <i class="bi bi-qr-code"></i>
                  </button>
                  <input
                    v-model="query.number"
                    type="text"
                    class="form-control"
                    id="number"
                    placeholder="受注番号"
                  />
                  <button
                    v-on:click.prevent="resetQuery()"
                    class="btn bg-white text-secondary border border-start-0"
                    type="button"
                  >
                    <i class="bi bi-x"></i>
                  </button>
                  <button
                    type="submit"
                    class="btn btn-secondary"
                    v-on:click.prevent="getQueryResult()"
                  >
                    <span class="d-block d-sm-none"
                      ><i class="bi bi-search d-block d-sm-none"></i
                    ></span>
                    <span class="d-none d-sm-block">検索</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-end">
            <router-link
              :to="{
                name: 'OperateOrderCreate',
              }"
              class="link-primary"
              ><i class="bi bi-plus-circle me-1"></i>新規登録</router-link
            >
          </div>

          <div class="col">
            <!-- orders -->
            <div
              v-if="orders && orders.length > 0"
              class="row row-cols-1 g-2 g-lg-3"
            >
              <div v-for="order in orders" :key="order.id" class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="m-0">
                        <h6 class="card-subtitle text-muted mb-1">
                          <router-link
                            :to="{
                              name: 'OperateOrderDetail',
                              params: { id: order.id },
                            }"
                            class="stretched-link text-decoration-none"
                            >{{ order.number }}</router-link
                          >
                        </h6>
                        <h5 class="card-title m-0">
                          {{ order.client_name }}
                        </h5>
                        <h6 class="card-subtitle text-muted m-0">
                          {{ order.client_code }}
                        </h6>
                      </div>
                      <div>
                        <span
                          class="badge"
                          :class="checkOrderStatus(order.latest_status)"
                          >{{ order.latest_status }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="card-footer d-flex justify-content-between">
                    <div>
                      <span class="small text-muted d-block">受注日</span
                      >{{ order.ordered_at }}
                    </div>
                    <div class="text-end">
                      <span class="small text-muted d-block">合計金額</span
                      >{{ toCommaSeparatedNumber(order.total_amount) }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-center gap-2 mb-2">
                <Pagination :prev="prev" :next="next" @move-page="movePage" />
              </div>
            </div>
            <div v-else>該当するデータはありません</div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import store from '../store'
import { toCommaSeparatedNumber } from '@/utils/stringUtils.js'
import CodeReader from '@/components/AppCodeReader.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import Navbar from '../components/OrderNavbar.vue'

export default {
  name: 'OperateOrderList',
  components: {
    CodeReader,
    Pagination,
    Navbar,
  },

  data() {
    return {
      query: {},
      showCodeCamera: false,
      formData: [],
      formErrors: {},
    }
  },
  computed: {
    orders() {
      return store.getters['operate_order/results']
    },
    prev() {
      return store.getters['operate_order/prev']
    },
    next() {
      return store.getters['operate_order/next']
    },
  },

  async created() {
    await this.getData()
  },

  methods: {
    toCommaSeparatedNumber,
    getData(query = {}) {
      return store.dispatch('operate_order/fetchAll', query)
    },
    startCodeCamera() {
      this.showCodeCamera = true
    },
    cancelCodeCamera() {
      this.showCodeCamera = false
    },
    handleQRCodeData(result) {
      this.query.number = result
      this.showCodeCamera = false
    },
    movePage(page) {
      this.getData(Object.assign(this.query, { page: page }))
    },
    getQueryResult() {
      this.getData(this.query)
    },
    resetQuery() {
      this.query = {}
      this.getData()
    },
    checkOrderStatus(latest_status) {
      switch (true) {
        case latest_status == '受注':
          return 'bg-primary'
        case latest_status == '売上':
          return 'bg-success'
        case latest_status == '取消':
          return 'bg-warning'
        default:
          return 'bg-secondary'
      }
    },
  },
}
</script>
