import OperateInventoryHome from './views/Home.vue'
import OperateInventory from './views/OperateInventory.vue'
import OperateStockIncoming from './views/OperateStockIncoming.vue'
import OperateStockOutgoing from './views/OperateStockOutgoing.vue'
import OperatePickingList from './views/OperatePickingList.vue'

export default {
  routes: [
    {
      path: '/operate/inventory',
      component: OperateInventoryHome,
      name: 'OperateInventoryHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          component: OperateInventory,
          name: 'OperateInventory',
        },
        {
          path: 'stocks/incoming',
          component: OperateStockIncoming,
          name: 'OperateStockIncoming',
        },
        {
          path: 'stocks/outgoing',
          component: OperateStockOutgoing,
          name: 'OperateStockOutgoing',
        },
        {
          path: 'picking-list',
          component: OperatePickingList,
          name: 'OperatePickingList',
        },
      ],
    },
  ],
}
