import api from '@/api'
import cookie from 'vue-cookies'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  user: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  auth_token() {
    return cookie.get(process.env.VUE_APP_AUTH_COOKIE_NAME)
  },
  is_token() {
    return cookie.isKey(process.env.VUE_APP_AUTH_COOKIE_NAME)
  },
  is_logged_in(state) {
    return !!Object.keys(state.user).length
  },
  user(state) {
    return state.user
  },
  userData: (state) => (key) => {
    if (key in state.user) {
      return state.user[key]
    }
    return false
  },
  activeApp: (state) => (name) => {
    if ('applications' in state.user) {
      return state.user['applications'][name]
    }
    return false
  },
  hasPerm: (state) => (permission) => {
    if ('permissions' in state.user) {
      return state.user['permissions'][permission]
    }
    return false
  },
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  updateToken: (state, data) => {
    cookie.set(process.env.VUE_APP_AUTH_COOKIE_NAME, data)
  },
  clearToken: (state) => {
    cookie.remove(process.env.VUE_APP_AUTH_COOKIE_NAME)
  },
  setUser: (state, user) => (state.user = user),
  clearUser: (state) => (state.user = {}),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearToken')
      commit('clearUser')
      resolve()
    })
  },
  async login({ commit, dispatch }, form_data) {
    const response = await api.post('auth/token/login/', form_data)
    commit('updateToken', response.data.auth_token)
    await dispatch('renew')
  },
  async logout({ dispatch }) {
    await api.post('auth/token/logout/')
    dispatch('clearAllStores', null, { root: true })
  },
  async renew({ commit, dispatch }) {
    try {
      const response = await api.get('auth/users/me/')
      commit('setUser', response.data)
    } catch (error) {
      dispatch('logout')
      throw error
    }
  },
  async boot({}) {
    await api.get('health_check/')
  },
  async setPassword({}, form_data) {
    const response = await api.post('auth/users/set_password/', form_data)
    return response
  },
  async patchData({ commit }, form_data) {
    const response = await api.patch('auth/users/me/', form_data)
    commit('setUser', response.data)
    return response
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
