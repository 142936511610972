<template>
  <NavbarForm @submit="formSubmit" @cancel="formCancel">
    <template v-slot:formTitle>受注登録</template>
  </NavbarForm>

  <main id="main" class="container-fluid py-3">
    <div class="card card-body bg-light mb-3">
      <form>
        <div class="row gy-2">
          <label for="form_client" class="col-sm-3 col-form-label"
            >顧客<span class="badge text-bg-danger ms-2 px-1">必須</span></label
          >
          <div class="col-sm-9">
            <FormSearchSelect
              v-model="formData.client"
              :options="clientList"
              :error="formErrors.client_id"
              id="form_client"
              placeholder="顧客コード・顧客名で検索"
              @getOptions="getClientList"
            />
          </div>
          <label for="form_number" class="col-sm-3 col-form-label"
            >受注番号<span class="badge text-bg-danger ms-2 px-1"
              >必須</span
            ></label
          >
          <div class="col-sm-9">
            <input
              v-model="formData.number"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': formErrors?.number,
              }"
              id="form_number"
            />
            <div v-if="formErrors?.number" class="invalid-feedback">
              {{ formErrors.number }}
            </div>
          </div>
          <label for="form_ordered_at" class="col-sm-3 col-form-label"
            >受注日時</label
          >
          <div class="col-sm-9">
            <input
              v-model="formData.ordered_at"
              type="datetime-local"
              class="form-control"
              :class="{
                'is-invalid': formErrors?.ordered_at,
              }"
              id="form_ordered_at"
            />
            <div v-if="formErrors?.ordered_at" class="invalid-feedback">
              {{ formErrors.ordered_at }}
            </div>
          </div>
          <label for="form_memo" class="col-sm-3 col-form-label">備考</label>
          <div class="col-sm-9">
            <input
              v-model="formData.memo"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': formErrors?.memo,
              }"
              id="form_memo"
            />
            <div v-if="formErrors?.memo" class="invalid-feedback">
              {{ formErrors.memo }}
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="clearfix">
      <div class="float-end">
        <button type="button" class="btn btn-link" @click="showOrderItemForm()">
          <i class="bi bi-plus-circle me-1"></i>品目を追加する
        </button>
      </div>
      <h5>受注品目</h5>
    </div>

    <div v-if="formData.order_items && formData.order_items.length > 0">
      <div class="table-responsive">
        <table class="table table-bordered bg-white">
          <thead>
            <tr>
              <th scope="col" class="col-sm-2">品目コード</th>
              <th scope="col" class="col-sm-3">品目名</th>
              <th scope="col" class="col-sm-1">単価</th>
              <th scope="col" class="col-sm-1">数量</th>
              <th scope="col" class="col-sm-1">小計</th>
              <th scope="col" class="col-sm-3">メモ</th>
              <th scope="col" class="col-sm-1"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in formData.order_items" :key="index">
              <td class="text-center">
                {{ item.item?.code }}
              </td>
              <td>{{ item.item?.name }}</td>
              <td class="text-end">{{ item.unit_price_at_order }}</td>
              <td class="text-end">{{ item.quantity }}</td>
              <td class="text-end">{{ item.subtotal }}</td>
              <td>
                <input type="text" v-model="item.memo" class="form-control" />
              </td>
              <td class="text-end">
                <button
                  type="button"
                  class="btn btn-link link-danger"
                  @click="deleteItem(index)"
                >
                  <i class="bi bi-trash3"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else class="mt-3">
      <p>品目を追加してください。</p>
      <div v-if="formErrors?.order_items">
        <div class="text-danger">品目は必ず1つ以上追加してください。</div>
      </div>
    </div>

    <OrderItemForm
      ref="orderItemForm"
      @afterSubmit="insertItem"
      :client="formData.client"
    />
  </main>
</template>

<script>
import store from '../store'
import { mapGetters, mapActions } from 'vuex'
import FormSearchSelect from '@/components/common/FormSearchSelect.vue'
import NavbarForm from '@/components/NavbarForm.vue'
import OrderItemForm from '../components/OrderItemForm.vue'

export default {
  name: 'ManageOrderCreate',
  inject: ['dayjs'],
  components: {
    FormSearchSelect,
    NavbarForm,
    OrderItemForm,
  },

  data() {
    return {
      formData: {
        client: null,
        order_number: '',
        ordered_at: this.dayjs().format('YYYY-MM-DDTHH:mm'),
        memo: '',
        order_items: [],
      },
      formErrors: {},
    }
  },
  computed: {
    ...mapGetters({
      clientList: 'client/list',
    }),
  },

  methods: {
    ...mapActions({
      getClientList: 'client/fetchList',
    }),
    async formSubmit() {
      this.formErrors = {}
      if (!this.formData.client) {
        this.formErrors.client_id = '顧客を選択してください。'
      }
      if (!this.formData.number) {
        this.formErrors.number = '受注番号を入力してください。'
      }
      if (this.formData.order_items.length == 0) {
        this.formErrors.order_items = '品目は必ず1つ以上追加してください。'
      }
      if (Object.keys(this.formErrors).length > 0) {
        return
      }

      try {
        await store.dispatch('manage_order/postOrPatch', this.formData)
        this.$store.dispatch('alert/setSuccessMessage', {
          message: '保存しました。',
        })
        window.setTimeout(async () => {
          await this.$router.push({
            name: 'ManageOrderList',
          })
        }, this.$store.getters['alert/timeout'])
      } catch (e) {
        if (e.response?.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            this.setErrorMessage(key, e.response.data[key])
          })
        }
      }
    },
    formCancel() {
      this.$router.push({
        name: 'ManageOrderList',
      })
    },
    setErrorMessage(key, errorData) {
      if (Array.isArray(errorData)) {
        this.formErrors[key] = errorData.join('\n')
      } else {
        this.formErrors[key] = errorData
      }
    },
    showOrderItemForm() {
      this.$refs.orderItemForm.show()
    },
    insertItem(item) {
      if (!this.formData.order_items) {
        this.formData.order_items = []
      }
      this.formData.order_items.push(item)
    },
    deleteItem(index) {
      this.formData.order_items.splice(index, 1)
    },
  },
}
</script>
