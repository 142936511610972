import { createStore } from 'vuex'
import manage_stock from './stock'
import manage_stock_location from './stock_location'
import manage_stock_transaction from './stock_transaction'
import manage_picking_list from './picking_list'

const store = createStore({
  actions: {
    clearAllStores({ dispatch }) {
      dispatch('manage_stock/clearAll', null, { root: true })
      dispatch('manage_stock_location/clearAll', null, { root: true })
      dispatch('manage_stock_transaction/clearAll', null, { root: true })
      dispatch('manage_picking_list/clearAll', null, { root: true })
    },
    clearDataStores({ dispatch }) {
      dispatch('manage_stock/clearAll', null, { root: true })
      dispatch('manage_stock_location/clearAll', null, { root: true })
      dispatch('manage_stock_transaction/clearAll', null, { root: true })
      dispatch('manage_picking_list/clearAll', null, { root: true })
    },
  },
  modules: {
    manage_stock,
    manage_stock_location,
    manage_stock_transaction,
    manage_picking_list,
  },
})

export default store
