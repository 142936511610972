import { createStore } from 'vuex'
import manage_order from './order'

const store = createStore({
  actions: {
    clearAllStores({ dispatch }) {
      dispatch('manage_order/clearAll', null, { root: true })
    },
    clearDataStores({ dispatch }) {
      dispatch('manage_order/clearAll', null, { root: true })
    },
  },
  modules: {
    manage_order,
  },
})

export default store
