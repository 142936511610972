<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row g-3">
            <div class="col-12">
              <label :for="modalId + '_form_stock_location'" class="form-label"
                >保管場所<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <FormSearchSelect
                v-model="formData.stock_location"
                :options="locationList"
                :error="formErrors.stock_location_id"
                :id="modalId + '_form_stock_location'"
                placeholder="保管場所名で検索"
                @getOptions="getLocationList"
              />
            </div>
            <div class="col-12">
              <label
                :for="modalId + '_form_changed_quantity'"
                class="form-label"
                >出庫数量<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <FormNumericInput
                v-model="formData.changed_quantity"
                :error="formErrors.changed_quantity"
                id="form_changed_quantity"
              />
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_changed_memo'" class="form-label"
                >変更理由</label
              >
              <input
                v-model="formData.changed_memo"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.changed_memo }"
                :id="modalId + '_form_changed_memo'"
              />
              <div v-if="formErrors?.changed_memo" class="invalid-feedback">
                {{ formErrors.changed_memo }}
              </div>
              <div v-else class="form-text">
                出庫数量を変更する場合は理由を入力してください。
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            閉じる
          </button>
          <button
            type="button"
            class="btn btn-dark"
            v-on:click.prevent="formSubmit"
          >
            <slot name="submitButton">更新</slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { randomString } from '@/utils/stringUtils.js'
import FormSearchSelect from '@/components/common/FormSearchSelect.vue'
import FormNumericInput from '@/components/common/FormNumericInput.vue'

export default {
  name: 'OperatePickingForm',
  components: {
    FormSearchSelect,
    FormNumericInput,
  },

  emits: ['hiddenModal', 'afterSubmit'],

  data() {
    return {
      modal: null,
      formData: {},
      formErrors: {},
    }
  },
  computed: {
    locationList() {
      return store.getters['operate_stock_location/list']
    },
    modalId: function () {
      return 'form-modal-' + this.randomString()
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.formErrors = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    randomString,
    async getLocationList(query = {}) {
      return await store.dispatch('operate_stock_location/fetchList', query)
    },
    show(formData = {}) {
      this.formData = Object.assign({}, formData)
      this.modal.show()
    },
    formSubmit() {
      this.formErrors = {}
      if (!this.formData.stock_location) {
        this.formErrors.stock_location_id = '保管場所を選択してください。'
      }
      if (this.formData.changed_quantity.length === 0) {
        this.formErrors.changed_quantity = '数量を入力してください。'
      }
      if (Object.keys(this.formErrors).length > 0) {
        return
      }

      this.$emit('afterSubmit', this.formData)
      this.modal.hide()
    },
  },
}
</script>
