import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  workers: [],
  groups: [],
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  workers: (state) => state.workers,
  groups: (state) => state.groups,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setWorkers: (state, workers) => (state.workers = workers),
  clearWorkers: (state) => (state.workers = []),
  setGroups: (state, groups) => (state.groups = groups),
  clearGroups: (state) => (state.groups = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearWorkers')
      commit('clearGroups')
      resolve()
    })
  },
  async fetchWorkerList({ state, commit }) {
    if (state.workers.length > 0) {
      return
    }

    const response = await api.get('dashboard/workers/list/')
    commit('setWorkers', response.data)
  },
  async fetchGroupList({ state, commit }) {
    if (state.groups.length > 0) {
      return
    }

    const response = await api.get('dashboard/groups/list/')
    commit('setGroups', response.data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
