<template>
  <i v-if="is_active" class="bi bi-check-circle-fill text-success"></i>
  <i v-else class="bi bi-x-circle-fill text-danger"></i>
</template>

<script>
export default {
  name: 'ActiveStatus',

  props: {
    is_active: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
