<template>
  <Navbar>
    <template v-slot:navbarTitle>製品検査</template>
  </Navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="clearfix mb-3">
      <div class="float-end">
        <router-link
          :to="{
            name: 'ManageInspection',
          }"
          class="btn btn-outline-dark me-1"
        >
          <span class="d-block d-sm-none"
            ><i class="bi bi-arrow-left"></i
          ></span>
          <span class="d-none d-sm-block">一覧に戻る</span>
        </router-link>
        <router-link
          :to="{
            name: 'ManageInspectionEdit',
            params: { id: this.id },
          }"
          class="btn btn-outline-success me-1"
        >
          <span class="d-block d-sm-none"><i class="bi bi-pencil"></i></span>
          <span class="d-none d-sm-block">編集</span>
        </router-link>
        <router-link
          :to="{
            name: 'ManageInspectionDuplicate',
          }"
          class="btn btn-outline-info me-1"
        >
          <span class="d-block d-sm-none"><i class="bi bi-files"></i></span>
          <span class="d-none d-sm-block">複製</span>
        </router-link>
        <button class="btn btn-danger" v-on:click.prevent="removeData()">
          <span class="d-block d-sm-none"><i class="bi bi-trash"></i></span>
          <span class="d-none d-sm-block">削除</span>
        </button>
      </div>
      <h5 class="text-truncate">
        <i class="bi bi-clipboard me-1"></i>{{ inspection.name }}
      </h5>
    </div>

    <div class="row">
      <div class="col-4">
        <h5>型式・型番</h5>
        <div class="card mb-3">
          <ul
            v-if="inspection.calls && inspection.calls.length > 0"
            class="list-group list-group-flush"
          >
            <li
              v-for="call in inspection.calls"
              :key="call.id"
              class="list-group-item d-flex justify-content-between"
            >
              <div class="text-break align-self-center">
                <span class="badge bg-dark me-3">{{
                  call.inspection_type_name
                }}</span>
                <span>{{ call.code }}</span>
              </div>
              <div class="text-nowrap">
                <button
                  type="button"
                  class="btn btn-link btn-sm text-secondary"
                  v-on:click.prevent="removeCall(call.id)"
                >
                  <i class="bi bi-trash-fill"></i>
                </button>
              </div>
            </li>
          </ul>
          <div v-else class="card-body">型式・型番はありません。</div>
        </div>
        <div class="d-sm-flex justify-content-between">
          <p>
            <i class="bi bi-plus-circle me-1"></i
            ><a
              href="#"
              v-on:click.prevent="showCallForm"
              class="text-muted text-decoration-none"
              >型式・型番を追加する</a
            >
          </p>
        </div>
      </div>

      <div class="col-8">
        <!-- inspection items -->
        <h5>検査項目</h5>
        <div v-if="inspection.items && inspection.items.length > 0">
          <div class="table-responsive">
            <table class="table table-bordered bg-white">
              <thead>
                <tr>
                  <th scope="col" class="col-sm-1">表示順</th>
                  <th scope="col" class="col-sm-3">項目種別</th>
                  <th scope="col" class="col-sm-6">項目名</th>
                  <th scope="col" class="col-sm-2"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in inspection.items" :key="item.id">
                  <td class="text-center">{{ item.position + 1 }}</td>
                  <td class="text-center">{{ item.type_name }}</td>
                  <td>{{ item.title }}</td>
                  <td class="text-center">
                    <a
                      href="#"
                      @click="preview(item)"
                      class="link-primary text-decoration-none"
                    >
                      <span class="d-block d-sm-none"
                        ><i class="bi bi-eye"></i
                      ></span>
                      <span class="d-none d-sm-block">プレビュー</span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p v-else class="mt-3">検査項目はありません。</p>
      </div>
    </div>

    <InspectionCallForm
      ref="callForm"
      @insert-call="insertCall"
      :inspection_id="id"
    />

    <InspectionItemPreview ref="item_preview" />
    <Confirm ref="confirmDataDelete" @confirm-true="confirmDataDelete" />
    <Confirm ref="confirmCallDelete" @confirm-true="confirmCallDelete" />
  </main>
</template>

<script>
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import Confirm from '@/components/TheConfirm.vue'
import InspectionItemPreview from '../components/InspectionItemPreview.vue'
import InspectionCallForm from '../components/InspectionCallForm.vue'

export default {
  name: 'ManageInspectionDetail',
  components: {
    Navbar,
    Confirm,
    InspectionItemPreview,
    InspectionCallForm,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      current_call_id: null,
    }
  },
  computed: {
    inspection() {
      return store.getters['inspection/detail']
    },
  },

  async created() {
    await this.getData(this.id).catch((error) => {
      if (error.status) {
        window.setTimeout(() => {
          this.$router.push({
            name: 'ManageInspection',
          })
        }, this.$store.getters['alert/timeout'])
      }
    })
  },

  methods: {
    getData(id) {
      return store.dispatch('inspection/fetchData', id)
    },
    preview(item) {
      this.$refs.item_preview.show(item)
    },
    removeData() {
      this.$refs.confirmDataDelete.showConfirm(
        'データを削除',
        'この検査シートを削除しますか？'
      )
    },
    async confirmDataDelete() {
      await store.dispatch('inspection/deleteData', this.id)
      this.$router.push({
        name: 'ManageInspection',
      })
    },
    showCallForm() {
      this.$refs.callForm.show()
    },
    insertCall(data) {
      if (!('calls' in this.inspection)) {
        this.inspection.calls = []
      }
      this.inspection.calls.push(data)
    },
    removeCall(call_id) {
      this.current_call_id = call_id
      this.$refs.confirmCallDelete.showConfirm(
        'データを削除',
        'この型式・型番を削除しますか？'
      )
    },
    async confirmCallDelete() {
      await store.dispatch('inspection_call/deleteData', this.current_call_id)
      let index = this.inspection.calls.findIndex(
        ({ id }) => id === this.current_call_id
      )
      this.inspection.calls.splice(index, 1)
      this.current_call_id = null
    },
  },
}
</script>
