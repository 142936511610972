<template>
  <div
    class="modal fade"
    id="alertModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="alert w-100" :class="alert.style">
        <h5 v-if="alert.title" class="modals-title">{{ alert.title }}</h5>
        {{ alert.message }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'

export default {
  name: 'Alert',

  data() {
    return {
      modal: null,
    }
  },
  computed: {
    ...mapGetters({
      alert: 'alert/alert',
      timeout: 'alert/timeout',
    }),
    is_message: {
      get() {
        return !!this.alert.message
      },
      set(val) {
        return val
      },
    },
  },

  watch: {
    $route(to, from) {
      if (to != from) {
        this.clearAll()
      }
    },
    is_message(to, from) {
      if (to) {
        this.modal.show()
        window.setTimeout(() => {
          this.modal.hide()
        }, this.timeout)
      }
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById('alertModal'))
    document
      .getElementById('alertModal')
      .addEventListener('hidden.bs.modal', () => {
        this.clearAll()
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    ...mapActions({
      clearAll: 'alert/clearAll',
    }),
  },
}
</script>
