<template>
  <nav class="navbar navbar-expand navbar-light border-bottom">
    <div class="container-fluid">
      <span class="navbar-brand"
        ><slot name="navbarTitle">{{ title }}</slot>
      </span>
      <span class="navbar-text d-none d-sm-block">{{ today }}</span>
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :to="{
              name: 'AppHomeManage',
            }"
            ><i class="bi bi-house"></i
          ></router-link>
        </li>
        <li class="nav-item">
          <span class="nav-link"><Profile /></span>
        </li>
        <li class="nav-item">
          <a v-on:click.prevent="confirmLogout()" class="nav-link" href="#"
            ><i class="bi bi-box-arrow-right"></i
          ></a>
        </li>
      </ul>
    </div>
  </nav>

  <Confirm ref="confirm" @confirm-true="confirmTrue" />
</template>

<script>
import { inject } from 'vue'
import { mapActions } from 'vuex'
import Confirm from '@/components/TheConfirm.vue'
import Profile from '@/components/Profile.vue'

export default {
  name: 'AppNavbar',
  components: {
    Confirm,
    Profile,
  },

  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      today: null,
    }
  },
  computed: {},

  mounted() {
    const dayjs = inject('dayjs')
    this.today = dayjs().format('YYYY年M月D日 (ddd)')
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    confirmLogout() {
      this.$refs.confirm.showConfirm('', 'ログアウトしますか？')
    },
    async confirmTrue() {
      await this.logout()
      this.$router.push({
        name: 'AppLogin',
      })
    },
  },
}
</script>

<style scoped>
.nav-item {
  margin-left: 0.5rem;
}

.bi::before {
  font-size: 1.5rem;
  line-height: 1rem;
}
</style>
