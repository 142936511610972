<template>
  <router-view></router-view>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ManageInspectionHome',

  computed: {
    ...mapGetters({
      activeApp: 'auth/activeApp',
    }),
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.activeApp('inspection') || vm.activeApp('inspection_plan')) {
        // 何もしない
      } else {
        vm.$store.dispatch('alert/setErrorMessage', {
          message: 'このアプリケーションへのアクセス権がありません。',
        })
        window.setTimeout(() => {
          vm.$router.push({
            name: 'AppHomeManage',
          })
        }, vm.$store.getters['alert/timeout'])
      }
    })
  },
}
</script>
