import { createStore } from 'vuex'
import inspection_result from '@/apps/inspection/manage/store/inspection_result'
import inspection_type from '@/apps/inspection/manage/store/inspection_type'
import inspection_plan from './inspection_plan'

const store = createStore({
  actions: {
    clearAllStores({ dispatch }) {
      dispatch('inspection_result/clearAll', null, { root: true })
      dispatch('inspection_type/clearAll', null, { root: true })
      dispatch('inspection_plan/clearAll', null, { root: true })
    },
    clearDataStores({ dispatch }) {
      dispatch('inspection_result/clearAll', null, { root: true })
      dispatch('inspection_type/clearAll', null, { root: true })
      dispatch('inspection_plan/clearAll', null, { root: true })
    },
  },
  modules: {
    inspection_result,
    inspection_type,
    inspection_plan,
  },
})

export default store
