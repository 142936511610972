import ItemPriceHome from '@/views/item_price/Home.vue'
import ItemPriceList from '@/views/item_price/ItemPriceList.vue'
import ItemPriceImport from '@/views/item_price/ItemPriceImport.vue'

export default {
  routes: [
    {
      path: '/item-price',
      component: ItemPriceHome,
      name: 'ItemPriceHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'prices',
          component: ItemPriceList,
          name: 'ItemPriceList',
        },
        {
          path: 'prices/import',
          component: ItemPriceImport,
          name: 'ItemPriceImport',
        },
      ],
    },
  ],
}
