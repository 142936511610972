import { createStore } from 'vuex'
import auth from './modules/auth'
import alert from './modules/alert'
import loading from './modules/loading'
import master from './modules/master'
import user from './modules/user'
import item from './item/item'
import item_classification from './item/item_classification'
import item_image from './item/item_image'
import item_price from './item_price/item_price'
import client from './client/client'
import supplier from './supplier/supplier'
import export_data from './export_data/export_data'
import operate_item from './item/operate_item'
import operate_supplier from './supplier/operate_supplier'

const store = createStore({
  actions: {
    clearAllStores({ dispatch }) {
      dispatch('auth/clearAll', null, { root: true })
      dispatch('alert/clearAll', null, { root: true })
      dispatch('loading/clearAll', null, { root: true })
      dispatch('master/clearAll', null, { root: true })
      dispatch('user/clearAll', null, { root: true })
      dispatch('item/clearAll', null, { root: true })
      dispatch('item_classification/clearAll', null, { root: true })
      dispatch('item_image/clearAll', null, { root: true })
      dispatch('item_price/clearAll', null, { root: true })
      dispatch('client/clearAll', null, { root: true })
      dispatch('supplier/clearAll', null, { root: true })
      dispatch('export_data/clearAll', null, { root: true })
      dispatch('operate_item/clearAll', null, { root: true })
      dispatch('operate_supplier/clearAll', null, { root: true })
    },
    clearDataStores({ dispatch }) {
      dispatch('master/clearAll', null, { root: true })
      dispatch('user/clearAll', null, { root: true })
      dispatch('item/clearAll', null, { root: true })
      dispatch('item_classification/clearAll', null, { root: true })
      dispatch('item_image/clearAll', null, { root: true })
      dispatch('item_price/clearAll', null, { root: true })
      dispatch('client/clearAll', null, { root: true })
      dispatch('supplier/clearAll', null, { root: true })
      dispatch('export_data/clearAll', null, { root: true })
      dispatch('operate_item/clearAll', null, { root: true })
      dispatch('operate_supplier/clearAll', null, { root: true })
    },
  },
  modules: {
    auth,
    alert,
    loading,
    master,
    user,
    item,
    item_classification,
    item_image,
    item_price,
    client,
    supplier,
    export_data,
    operate_item,
    operate_supplier,
  },
})

export default store
