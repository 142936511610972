<template>
  <Navbar>
    <template v-slot:navbarTitle>
      <h1 class="h5 m-0">[{{ item.code }}] {{ item.name }}</h1>
    </template>
    <template v-slot:navbarButton>
      <router-link
        :to="{
          name: 'OperateItemList',
        }"
        class="btn btn-dark"
        ><i class="bi bi-0-square me-1"></i>戻る</router-link
      >
    </template>
  </Navbar>

  <main id="main" class="bg-light">
    <div v-if="!isLoading" class="container py-3 py-lg-4">
      <div
        v-if="item.images && item.images.length > 0"
        class="row g-1 g-md-2"
        :class="{
          'row-cols-1': item.images.length == 1,
          'row-cols-2': item.images.length != 1,
        }"
      >
        <ImageGallery
          ref="gallery"
          :files="item.images"
          :thumbnail_class="'object-fit-contain'"
          ><template v-slot:thumbnail-operation-icons="{ index }">
            <div class="d-flex justify-content-start p-1">
              <button
                :ref="'link' + (index + 1)"
                class="btn btn-outline-dark border-0"
              >
                <i
                  class="bi"
                  :class="'bi-' + parseFloat(index + 1) + '-square'"
                ></i>
              </button>
            </div> </template
        ></ImageGallery>
      </div>
      <div v-else class="row g-1 g-md-2">
        <div class="col-12">
          <p>画像の登録はありません</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/operate/OperateNavbar.vue'
import ImageGallery from '@/components/AppImageGallery.vue'

export default {
  name: 'OperateItemDetail',
  components: {
    Navbar,
    ImageGallery,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters({
      item: 'operate_item/detail',
    }),
  },

  async created() {
    try {
      await this.getData(this.id)
    } catch (error) {
      if (error.status) {
        window.setTimeout(() => {
          this.$router.push({
            name: 'OperateItemList',
          })
        }, this.$store.getters['alert/timeout'])
      }
    } finally {
      this.isLoading = false
    }
  },

  mounted() {
    window.addEventListener('keydown', this.handleKeyDown)
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  },

  methods: {
    ...mapActions({
      getData: 'operate_item/fetchData',
    }),
    handleKeyDown(event) {
      if (document.activeElement.tagName.toLowerCase() === 'input') {
        return
      }
      switch (event.key) {
        case '1':
        case '2':
        case '3':
        case '4': {
          const linkRef = this.$refs['link' + event.key]
          if (linkRef) {
            const index = parseInt(event.key) - 1
            this.$refs.gallery.showLightBox(index)
          }
          break
        }
        case '0':
          this.$router.push({ name: 'OperateItemList' })
          break
        case 'Backspace':
          this.$refs.gallery.hideLightBox()
          break
        default:
          break
      }
    },
  },
}
</script>
