<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modalTitle">品目</slot>
          </h5>
        </div>
        <div class="modal-body">
          <div class="row g-3">
            <div class="col-12">
              <label :for="modalId + '_form_code'" class="form-label"
                >品目コード<span
                  v-if="formData.id"
                  class="badge text-bg-secondary ms-2 px-1"
                  >変更不可</span
                ><span v-else class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <input
                v-model="formData.code"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': formErrors?.code,
                  'bg-secondary': formData.id,
                }"
                :id="modalId + '_form_code'"
                :readonly="formData.id"
              />
              <div v-if="formErrors?.code" class="invalid-feedback">
                {{ formErrors.code }}
              </div>
              <div v-else class="form-text text-muted small">
                半角英数字、ハイフン、アンダースコアのみ使用できます
              </div>
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_item_model'" class="form-label"
                >型式</label
              >
              <input
                v-model="formData.item_model"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.item_model }"
                :id="modalId + '_form_item_model'"
              />
              <div v-if="formErrors?.item_model" class="invalid-feedback">
                {{ formErrors.item_model }}
              </div>
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_name'" class="form-label"
                >品目名<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <input
                v-model="formData.name"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.name }"
                :id="modalId + '_form_name'"
              />
              <div v-if="formErrors?.name" class="invalid-feedback">
                {{ formErrors.name }}
              </div>
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_memo'" class="form-label"
                >メモ</label
              >
              <input
                v-model="formData.memo"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.memo }"
                :id="modalId + '_form_memo'"
              />
              <div v-if="formErrors?.memo" class="invalid-feedback">
                {{ formErrors.memo }}
              </div>
            </div>
            <div class="col-12">
              <label class="form-label">品目分類</label>
              <form-multi-select
                v-model="formData.classifications"
                :options="classificationList"
                :error="formErrors.classifications"
                :id="modalId + '_form_classifications'"
                @get-options="getClassificationsList"
              ></form-multi-select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div v-if="formErrors?.form" class="text-danger">
            {{ formErrors.form }}
          </div>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            閉じる
          </button>
          <button
            type="button"
            class="btn btn-dark"
            v-on:click.prevent="formSubmit()"
          >
            <slot name="submitButton">保存</slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { mapGetters, mapActions } from 'vuex'
import { randomString } from '@/utils/stringUtils.js'
import FormMultiSelect from '@/components/common/FormMultiSelect.vue'

export default {
  name: 'ItemForm',
  components: {
    FormMultiSelect,
  },

  emits: ['hiddenModal', 'afterSubmit'],

  data() {
    return {
      modal: null,
      formData: {},
      formErrors: {},
    }
  },
  computed: {
    ...mapGetters({
      classificationList: 'item_classification/list',
    }),
    modalId: function () {
      return 'form-modal-' + this.randomString()
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.formErrors = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    randomString,
    ...mapActions({
      getClassificationsList: 'item_classification/fetchList',
      postOrPatch: 'item/postOrPatch',
    }),
    show(formData = {}) {
      this.formData = Object.assign({}, formData)
      this.modal.show()
    },
    async formSubmit() {
      try {
        this.formErrors = {}
        await this.postOrPatch(this.formData)
        this.$store.dispatch('alert/setSuccessMessage', {
          message: '保存しました。',
        })
        await this.$emit('afterSubmit', this.formData)
        await this.modal.hide()
      } catch (e) {
        if (e.response?.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            this.setErrorMessage(key, e.response.data[key])
          })
        } else {
          this.setErrorMessage('form', '保存できませんでした')
        }
      }
    },
    setErrorMessage(key, errorData) {
      if (Array.isArray(errorData)) {
        this.formErrors[key] = errorData.join('\n')
      } else {
        this.formErrors[key] = errorData
      }
    },
  },
}
</script>
