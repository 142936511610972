<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modalTitle">仕入先情報</slot>
          </h5>
        </div>
        <div class="modal-body">
          <div class="row g-3">
            <div class="col-12">
              <label :for="modalId + '_form_code'" class="form-label"
                >仕入先コード<span
                  v-if="formData.id"
                  class="badge text-bg-secondary ms-2 px-1"
                  >変更不可</span
                ><span v-else class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <input
                v-model="formData.code"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': formErrors?.code,
                  'bg-secondary': formData.id,
                }"
                :id="modalId + '_form_code'"
                :readonly="formData.id"
              />
              <div v-if="formErrors?.code" class="invalid-feedback">
                {{ formErrors.code }}
              </div>
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_name'" class="form-label"
                >仕入先名<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <input
                v-model="formData.name"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.name }"
                :id="modalId + '_form_name'"
              />
              <div v-if="formErrors?.name" class="invalid-feedback">
                {{ formErrors.name }}
              </div>
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_kana'" class="form-label"
                >仕入先名（ふりがな）</label
              >
              <input
                v-model="formData.kana"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.kana }"
                :id="modalId + '_form_kana'"
              />
              <div v-if="formErrors?.kana" class="invalid-feedback">
                {{ formErrors.kana }}
              </div>
            </div>
            <div class="col-6">
              <label :for="modalId + '_form_postal_code'" class="form-label"
                >郵便番号</label
              >
              <input
                v-model="formData.postal_code"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.postal_code }"
                :id="modalId + '_form_postal_code'"
              />
              <div v-if="formErrors?.postal_code" class="invalid-feedback">
                {{ formErrors.postal_code }}
              </div>
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_address'" class="form-label"
                >住所</label
              >
              <input
                v-model="formData.address"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.address }"
                :id="modalId + '_form_address'"
              />
              <div v-if="formErrors?.address" class="invalid-feedback">
                {{ formErrors.address }}
              </div>
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_phone_number'" class="form-label"
                >電話番号</label
              >
              <input
                v-model="formData.phone_number"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.phone_number }"
                :id="modalId + '_form_phone_number'"
              />
              <div v-if="formErrors?.phone_number" class="invalid-feedback">
                {{ formErrors.phone_number }}
              </div>
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_email'" class="form-label"
                >メールアドレス</label
              >
              <input
                v-model="formData.email"
                type="email"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.email }"
                :id="modalId + '_form_email'"
              />
              <div v-if="formErrors?.email" class="invalid-feedback">
                {{ formErrors.email }}
              </div>
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_memo'" class="form-label"
                >メモ</label
              >
              <input
                v-model="formData.memo"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.memo }"
                :id="modalId + '_form_memo'"
              />
              <div v-if="formErrors?.memo" class="invalid-feedback">
                {{ formErrors.memo }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div v-if="formErrors?.form" class="text-danger">
            {{ formErrors.form }}
          </div>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            閉じる
          </button>
          <button
            type="button"
            class="btn btn-dark"
            v-on:click.prevent="formSubmit()"
          >
            <slot name="submitButton">保存</slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { mapActions } from 'vuex'
import { randomString, removeNonNumericAndHyphen } from '@/utils/stringUtils.js'

export default {
  name: 'SupplierForm',
  components: {},

  emits: ['hiddenModal', 'afterSubmit'],

  data() {
    return {
      modal: null,
      formData: {},
      formErrors: {},
    }
  },
  computed: {
    modalId: function () {
      return 'form-modal-' + this.randomString()
    },
  },
  watch: {
    'formData.postal_code': function (val) {
      this.formData.postal_code = removeNonNumericAndHyphen(val, 8)
    },
    'formData.phone_number': function (val) {
      this.formData.phone_number = removeNonNumericAndHyphen(val, 15)
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.formErrors = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    randomString,
    removeNonNumericAndHyphen,
    ...mapActions({
      postOrPatch: 'supplier/postOrPatch',
    }),
    show(formData = {}) {
      this.formData = Object.assign({}, formData)
      this.modal.show()
    },
    async formSubmit() {
      try {
        this.formErrors = {}
        await this.postOrPatch(this.formData)
        this.$store.dispatch('alert/setSuccessMessage', {
          message: '保存しました。',
        })
        await this.$emit('afterSubmit', this.formData)
        await this.modal.hide()
      } catch (e) {
        if (e.response?.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            this.setErrorMessage(key, e.response.data[key])
          })
        } else {
          this.setErrorMessage('form', '保存できませんでした')
        }
      }
    },
    setErrorMessage(key, errorData) {
      if (Array.isArray(errorData)) {
        this.formErrors[key] = errorData.join('\n')
      } else {
        this.formErrors[key] = errorData
      }
    },
  },
}
</script>
