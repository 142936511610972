import ClientHome from '@/views/client/Home.vue'
import ClientList from '@/views/client/ClientList.vue'
import ClientDetail from '@/views/client/ClientDetail.vue'
import ClientImport from '@/views/client/ClientImport.vue'

export default {
  routes: [
    {
      path: '/client',
      component: ClientHome,
      name: 'ClientHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'clients',
          component: ClientList,
          name: 'ClientList',
        },
        {
          path: 'clients/import',
          component: ClientImport,
          name: 'ClientImport',
        },
        {
          path: 'clients/:id([0-9]+)',
          component: ClientDetail,
          name: 'ClientDetail',
          props: (route) => ({ id: Number(route.params.id) }),
        },
      ],
    },
  ],
}
