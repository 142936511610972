<template>
  <div class="input-group">
    <input
      v-model="inputValue"
      type="text"
      class="form-control"
      :class="{ 'is-invalid': error }"
      :id="id"
      @input="onlyNumbers"
    />
    <button
      v-if="clearValue"
      v-on:click.prevent="resetInput()"
      class="btn bg-white text-secondary border border-start-0"
      type="button"
    >
      <i class="bi bi-x"></i>
    </button>
    <div v-if="error" class="invalid-feedback">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { toHalfWidthNumbers } from '@/utils/stringUtils.js'

export default {
  name: 'FormNumericInput',
  props: {
    modelValue: {
      type: [String, Number, Object],
      default: null,
    },
    error: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    clearValue: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputValue: this.modelValue,
    }
  },
  watch: {
    modelValue(newVal) {
      this.inputValue = newVal
    },
  },
  methods: {
    toHalfWidthNumbers,
    onlyNumbers() {
      let convertedInput = this.toHalfWidthNumbers(this.inputValue)
      if (convertedInput) {
        convertedInput = convertedInput.replace(/[^0-9.]/g, '')
        this.inputValue = convertedInput
        this.$emit('update:modelValue', convertedInput)
      }
    },
    resetInput() {
      this.inputValue = ''
      this.$emit('update:modelValue', '')
    },
  },
}
</script>
