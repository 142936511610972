<template>
  <CodeReader
    v-if="showCodeCamera"
    ref="codeReader"
    @decode="handleQRCodeData"
    @cancel="cancelCodeCamera"
    class="animate__animated animate__fadeIn"
  />

  <div v-else>
    <Navbar>
      <template v-slot:navbarTitle>
        <h1 class="h5 m-0">ピッキング</h1>
      </template>
      <template v-slot:navbarButton>
        <router-link
          :to="{
            name: 'OperateInventory',
          }"
          class="btn btn-dark"
          >戻る</router-link
        >
      </template>
    </Navbar>

    <main id="main" class="bg-light">
      <div class="container py-3 py-lg-4">
        <div class="row row-cols-1 gy-2 gy-lg-3 mb-2">
          <div class="col">
            <div class="card">
              <div class="card-body p-2 p-lg-3">
                <div class="input-group">
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    v-on:click.prevent="startCodeCamera"
                  >
                    <i class="bi bi-qr-code"></i>
                  </button>
                  <FormSearchInput
                    ref="dropdown"
                    v-model="query.code"
                    :options="pickingList"
                    :placeholder="'管理コード'"
                    @selectOption="selectOption"
                    @fetchOptions="getPickingList"
                  >
                    <template #default="{ option }">
                      <span>{{ option.code }}</span>
                    </template>
                  </FormSearchInput>
                  <button
                    v-on:click.prevent="resetSearch()"
                    class="btn bg-white text-secondary border border-start-0"
                    type="button"
                  >
                    <i class="bi bi-x"></i>
                  </button>
                  <button
                    type="submit"
                    class="btn btn-secondary"
                    v-on:click.prevent="submitSearch()"
                  >
                    <span class="d-block d-sm-none"
                      ><i class="bi bi-search d-block d-sm-none"></i
                    ></span>
                    <span class="d-none d-sm-block">検索</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col">
            <!-- pickingLists -->
            <div
              v-if="formData && formData.length > 0"
              class="row row-cols-1 g-2 g-lg-3"
            >
              <div v-for="item in formData" :key="item.id" class="col">
                <div class="card h-100" :class="checkItemQuantity(item)">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="m-0">
                        <h6 class="card-subtitle text-muted mb-1">
                          <span class="d-block">{{ item.code }}</span>
                          <span v-if="item.deadline" class="d-block small"
                            >[納期] {{ item.deadline }}</span
                          >
                        </h6>
                        <h5 class="card-title m-0">
                          {{ item.stock.name }}
                        </h5>
                        <h6 class="card-subtitle text-muted m-0">
                          【{{ item.stock.code }}】{{ item.stock.item_model }}
                        </h6>
                      </div>
                      <div>
                        <button
                          @click="showItemModal(item)"
                          type="button"
                          class="btn btn-sm btn-dark"
                          :disabled="item.picked_by.length > 0"
                        >
                          出庫
                        </button>
                      </div>
                    </div>
                    <div class="m-0 mt-1">
                      <p class="card-text m-0">
                        {{ item.memo }}
                      </p>
                      <p
                        v-if="item.changed_memo"
                        class="card-text text-danger m-0 mt-1"
                      >
                        {{ item.changed_memo }}
                      </p>
                    </div>
                  </div>
                  <div class="card-footer d-flex justify-content-between">
                    <span>{{ item.stock_location?.name }}</span>
                    <span
                      >{{ item.changed_quantity }} / {{ item.quantity }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col">
                <a @click="formSubmit" class="btn btn-lg btn-primary w-100"
                  >保存する</a
                >
              </div>
            </div>
            <div v-else class="alert alert-warning" role="alert">
              管理コードを入力してください
            </div>
          </div>
        </div>
      </div>

      <OperatePickingForm ref="itemModal" @after-submit="updateItem" />
    </main>
  </div>
</template>

<script>
import store from '../store'
import CodeReader from '@/components/AppCodeReader.vue'
import Navbar from '../components/InventoryNavbar.vue'
import OperatePickingForm from '../components/OperatePickingForm.vue'
import FormSearchInput from '@/components/common/FormSearchInput.vue'

export default {
  name: 'OperatePickingList',
  components: {
    CodeReader,
    Navbar,
    OperatePickingForm,
    FormSearchInput,
  },

  data() {
    return {
      query: {
        code: '',
      },
      showCodeCamera: false,
      formData: [],
      formErrors: {},
    }
  },
  computed: {
    pickingList() {
      return store.getters['picking_list/list']
    },
    result() {
      return store.getters['picking_list/result']
    },
  },

  unmounted() {
    this.clearPickingList()
  },

  methods: {
    clearPickingList() {
      store.dispatch('picking_list/clearAll')
      this.formData = []
    },
    async searchPickingList(query) {
      return await store.dispatch('picking_list/searchData', query)
    },
    async getPickingList(keyword) {
      await store.dispatch('picking_list/fetchList', { q: keyword })
    },
    selectOption(option) {
      this.query.code = option.code
    },
    startCodeCamera() {
      this.showCodeCamera = true
    },
    cancelCodeCamera() {
      this.showCodeCamera = false
    },
    handleQRCodeData(result) {
      this.query.code = result
      this.showCodeCamera = false
    },
    showItemModal(item) {
      this.$refs.itemModal.show(item)
    },
    async submitSearch() {
      if (!this.query.code) {
        this.$store.dispatch('alert/setErrorMessage', {
          message: '管理コードを入力してください',
        })
      } else {
        try {
          await this.searchPickingList(this.query)
          if (this.result.length == 0) {
            this.$store.dispatch('alert/setErrorMessage', {
              message: '該当するデータがありません',
            })
          } else {
            this.formData = this.result
          }
        } catch (error) {
          this.clearPickingList()
        }
      }
    },
    resetSearch() {
      this.query.code = ''
      this.clearPickingList()
    },
    updateItem(itemData) {
      this.formData = this.formData.map((item) => {
        if (item.id == itemData.id) {
          return itemData
        } else {
          return item
        }
      })
    },
    async formSubmit() {
      // this.result と this.formData を比較して changed_quantity に変更があったデータのみ配列にする
      const changedData = this.formData.filter((item) => {
        const originalItem = this.result.find(
          (originalItem) => originalItem.id == item.id
        )
        return item.changed_quantity != originalItem.changed_quantity
      })

      if (changedData.length == 0) {
        this.$store.dispatch('alert/setErrorMessage', {
          message: '変更がありません',
        })
        return
      }

      try {
        await store.dispatch('picking_list/bulkPatchData', changedData)
        this.$store.dispatch('alert/setSuccessMessage', {
          message: '保存しました',
        })
        window.setTimeout(() => {
          this.$router.push({
            name: 'OperateInventory',
          })
        }, this.$store.getters['alert/timeout'])
      } catch (error) {
        this.$store.dispatch('alert/setErrorMessage', {
          message: '保存できませんでした',
        })
      }
    },
    setErrorMessage(key, errorData) {
      if (Array.isArray(errorData)) {
        this.formErrors[key] = errorData.join('\n')
      } else {
        this.formErrors[key] = errorData
      }
    },
    checkItemQuantity(item) {
      switch (true) {
        case item.picked_by.length > 0:
          return 'bg-secondary'
        case parseFloat(item.changed_quantity) == 0:
          return ''
        case parseFloat(item.changed_quantity) == parseFloat(item.quantity):
          return 'border-success border-2'
        case parseFloat(item.changed_quantity) > parseFloat(item.quantity):
          return 'border-warning border-2'
        case parseFloat(item.changed_quantity) < parseFloat(item.quantity):
          return 'border-danger border-2'
      }
    },
  },
}
</script>
