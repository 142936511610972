import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
  query: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  results: (state) => state.data.results,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  query: (state) => state.query,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = []),
  setQuery: (state, query) => (state.query = query),
  clearQuery: (state) => (state.query = {}),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearQuery')
      resolve()
    })
  },
  /**
   * データを取得する
   *
   * @return {void}
   */
  async fetchAll({ commit, state }) {
    // パラメーターの値が空の場合は削除する
    Object.keys(state.query).forEach(
      (key) => state.query[key] === '' && delete state.query[key]
    )
    const response = await api.get('operate/inspection_plan_products/', {
      params: state.query,
    })
    commit('setData', response.data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
