<template>
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modalTitle">パスワードを変更</slot>
          </h5>
        </div>
        <div class="modal-body">
          <div class="row g-3">
            <div class="col-12">
              <label :for="modalId + '_form_new_password'" class="form-label"
                >パスワード<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <div class="input-group">
                <input
                  v-model="formData.new_password"
                  :type="passwordFieldType"
                  class="form-control"
                  :class="{ 'is-invalid': formErrors?.new_password }"
                  :id="modalId + '_form_new_password'"
                />
                <button
                  class="btn btn-outline-secondary border"
                  type="button"
                  tabindex="-1"
                  @click="togglePasswordVisibility"
                >
                  <i
                    :class="
                      passwordFieldType === 'password'
                        ? 'bi bi-eye-fill'
                        : 'bi bi-eye-slash-fill'
                    "
                  ></i>
                </button>
                <div v-if="formErrors?.new_password" class="invalid-feedback">
                  {{ formErrors.new_password }}
                </div>
              </div>
              <div
                v-if="!formErrors?.new_password"
                class="form-text text-muted small"
              >
                パスワードは8文字以上かつ 0-9, a-z, 記号 #$%& を含めてください
              </div>
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_re_new_password'" class="form-label"
                >パスワード（再入力）<span
                  class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <input
                v-model="formData.re_new_password"
                type="password"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.re_new_password }"
                :id="modalId + '_form_re_new_password'"
              />
              <div v-if="formErrors?.re_new_password" class="invalid-feedback">
                {{ formErrors.re_new_password }}
              </div>
              <div v-else class="form-text text-muted small">
                確認のためもう一度入力してください
              </div>
            </div>
            <div class="col-12">
              <label
                :for="modalId + '_form_current_password'"
                class="form-label"
                >現在のパスワード<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <input
                v-model="formData.current_password"
                type="password"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.current_password }"
                :id="modalId + '_form_current_password'"
              />
              <div v-if="formErrors?.current_password" class="invalid-feedback">
                {{ formErrors.current_password }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            閉じる
          </button>
          <button
            type="button"
            class="btn btn-dark"
            v-on:click.prevent="formSubmit()"
          >
            <slot name="submitButton">保存</slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { mapActions } from 'vuex'
import { randomString } from '@/utils/stringUtils.js'

export default {
  name: 'ProfileChangePassword',
  components: {},

  emits: ['hiddenModal', 'afterSubmit'],

  data() {
    return {
      modal: null,
      formData: {},
      formErrors: {},
      passwordFieldType: 'password',
    }
  },
  computed: {
    modalId: function () {
      return 'form-modal-' + this.randomString()
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.formData = {}
        this.formErrors = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    randomString,
    ...mapActions({
      setPassword: 'auth/setPassword',
    }),
    showModal() {
      this.modal.show()
    },
    async formSubmit() {
      try {
        this.formErrors = {}
        if (!this.formData.new_password) {
          this.formErrors.new_password = '新しいパスワードを入力してください'
        }
        if (!this.formData.re_new_password) {
          this.formErrors.re_new_password =
            '確認用のパスワードを入力してください'
        }
        if (!this.formData.current_password) {
          this.formErrors.current_password =
            '現在のパスワードを入力してください'
        }

        if (Object.keys(this.formErrors).length > 0) {
          return
        }

        await this.setPassword(this.formData)
        this.$store.dispatch('alert/setSuccessMessage', {
          message: '保存しました。',
        })
        await this.$emit('afterSubmit')
        this.modal.hide()
      } catch (e) {
        if (e.response.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            this.setErrorMessage(key, e.response.data[key])
          })
        }
      }
    },
    setErrorMessage(key, errorData) {
      if (Array.isArray(errorData)) {
        this.formErrors[key] = errorData.join('\n')
      } else {
        this.formErrors[key] = errorData
      }
    },
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    },
  },
}
</script>
