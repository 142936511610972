<template>
  <div class="card mb-3">
    <h5 class="card-header bg-secondary">受注管理</h5>
    <div class="card-body">
      <div class="row row-cols-1 row-cols-md-2 g-1">
        <div class="col">
          <router-link
            :to="{
              name: 'ManageOrderList',
            }"
            class="btn btn-lg btn-secondary w-100 py-3"
            ><i class="bi bi-file-earmark-text d-block"></i>受注</router-link
          >
        </div>
        <div class="col">
          <router-link
            :to="{
              name: 'ManageOrderSalesList',
            }"
            class="btn btn-lg btn-secondary w-100 py-3"
            ><i class="bi bi-file-earmark-text d-block"></i>売上</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuOrder',
}
</script>
