<template>
  <Navbar />
  <main id="main" class="container pt-4">
    <div class="row g-3">
      <div class="col-12 col-md-7 col-lg-8">
        <div class="row row-cols-1 g-3">
          <div
            v-if="activeApp('inspection') || activeApp('inspection_plan')"
            class="col"
          >
            <router-link
              :to="{
                name: 'OperateInspectionPlanProductList',
              }"
              class="btn btn-lg btn-secondary w-100 py-3"
              ><i
                class="bi bi-clipboard-check d-block"
                style="font-size: 2rem"
              ></i
              >指示検査</router-link
            >
          </div>
          <div v-if="activeApp('inventory')" class="col">
            <router-link
              :to="{
                name: 'OperateInventory',
              }"
              class="btn btn-lg btn-secondary w-100 py-3"
              ><i class="bi bi-box d-block" style="font-size: 2rem"></i
              >在庫</router-link
            >
          </div>
          <div v-if="activeApp('order')" class="col">
            <router-link
              :to="{
                name: 'OperateOrder',
              }"
              class="btn btn-lg btn-secondary w-100 py-3"
              ><i
                class="bi bi-box-arrow-in-right d-block"
                style="font-size: 2rem"
              ></i
              >受注</router-link
            >
          </div>
          <div v-if="activeApp('purchase')" class="col">
            <router-link
              :to="{
                name: 'OperatePurchase',
              }"
              class="btn btn-lg btn-secondary w-100 py-3"
              ><i
                class="bi bi-box-arrow-in-right d-block"
                style="font-size: 2rem"
              ></i
              >仕入</router-link
            >
          </div>
        </div>
      </div>
      <div class="col-12 col-md-5 col-lg-4">
        <div class="row row-cols-1 g-3">
          <div class="col">
            <a
              v-on:click.prevent="confirmLogout()"
              class="btn btn-lg btn-outline-danger w-100 py-3"
              href="#"
              ><i
                class="bi bi-x-circle-fill d-block"
                style="font-size: 2rem"
              ></i
              >作業終了</a
            >
          </div>
          <div class="col">
            <router-link
              :to="{
                name: 'OperateItemList',
              }"
              class="btn btn-lg btn-outline-dark w-100 py-3"
              >品目</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </main>

  <Confirm ref="confirm" @confirm-true="confirmTrue" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/operate/OperateNavbar.vue'
import Confirm from '@/components/TheConfirm.vue'

export default {
  name: 'AppHomeOperate',
  components: {
    Navbar,
    Confirm,
  },

  computed: {
    ...mapGetters({
      activeApp: 'auth/activeApp',
    }),
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    confirmLogout() {
      this.$refs.confirm.showConfirm('', '作業を終了しますか？')
    },
    async confirmTrue() {
      await this.logout()
      this.$router.push({
        name: 'AppLogin',
      })
    },
  },
}
</script>

<style>
#main {
  min-height: calc(100vh - 56px);
}
</style>
