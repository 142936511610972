<template>
  <navbar>
    <template v-slot:navbarTitle>製品検査</template>
  </navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="d-flex gap-2 mb-3">
      <h5 class="m-0 p-0">
        <i class="bi bi-clipboard-data me-1"></i>検査結果一覧
      </h5>
      <div class="ms-auto">
        <button
          type="button"
          v-on:click.prevent="convertToCsvConfirm()"
          class="btn btn-dark"
        >
          <span class="d-block d-sm-none"
            ><i class="bi bi-arrow-repeat"></i
          ></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-arrow-repeat me-1"></i>データをCSVに変換する</span
          >
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-start gap-2 mb-2">
      <div class="me-auto col-6 col-lg-4 col-xl-3">
        <search-form
          :query="query"
          query-key="inspection_result_number"
          placeholder="検査番号で検索"
          :use-search-modal="true"
          @update-search-query="onUpdateSearchQuery"
          @open-search-modal="onOpenSearchModal"
        ></search-form>
      </div>
      <pagination :prev="prev" :next="next" @move-page="movePage"></pagination>
    </div>

    <!-- results -->
    <div v-if="results && results.length > 0">
      <div class="table-responsive">
        <table class="table table-bordered bg-white">
          <thead>
            <tr>
              <th scope="col" class="col-sm-2">検査番号</th>
              <th scope="col" class="col-sm-1">検査種別</th>
              <th scope="col" class="col-sm-1">型式</th>
              <th scope="col" class="col-sm-1">機種コード</th>
              <th scope="col" class="col-sm-1">客先製品コード</th>
              <th scope="col" class="col-sm-3">検査シート</th>
              <th scope="col" class="col-sm-1">検査員</th>
              <th scope="col" class="col-sm-2">検査開始日時</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="result in results" :key="result.id">
              <td scope="row" class="text-center">
                <router-link
                  :to="{
                    name: 'ManageInspectionResultDetail',
                    params: { id: result.id },
                  }"
                  >{{ result.inspection_result_number }}</router-link
                >
              </td>
              <td class="text-center">{{ result.inspection_type_name }}</td>
              <td>{{ result.product_model }}</td>
              <td class="text-center">{{ result.product_code }}</td>
              <td>{{ result.client_product_code }}</td>
              <td>{{ result.inspection_name }}</td>
              <td class="text-center">{{ result.worker }}</td>
              <td class="text-end">{{ result.begin_datetime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>

    <!-- Modal -->
    <search-modal
      :query="query"
      :items="searchItems"
      @update-search-query="onUpdateSearchQuery"
      ref="searchModal"
    ></search-modal>

    <confirm ref="confirmConvertData" @confirm-true="convertToCsv"></confirm>
  </main>
</template>

<script>
import { mapActions } from 'vuex'
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import Confirm from '@/components/TheConfirm.vue'
import SearchForm from '@/components/common/SearchForm.vue'
import SearchModal from '@/components/common/SearchModal.vue'

export default {
  name: 'ManageInspectionResult',
  inject: ['dayjs'],
  components: {
    Navbar,
    Pagination,
    Confirm,
    SearchForm,
    SearchModal,
  },

  data() {
    return {
      query: {},
      today: this.dayjs().format('YYYY-MM-DD'),
      lastMonth: this.dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    }
  },

  computed: {
    results() {
      return store.getters['inspection_result/results']
    },
    prev() {
      return store.getters['inspection_result/prev']
    },
    next() {
      return store.getters['inspection_result/next']
    },
    saveQuery() {
      return store.getters['inspection_result/query']
    },
    inspectionTypes() {
      let inspectionTypeList = store.getters['inspection_type/list']
      return inspectionTypeList.map((item) => {
        return {
          value: item.name,
          name: item.name,
        }
      })
    },
    searchItems() {
      return [
        {
          id: 'inspection_result_number',
          label: '検査番号（前方一致）',
          type: 'text',
        },
        {
          id: 'inspection_type_name',
          label: '検査種別（完全一致）',
          type: 'select',
          options: this.inspectionTypes,
        },
        {
          id: 'product_model',
          label: '型式（前方一致）',
          type: 'text',
        },
        {
          id: 'product_code',
          label: '機種コード（完全一致）',
          type: 'text',
        },
        {
          id: 'client_product_code',
          label: '客先製品コード（前方一致）',
          type: 'text',
        },
        {
          id: 'inspection_name',
          label: 'シート名（前方一致）',
          type: 'text',
        },
        {
          id: 'worker',
          label: '検査員（部分一致）',
          type: 'text',
        },
        {
          id: 'date',
          label: '検査終了日',
          type: 'date',
        },
      ]
    },
  },

  async created() {
    await this.fetchInspectionTypeList()
    this.query = this.saveQuery
    if (Object.keys(this.query).length === 0) {
      this.query = { date_from: this.lastMonth, date_to: this.today }
    }
    await this.setQuery(this.query)
    await this.getData()
  },
  beforeRouteLeave(to, from, next) {
    // 詳細画面以外のページに移動する場合にデータをクリアする
    if (to.name !== 'ManageInspectionResultDetail') {
      this.clearQuery()
      this.clearData()
    }
    next()
  },

  methods: {
    ...mapActions({
      postExportData: 'export_data/postExportData',
    }),
    fetchInspectionTypeList() {
      return store.dispatch('inspection_type/fetchList')
    },
    getData() {
      return store.dispatch('inspection_result/fetchAll')
    },
    setQuery(query = {}) {
      store.commit('inspection_result/setQuery', query)
    },
    clearQuery() {
      store.commit('inspection_result/clearQuery')
    },
    clearData() {
      store.commit('inspection_result/clearData')
    },
    exportData(query = {}) {
      return store.dispatch('inspection_result/fetchExportData', query)
    },
    async movePage(page) {
      this.query = Object.assign(this.query, { page: page })
      await this.setQuery(this.query)
      await this.getData()
    },
    onOpenSearchModal() {
      this.$refs.searchModal.show()
    },
    async onUpdateSearchQuery(query = {}) {
      this.query = query
      await this.setQuery(this.query)
      await this.getData()
    },
    convertToCsvConfirm() {
      this.$refs.confirmConvertData.showConfirm(
        'データを変換',
        'この内容でCSVに変換しますか？'
      )
    },
    convertToCsv() {
      const formData = {
        application: 'inspection-results',
        conditions: Object.assign({}, this.query),
      }
      try {
        this.postExportData(formData)
        this.$store.dispatch('alert/setSuccessMessage', {
          message:
            'CSVファイルへの変換を開始しました。エクスポート管理からダウンロードしてください',
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
