<template>
  <div class="m-0 p-0">
    <div class="mb-2">
      <label for="imageInput" class="form-label">参考画像</label>
      <input
        type="file"
        class="form-control"
        id="imageInput"
        :accept="accept"
        @change="onImageChange"
      />
    </div>
    <div class="ratio ratio-16x9">
      <canvas id="imageCanvas" width="1280" height="720"></canvas>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageSelect',

  emits: ['selectedFunc'],
  props: {
    accept: {
      type: String,
      required: false,
      default: 'image/jpeg,image/png,image/webp',
    },
  },

  data() {
    return {
      imageCanvas: null,
      imageContext: null,
      image: null,
    }
  },

  mounted() {
    this.reset()
    this.imageCanvas = document.getElementById('imageCanvas')
    this.imageContext = this.imageCanvas.getContext('2d', {
      willReadFrequently: true,
    })
  },
  unmounted() {
    this.reset()
  },

  methods: {
    reset() {
      if (this.imageContext != null) {
        this.imageContext.clearRect(
          0,
          0,
          this.imageCanvas.width,
          this.imageCanvas.height
        )
      }
    },
    drawCanvas(url) {
      this.image = new Image()
      this.image.src = url
      this.image.onload = () => {
        const currentSize = this.getCurrentSize(this.imageCanvas)
        this.imageContext.drawImage(
          this.image,
          0,
          0,
          this.image.width,
          this.image.height,
          currentSize.x,
          currentSize.y,
          currentSize.w,
          currentSize.h
        )
        // 親要素に返す
        this.$emit('selectedFunc', this.imageCanvas.toDataURL('image/webp'))
      }
    },
    getCurrentSize(targetCanvas) {
      let currentW = targetCanvas.width,
        currentH = targetCanvas.height,
        currentX = 0,
        currentY = 0

      if (this.image.width < this.image.height) {
        // 縦向き・高さに合わせる
        currentW = this.image.width * (targetCanvas.height / this.image.height)
        currentH = targetCanvas.height
        currentX = (targetCanvas.width - currentW) / 2
      }
      if (this.image.width > this.image.height) {
        // 横向き
        const orgR = 9 / 16
        const imageR = this.image.height / this.image.width
        if (orgR < imageR) {
          // 比率が大きい場合は高さに合わせる
          currentW =
            this.image.width * (targetCanvas.height / this.image.height)
          currentH = targetCanvas.height
          currentX = (targetCanvas.width - currentW) / 2
        }
        if (orgR > imageR) {
          // 比率が小さい場合は幅に合わせる
          currentW = targetCanvas.width
          currentH = this.image.height * (targetCanvas.width / this.image.width)
          currentY = (targetCanvas.height - currentH) / 2
        }
      }

      return {
        w: currentW,
        h: currentH,
        x: currentX,
        y: currentY,
      }
    },
    onImageChange(e) {
      this.reset()
      const images = e.target.files || e.dataTransfer.files
      const reader = new FileReader()
      reader.readAsDataURL(images[0])
      reader.onload = () => {
        this.drawCanvas(reader.result)
      }
    },
  },
}
</script>
