<template>
  <Navbar />

  <div class="container pt-4">
    <div class="row row-cols-2 g-3">
      <div class="col">
        <router-link
          :to="{
            name: 'OperatePurchaseDefectiveItemCreate',
          }"
          class="btn btn-lg btn-outline-warning w-100 py-3"
          ><i class="bi bi-plus-square d-block" style="font-size: 2rem"></i
          >不良品登録</router-link
        >
      </div>
      <div class="col">
        <router-link
          :to="{
            name: 'AppHomeOperate',
          }"
          class="btn btn-lg btn-outline-dark w-100 py-3"
          ><i class="bi bi-house d-block" style="font-size: 2rem"></i
          >ホームに戻る</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '../components/PurchaseNavbar.vue'

export default {
  name: 'OperatePurchase',
  components: {
    Navbar,
  },

  created() {
    this.$store.dispatch('clearDataStores')
  },
}
</script>
