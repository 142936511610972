import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
  list: [],
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  results: (state) => state.data.results,
  count: (state) => state.data.count,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  current_page: (state) => state.data.current_page,
  total_pages: (state) => state.data.total_pages,
  list: (state) => state.list,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = []),
  setList: (state, list) => (state.list = list),
  clearList: (state) => (state.list = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearList')
      resolve()
    })
  },
  async fetchAll({ commit }, query_params) {
    const response = await api.get('manage/inspection_types/', {
      params: query_params,
    })
    commit('setData', response.data)
  },
  async fetchList({ state, commit }) {
    if (state.list.length > 0) {
      return
    }

    const response = await api.get('manage/inspection_types/list/')
    commit('setList', response.data)
  },
  async postData({ commit }, form_data) {
    const response = await api.post('manage/inspection_types/', form_data)
    commit('clearList')
    return response.data
  },
  async patchData({ commit }, form_data) {
    const response = await api.patch(
      'manage/inspection_types/' + form_data.id + '/',
      form_data
    )
    commit('clearList')
    return response.data
  },
  async deleteData({ commit }, id) {
    await api.delete('manage/inspection_types/' + id + '/')
    commit('clearList')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
