<template>
  <navbar>
    <template v-slot:navbarTitle>仕入先管理</template>
  </navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="d-flex gap-2 mb-3">
      <h5 class="m-0 p-0"><i class="bi bi-building me-1"></i>仕入先一覧</h5>
      <div class="ms-auto">
        <router-link
          :to="{
            name: 'SupplierImport',
          }"
          class="btn btn-dark me-2"
          ><span class="d-block d-sm-none"
            ><i class="bi bi-cloud-upload"></i
          ></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-cloud-upload me-1"></i>CSVから一括で登録する</span
          ></router-link
        >
        <button
          type="button"
          v-on:click.prevent="showSupplierForm()"
          class="btn btn-primary"
        >
          <span class="d-block d-sm-none"><i class="bi bi-plus-lg"></i></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-plus-lg me-1"></i>仕入先情報を登録する</span
          >
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-start gap-2 mb-2">
      <div class="me-auto col-6 col-lg-4 col-xl-3">
        <search-form
          :query="query"
          query-key="code"
          placeholder="仕入先コードで検索"
          :use-search-modal="true"
          @update-search-query="onUpdateSearchQuery"
          @open-search-modal="onOpenSearchModal"
        ></search-form>
      </div>
      <pagination :prev="prev" :next="next" @move-page="movePage"></pagination>
    </div>

    <!-- suppliers -->
    <div v-if="suppliers && suppliers.length > 0">
      <data-table :headers="dataHeaders" :items="suppliers">
        <template #code="{ item }">
          <router-link
            :to="{
              name: 'SupplierDetail',
              params: { id: item.id },
            }"
            >{{ item.code }}</router-link
          >
        </template>
      </data-table>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>

    <!-- Modal -->
    <search-modal
      :query="query"
      :items="searchItems"
      @update-search-query="onUpdateSearchQuery"
      ref="searchModal"
    ></search-modal>

    <supplier-form ref="supplierForm">
      <template v-slot:modalTitle>仕入先データ登録</template>
    </supplier-form>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import DataTable from '@/components/common/DataTable.vue'
import SearchForm from '@/components/common/SearchForm.vue'
import SearchModal from '@/components/common/SearchModal.vue'
import SupplierForm from '@/components/manage/SupplierForm.vue'

export default {
  name: 'SupplierList',
  components: {
    Navbar,
    Pagination,
    DataTable,
    SearchForm,
    SearchModal,
    SupplierForm,
  },

  data() {
    return {
      query: {},
      dataHeaders: [
        {
          key: 'code',
          label: '仕入先コード',
          class: 'col-2',
          format: 'code',
        },
        {
          key: 'name',
          label: '仕入先名',
          class: 'col-3',
          format: 'string',
        },
        {
          key: 'kana',
          label: '仕入先名（ふりがな）',
          class: 'col-3',
          format: 'string',
        },
        {
          key: 'created_at',
          label: '登録日時',
          class: 'col-2',
          format: 'datetime',
        },
        {
          key: 'updated_at',
          label: '更新日時',
          class: 'col-2',
          format: 'datetime',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      suppliers: 'supplier/results',
      prev: 'supplier/prev',
      next: 'supplier/next',
      saveQuery: 'supplier/query',
    }),
    searchItems() {
      return [
        {
          id: 'code',
          label: '仕入先コード（前方一致）',
          type: 'text',
        },
        {
          id: 'name',
          label: '仕入先名（部分一致）',
          type: 'text',
        },
        {
          id: 'kana',
          label: 'ふりがな（部分一致）',
          type: 'text',
        },
        {
          id: 'postal_code',
          label: '郵便番号（前方一致）',
          type: 'text',
        },
        {
          id: 'phone_number',
          label: '電話番号（前方一致）',
          type: 'text',
        },
      ]
    },
  },

  async created() {
    this.query = this.saveQuery
    await this.setQuery(this.query)
    await this.getData()
  },
  beforeRouteLeave(to, from, next) {
    // 詳細画面以外のページに移動する場合にデータをクリアする
    if (to.name !== 'SupplierDetail') {
      this.clearQuery()
      this.clearData()
    }
    next()
  },

  methods: {
    ...mapActions({
      getData: 'supplier/fetchAll',
    }),
    ...mapMutations({
      setQuery: 'supplier/setQuery',
      clearQuery: 'supplier/clearQuery',
      clearData: 'supplier/clearData',
    }),
    async movePage(page) {
      this.query = Object.assign(this.query, { page: page })
      await this.setQuery(this.query)
      await this.getData()
    },
    onOpenSearchModal() {
      this.$refs.searchModal.show()
    },
    async onUpdateSearchQuery(query = {}) {
      this.query = query
      await this.setQuery(this.query)
      await this.getData()
    },
    showSupplierForm() {
      this.$refs.supplierForm.show()
    },
  },
}
</script>
