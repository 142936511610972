import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
  detail: {},
  list: [],
  query: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  inspections: (state) => state.data.results,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  detail: (state) => state.detail,
  list: (state) => state.list,
  query: (state) => state.query,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = []),
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
  setList: (state, list) => (state.list = list),
  clearList: (state) => (state.list = []),
  setQuery: (state, query) => (state.query = query),
  clearQuery: (state) => (state.query = {}),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearDetail')
      commit('clearList')
      commit('clearQuery')
      resolve()
    })
  },
  /**
   * データを取得する
   *
   * @return {void}
   */
  async fetchAll({ commit, state }) {
    // パラメーターの値が空の場合は削除する
    Object.keys(state.query).forEach(
      (key) => state.query[key] === '' && delete state.query[key]
    )
    const response = await api.get('manage/inspections/', {
      params: state.query,
    })
    commit('setData', response.data)
  },
  /**
   * データを1件取得する
   *
   * @param {number}
   * @return {void}
   */
  async fetchData({ commit }, id) {
    const response = await api.get('manage/inspections/' + id + '/')
    commit('setDetail', response.data)
  },
  /**
   * データをリスト形式で取得する
   *
   * @param {object}
   * @return {void}
   */
  async fetchList({ commit }, queryParams) {
    const response = await api.get('manage/inspections/list/', {
      params: queryParams,
    })
    commit('setList', response.data)
  },
  /**
   * データを追加または更新する
   *
   * @param {object}
   * @return {object}
   */
  async postOrPatch({ dispatch }, formData) {
    try {
      // 必要なデータのみにする
      const requiredKeys = ['id', 'name']
      const submitData = Object.fromEntries(
        Object.entries(formData).filter(([key]) => requiredKeys.includes(key))
      )

      let response
      if ('id' in submitData && submitData['id']) {
        response = await dispatch('patchData', submitData)
        await dispatch('relatedDataDelete', formData)
      } else {
        response = await dispatch('postData', submitData)
      }
      await dispatch('relatedDataPost', formData)
      return response
    } catch (error) {
      console.error('Failed to post or patch')
      throw error
    }
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {object}
   */
  async postData({ commit }, formData) {
    try {
      const response = await api.post('manage/inspections/', formData)
      commit('setDetail', response.data)
      return response.data
    } catch (error) {
      console.error('Failed to post data:', error)
      throw error
    }
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {object}
   */
  async patchData({ dispatch }, formData) {
    try {
      const response = await api.patch(
        'manage/inspections/' + formData.id + '/',
        formData
      )
      dispatch('fetchData', formData.id)
      return response.data
    } catch (error) {
      console.error('Failed to patch data:', error)
      throw error
    }
  },
  /**
   * 関連データを追加または更新する
   *
   * @param {object}
   * @return {void}
   */
  async relatedDataPost({ dispatch }, formData) {
    // 検査項目
    if ('items' in formData) {
      dispatch('inspection_item/bulkPostOrPatch', formData['items'], {
        root: true,
      })
    }
  },
  /**
   * 関連データを削除する
   *
   * @param {object}
   * @return {void}
   */
  async relatedDataDelete({ state, dispatch }, formData) {
    // 検査項目
    if ('items' in formData) {
      const diffItemIds = getDiffIds(state.detail?.items, formData['items'])
      if (diffItemIds.length > 0) {
        dispatch('inspection_item/bulkDelete', diffItemIds, {
          root: true,
        })
      }
    }
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {number}
   */
  async deleteData({ commit }, id) {
    try {
      await api.delete('manage/inspections/' + id + '/')
      commit('clearData')
      commit('clearDetail')
      return id
    } catch (error) {
      console.error('Failed to delete data:', error)
      throw error
    }
  },
  /**
   * データを複製する
   *
   * @return {Object}
   */
  getDuplicateData({ state }) {
    return new Promise((resolve, reject) => {
      if (!Object.keys(state.detail).length) {
        reject()
      }

      const data = state.detail
      delete data['id']
      delete data['calls']
      data['items'].forEach((item) => {
        item.original_id = item.id
        delete item.id
        delete item.type_name
      })

      data['name'] = data['name'] + '_copy'
      resolve(data)
    })
  },
}

/**
 * データを比較して差分のIDを取得する
 *
 * @return {Array}
 */
function getDiffIds(current_data, form_data) {
  const ids = form_data
    .map((obj) => {
      if (Object.prototype.hasOwnProperty.call(obj, 'id')) {
        return obj.id
      }
    })
    .filter((id) => id !== undefined)
  const current_ids = current_data
    .map((obj) => {
      if (Object.prototype.hasOwnProperty.call(obj, 'id')) {
        return obj.id
      }
    })
    .filter((id) => id !== undefined)
  return current_ids.filter((x) => !ids.includes(x))
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
