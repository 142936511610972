<template>
  <div class="flex-grow">
    <canvas id="dataChart" class="w-full h-full"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import { onMounted, ref } from 'vue';

Chart.register(...registerables)

export default {
  name: 'DataGraph',
  components: {},

  props: {
    datasetLabel: {
      type: String,
      default: '数',
    },
  },

  setup(props) {
    const chartInstance = ref(null)

    const createChart = (labels, data) => {
      const ctx = document.getElementById('dataChart').getContext('2d')
      chartInstance.value = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: props.datasetLabel,
              data: data,
              backgroundColor: 'rgba(54, 162, 235, 0.5)',
              barThickness: 20,
            },
          ],
        },
        options: {
          indexAxis: 'y',
          maintainAspectRatio: false, // グラフをキャンバスにフィットさせる
          scales: {
            x: {
              beginAtZero: true,
              // data の count の最大値もしくは30に合わせて x 軸の最大値を設定
              max: Math.max(...data) > 30 ? Math.max(...data) : 30,
              ticks: {
                stepSize: 1,
              },
            },
          },
        },
      })
    }

    const updateChart = async (graphData) => {
      if (!graphData) {
        return
      }

      try {
        const labels = Object.values(graphData).map((item) => item.name)
        const data = Object.values(graphData).map((item) => item.count)

        if (chartInstance.value) {
          chartInstance.value.destroy()
        }

        createChart(labels, data)
      } catch (error) {
        console.error('Error loading group counts:', error)
      }
    }

    onMounted(() => {
      updateChart()
    })

    return {
      updateChart,
    }
  },
}
</script>
