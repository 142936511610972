<template>
  <input
    ref="searchInput"
    v-model="inputValue"
    type="text"
    class="form-control"
    :placeholder="placeholder"
    @input="onInput"
    @focus="showDropdown = true"
    @blur="hideDropdown"
  />
  <ul
    v-if="showDropdown && options.length"
    class="dropdown-menu show m-0 p-0"
    :style="{ width: dropdownWidth + 'px' }"
  >
    <li
      v-for="option in options"
      :key="option.id"
      @mousedown.prevent="selectOption(option)"
      class="dropdown-item"
    >
      <slot :option="option"> {{ option.name }} </slot>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'FormSearchInput',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '品目コード',
    },
  },
  emits: ['update:modelValue', 'fetchOptions', 'selectOption'],
  data() {
    return {
      inputValue: this.modelValue,
      showDropdown: false,
      dropdownWidth: 0,
    }
  },
  watch: {
    modelValue(newValue) {
      this.inputValue = newValue
    },
    inputValue(newValue) {
      this.$emit('update:modelValue', newValue)
    },
  },
  methods: {
    onInput() {
      if (this.inputValue.length >= 2) {
        this.$emit('fetchOptions', this.inputValue)
        this.showDropdown = true
      } else {
        this.showDropdown = false
      }
    },
    selectOption(option) {
      this.inputValue = option.code
      this.showDropdown = false
      this.$emit('selectOption', option)
    },
    hideDropdown() {
      setTimeout(() => {
        this.showDropdown = false
      }, 200)
    },
    updateDropdownPosition() {
      this.$nextTick(() => {
        if (this.$refs.searchInput) {
          const inputRect = this.$refs.searchInput.getBoundingClientRect()
          this.dropdownWidth = inputRect.width
        }
      })
    },
  },
  mounted() {
    this.updateDropdownPosition()
    window.addEventListener('resize', this.updateDropdownPosition)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDropdownPosition)
  },
}
</script>

<style scoped>
.dropdown-menu.show {
  top: 37px;
  left: 33px;
  background-color: #fff;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}
</style>
