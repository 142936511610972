import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
  result: [],
  list: [],
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  results: (state) => state.data.results,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  result: (state) => state.result,
  list: (state) => state.list,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = {}),
  setResult: (state, result) => (state.result = result),
  clearResult: (state) => (state.result = []),
  setList: (state, list) => (state.list = list),
  clearList: (state) => (state.list = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearResult')
      commit('clearList')
      resolve()
    })
  },
  /**
   * リストデータを取得する
   *
   * @param {object} queryParams
   * @return {object}
   */
  async fetchList({ commit }, queryParams) {
    const response = await api.get('operate/picking-list/list/', {
      params: queryParams,
    })
    commit('setList', response.data)
  },
  /**
   * 一覧データを取得する
   *
   * @param {object} queryParams
   * @return {object}
   */
  async fetchAll({ commit }, queryParams) {
    const response = await api.get('operate/picking-list/', {
      params: queryParams,
    })
    commit('setData', response.data)
  },
  /**
   * データ検索
   *
   * @param {object} queryParams { code: 123456 }
   * @return {array}
   */
  async searchData({ commit }, queryParams) {
    const response = await api.get('operate/picking-list/search/', {
      params: queryParams,
    })
    commit('setResult', response.data)
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {object}
   */
  async patchData({}, formData) {
    if ('stock_location' in formData) {
      formData.stock_location_id = formData.stock_location.id
    }

    // changed_quantity が 0 の場合は除外する
    if (formData.changed_quantity === 0) {
      return
    }

    // picket_by の値がある場合は除外する
    if (formData.picket_by) {
      return
    }

    // 必要なデータのみにする
    const requiredKeys = [
      'stock_location_id',
      'changed_quantity',
      'changed_memo',
    ]
    const submitData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) =>
        requiredKeys.includes(key)
      )
    )

    try {
      await api.patch('operate/picking-list/' + formData.id + '/', submitData)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを一括更新する
   *
   * @param {array} formData
   * @return {void}
   */
  async bulkPatchData({}, formData) {
    // formData の数だけ patchData を実行する
    formData.forEach(async (item) => {
      await actions.patchData({}, item)
    })
    // // 必要なデータのみにする
    // const requiredKeys = [
    //   'stock_location_id',
    //   'changed_quantity',
    //   'changed_memo',
    // ]

    // const submitData = formData.map((item) => {
    //   if ('stock_location' in item && item['stock_location']) {
    //     item['stock_location_id'] = item['stock_location']['id']
    //   }

    //   // changed_quantity が 0 の場合は除外する
    //   if (item['changed_quantity'] === 0) {
    //     return
    //   }

    //   return Object.fromEntries(
    //     Object.entries(item).filter(([key, value]) =>
    //       requiredKeys.includes(key)
    //     )
    //   )
    // })

    // try {
    //   await api.patch('operate/picking-list/bulk-update/', submitData)
    // } catch (error) {
    //   throw error
    // }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
