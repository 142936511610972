<template>
  <Navbar>
    <template v-slot:navbarTitle>在庫管理</template>
  </Navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="clearfix mb-3">
      <div class="float-end">
        <router-link
          :to="{
            name: 'ManageStockTransactionList',
            query: {
              code: stock.code,
            },
          }"
          class="btn btn-outline-primary me-1"
        >
          <span class="d-block d-sm-none"><i class="bi bi-graph-up"></i></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-graph-up me-1"></i>変動履歴を見る</span
          >
        </router-link>
        <button
          type="button"
          v-on:click.prevent="removeData()"
          class="btn btn-outline-danger me-1"
        >
          <span class="d-block d-sm-none"><i class="bi bi-x"></i></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-x me-1"></i>削除する</span
          >
        </button>
        <button
          type="button"
          v-on:click.prevent="showStockForm()"
          class="btn btn-outline-success me-1"
        >
          <span class="d-block d-sm-none"><i class="bi bi-pencil"></i></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-pencil me-1"></i>編集する</span
          >
        </button>
        <router-link
          :to="{
            name: 'ManageStockList',
          }"
          class="btn btn-outline-dark"
        >
          <span class="d-block d-sm-none"
            ><i class="bi bi-arrow-left"></i
          ></span>
          <span class="d-none d-sm-block">一覧に戻る</span>
        </router-link>
      </div>
      <h5><i class="bi bi-box me-1"></i>在庫品目詳細</h5>
    </div>

    <div v-if="!is_loading">
      <h5>
        品目情報
        <a
          class="fs-6"
          data-bs-toggle="collapse"
          href="#resultData"
          role="button"
          aria-expanded="true"
          aria-controls="resultData"
          ><i class="bi bi-arrows-collapse"></i>
        </a>
      </h5>

      <div class="collapse show" id="resultData">
        <div class="row row-cols-1 row-cols-lg-2 g-1 g-lg-2">
          <div class="col">
            <table class="table table-bordered table-row bg-white mb-3">
              <tbody>
                <tr>
                  <th class="col-sm-4 bg-light">品目コード</th>
                  <td class="col-sm-8">{{ stock.code }}</td>
                </tr>
                <tr>
                  <th class="bg-light">型式</th>
                  <td>{{ stock.item_model }}</td>
                </tr>
                <tr>
                  <th class="bg-light">品目名</th>
                  <td>{{ stock.name }}</td>
                </tr>
                <tr>
                  <th class="bg-light">品目分類</th>
                  <td>
                    {{
                      stock.classifications
                        ? stock.classifications
                            .map((obj) => obj.name)
                            .join(', ')
                        : ''
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="bg-light">在庫数量</th>
                  <td>
                    <span :class="checkQuantity(stock)">{{
                      stock.quantity
                    }}</span>
                    <a
                      v-on:click.prevent="showIncomingForm()"
                      href="#"
                      class="text-info ms-3"
                      ><i class="bi bi-plus-square-fill me-1"></i>入庫</a
                    >
                    <a
                      v-on:click.prevent="showOutgoingForm()"
                      href="#"
                      class="text-warning ms-2"
                      ><i class="bi bi-dash-square-fill me-1"></i>出庫</a
                    >
                  </td>
                </tr>
                <tr>
                  <th class="bg-light">在庫閾値</th>
                  <td>{{ stock.threshold }}</td>
                </tr>
                <tr>
                  <th class="bg-light">メモ</th>
                  <td>{{ stock.memo }}</td>
                </tr>
                <tr>
                  <th class="bg-light">更新日時</th>
                  <td>{{ stock.updated_at }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col">
            <div
              v-if="stock.images && stock.images.length > 0"
              class="row row-cols-2 row-cols-md-3 g-1 g-lg-2"
            >
              <div v-for="image in stock.images" :key="image.id" class="col">
                <img
                  @click="showImage(image.file_path)"
                  :src="image.file_path"
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <!-- detail -->
      <h5>在庫状況</h5>
      <div v-if="stock.locations && stock.locations.length > 0">
        <div class="table-responsive">
          <table class="table table-bordered bg-white">
            <thead>
              <tr>
                <th scope="col" class="col-sm-7">保管場所名</th>
                <th scope="col" class="col-sm-1">保管在庫数</th>
                <th scope="col" class="col-sm-2"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="location in stock.locations" :key="location.id">
                <td>{{ location.name }}</td>
                <td class="text-end">{{ location.quantity }}</td>
                <td class="text-center">
                  <router-link
                    :to="{
                      name: 'ManageStockTransactionList',
                      query: {
                        code: stock.code,
                        stock_location_id: location.id,
                      },
                    }"
                    >変動履歴を見る</router-link
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p v-else class="mt-3">該当するデータはありません。</p>

      <ImageModal ref="imageModal" />
    </div>

    <DeleteDialog ref="confirmDataDelete" @confirm-true="confirmDataDelete" />
    <StockForm ref="stockForm">
      <template v-slot:modalTitle>在庫品目編集</template>
    </StockForm>
    <StockFlowForm ref="stockIncomingForm" submit-method="incoming">
      <template v-slot:modalTitle>在庫入庫</template>
      <template v-slot:submitButton>入庫</template>
    </StockFlowForm>
    <StockFlowForm ref="stockOutgoingForm" submit-method="outgoing">
      <template v-slot:modalTitle>在庫出庫</template>
      <template v-slot:submitButton>出庫</template>
    </StockFlowForm>
  </main>
</template>

<script>
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import DeleteDialog from '@/components/AppDeleteDialog.vue'
import ImageModal from '@/components/TheImageModal.vue'
import StockForm from '../components/StockForm.vue'
import StockFlowForm from '../components/StockFlowForm.vue'

export default {
  name: 'ManageStockDetail',
  components: {
    Navbar,
    DeleteDialog,
    ImageModal,
    StockForm,
    StockFlowForm,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      is_loading: true,
    }
  },
  computed: {
    stock() {
      return store.getters['manage_stock/detail']
    },
  },

  async created() {
    try {
      await this.getData(this.id)
    } catch (error) {
      if (error.status) {
        window.setTimeout(() => {
          this.$router.push({
            name: 'ManageStockList',
          })
        }, this.$store.getters['alert/timeout'])
      }
    } finally {
      this.is_loading = false
    }
  },

  methods: {
    getData(id) {
      return store.dispatch('manage_stock/fetchData', id)
    },
    deleteData(id) {
      return store.dispatch('manage_stock/deleteData', id)
    },
    showImage(url) {
      this.$refs.imageModal.showModal(url)
    },
    showStockForm() {
      this.$refs.stockForm.show(this.stock)
    },
    showIncomingForm() {
      this.$refs.stockIncomingForm.show()
    },
    showOutgoingForm() {
      this.$refs.stockOutgoingForm.show()
    },
    checkQuantity(data) {
      switch (true) {
        case parseFloat(data.quantity) < parseFloat(data.threshold):
          return 'text-danger fw-semibold'
        case parseFloat(data.quantity) == parseFloat(data.threshold):
          return 'text-warning fw-semibold'
      }
    },
    removeData() {
      this.$refs.confirmDataDelete.showDialog(
        '在庫品目データ',
        this.stock.code,
        ['在庫', '変動履歴']
      )
    },
    async confirmDataDelete() {
      try {
        await this.deleteData(this.id)
        await this.$router.push({
          name: 'ManageStockList',
        })
      } catch (e) {
        if (e.response.status == 400) {
          this.$store.dispatch('alert/setErrorMessage', {
            message: e.response.data,
          })
        }
      }
    },
  },
}
</script>
