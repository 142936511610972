import OperateInspectionPlanHome from './views/Home.vue'
import OperateInspectionPlanProductList from './views/InspectionPlanProductList.vue'
import OperateInspectionPlanProductDetail from './views/InspectionPlanProductDetail.vue'

export default {
  routes: [
    {
      path: '/operate/inspection_plan',
      component: OperateInspectionPlanHome,
      name: 'OperateInspectionPlanHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'products',
          component: OperateInspectionPlanProductList,
          name: 'OperateInspectionPlanProductList',
        },
        {
          path: 'products/:id([0-9]+)',
          component: OperateInspectionPlanProductDetail,
          name: 'OperateInspectionPlanProductDetail',
          props: (route) => ({ id: Number(route.params.id) }),
        },
      ],
    },
  ],
}
