<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="modal_id"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">型式・型番を追加</h5>
        </div>
        <div class="modal-body">
          <div class="row g-3">
            <div class="col-12">
              <label for="inspection_type" class="form-label">検査種別</label>
              <select
                v-model="inspection_call.inspection_type_id"
                class="form-select"
                :class="{
                  'is-invalid': v$.inspection_call.inspection_type_id.$error,
                }"
                id="inspection_type"
              >
                <option :value="undefined">---</option>
                <option
                  v-for="inspection_type in inspection_types"
                  :key="inspection_type.id"
                  :value="inspection_type.id"
                >
                  {{ inspection_type.name }}
                </option>
              </select>
            </div>
            <div class="col-12">
              <label for="code" class="form-label">型式・型番</label>
              <input
                v-model="inspection_call.code"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': v$.inspection_call.code.$error }"
                id="code"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            閉じる
          </button>
          <button
            type="button"
            class="btn btn-dark"
            v-on:click.prevent="insert()"
          >
            追加
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { useVuelidate } from '@vuelidate/core'
import { required, integer } from '@vuelidate/validators'

export default {
  name: 'InspectionCallForm',
  components: {},

  setup() {
    return { v$: useVuelidate() }
  },

  emits: ['insertCall'],
  props: {
    inspection_id: {
      type: Number,
    },
  },

  data() {
    return {
      modal: null,
      inspection_call: {},
    }
  },
  computed: {
    inspection_types() {
      return store.getters['inspection_type/list']
    },
    modal_id: function () {
      let chars = 'abcdefghijklmnopqrstuvwxyz0123456789'
      let rand_str = ''
      for (let i = 0; i < 8; i++) {
        rand_str += chars.charAt(Math.floor(Math.random() * chars.length))
      }
      return 'form-modal-' + rand_str
    },
  },
  validations() {
    return {
      inspection_call: {
        inspection_type_id: { required, integer },
        code: { required },
      },
    }
  },

  async created() {
    await this.fetchInspectionTypeList()
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modal_id))
    document
      .getElementById(this.modal_id)
      .addEventListener('hidden.bs.modal', this.clearForm)
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    fetchInspectionTypeList() {
      return store.dispatch('inspection_type/fetchList')
    },
    show() {
      this.modal.show()
    },
    async insert() {
      const result = await this.v$.$validate()
      if (!result) {
        return
      }
      this.inspection_call.inspection_id = this.inspection_id
      const data = await store.dispatch(
        'inspection_call/postData',
        this.inspection_call
      )
      this.$emit('insertCall', data)
      this.modal.hide()
    },
    clearForm() {
      this.v$.$reset()
      this.inspection_call = {}
    },
  },
}
</script>
