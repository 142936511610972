import ManagePurchaseHome from './views/Home.vue'
import ManagePurchaseDefectiveItemAggregate from './views/PurchaseDefectiveItemAggregate.vue'
import ManagePurchaseDefectiveItemCauseList from './views/PurchaseDefectiveItemCauseList.vue'
import ManagePurchaseDefectiveItemList from './views/PurchaseDefectiveItemList.vue'

export default {
  routes: [
    {
      path: '/manage/purchase',
      component: ManagePurchaseHome,
      name: 'ManagePurchaseHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'defective-items',
          component: ManagePurchaseDefectiveItemList,
          name: 'ManagePurchaseDefectiveItemList',
        },
        {
          path: 'defective-item-aggregate',
          component: ManagePurchaseDefectiveItemAggregate,
          name: 'ManagePurchaseDefectiveItemAggregate',
        },
        {
          path: 'defective-item-causes',
          component: ManagePurchaseDefectiveItemCauseList,
          name: 'ManagePurchaseDefectiveItemCauseList',
        },
      ],
    },
  ],
}
