import { createStore } from 'vuex'
import inspection_call from './inspection_call'
import inspection_item from './inspection_item'
import inspection_master from './inspection_master'
import inspection_result from './inspection_result'
import inspection_type from './inspection_type'
import inspection from './inspection'

const store = createStore({
  actions: {
    clearAllStores({ dispatch }) {
      dispatch('inspection_call/clearAll', null, { root: true })
      dispatch('inspection_item/clearAll', null, { root: true })
      dispatch('inspection_master/clearAll', null, { root: true })
      dispatch('inspection_result/clearAll', null, { root: true })
      dispatch('inspection_type/clearAll', null, { root: true })
      dispatch('inspection/clearAll', null, { root: true })
    },
    clearDataStores({ dispatch }) {
      dispatch('inspection_call/clearAll', null, { root: true })
      dispatch('inspection_item/clearAll', null, { root: true })
      dispatch('inspection_master/clearAll', null, { root: true })
      dispatch('inspection_result/clearAll', null, { root: true })
      dispatch('inspection_type/clearAll', null, { root: true })
      dispatch('inspection/clearAll', null, { root: true })
    },
  },
  modules: {
    inspection_call,
    inspection_item,
    inspection_master,
    inspection_result,
    inspection_type,
    inspection,
  },
})

export default store
