import { createStore } from 'vuex'
import operate_inspection_plan_product from './inspection_plan_product'

const store = createStore({
  actions: {
    clearAllStores({ dispatch }) {
      dispatch('operate_inspection_plan_product/clearAll', null, { root: true })
    },
    clearDataStores({ dispatch }) {
      dispatch('operate_inspection_plan_product/clearAll', null, { root: true })
    },
  },
  modules: {
    operate_inspection_plan_product,
  },
})

export default store
