<template>
  <div class="row row-cols-1 gy-3">
    <div class="col">
      <div class="ratio ratio-16x9 mb-3">
        <canvas
          id="videoCanvas"
          width="1280"
          height="720"
          class="bg-dark"
        ></canvas>
      </div>
      <div class="row">
        <div class="col">
          <button class="btn btn-lg btn-secondary w-100 py-4">
            カメラ起動
          </button>
        </div>
        <div class="col">
          <button class="btn btn-lg btn-danger w-100 py-4">クリア</button>
        </div>
      </div>
    </div>
    <div class="col">
      <button class="btn btn-lg btn-success w-100 py-4">
        <i class="bi bi-check-circle-fill me-2"></i> 撮影しました
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InspectionCamera',
}
</script>
