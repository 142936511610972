<template>
  <nav>
    <ul class="pagination m-0">
      <li class="page-item" :class="this.prev ? '' : 'disabled'">
        <a href="#" class="page-link" @click.prevent="movePrev()"
          ><span aria-hidden="true">&laquo;</span></a
        >
      </li>
      <li class="page-item" :class="this.next ? '' : 'disabled'">
        <a href="#" class="page-link" @click.prevent="moveNext()"
          ><span aria-hidden="true">&raquo;</span></a
        >
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'AppNoCountPagination',

  props: {
    prev: String,
    next: String,
  },
  emits: ['movePage'],

  methods: {
    movePrev() {
      if (this.prev) {
        const page = this.getPageFromUrl(this.prev)
        this.movePage(page)
      }
    },
    moveNext() {
      if (this.next) {
        const page = this.getPageFromUrl(this.next)
        this.movePage(page)
      }
    },
    movePage(page) {
      this.$emit('movePage', page)
    },
    getPageFromUrl(urlString) {
      const url = new URL(urlString)
      const urlParams = new URLSearchParams(url.search)
      return urlParams.get('page')
    },
  },
}
</script>
