<template>
  <nav class="navbar navbar-expand navbar-dark bg-dark">
    <div class="container-fluid">
      <h5 class="navbar-brand mb-0">
        <i class="bi bi-cloud-upload me-1"></i>型式・型番データアップロード
      </h5>
      <div class="d-flex">
        <div v-if="hasData">
          <button
            class="btn btn-primary me-2"
            v-on:click.prevent="formSubmit()"
          >
            アップロード
          </button>
          <button
            class="btn btn-dark border-light me-2"
            v-on:click.prevent="formReset()"
          >
            リセット
          </button>
        </div>
        <router-link
          :to="{
            name: 'ManageInspectionCall',
          }"
          class="btn btn-secondary"
          >キャンセル</router-link
        >
      </div>
    </div>
  </nav>
  <main id="main" class="container-fluid pt-3">
    <div class="row">
      <div class="col-lg-3">
        <vue-csv-import v-model="calls" :fields="fields" ref="csvImport">
          <vue-csv-input
            :headers="headers"
            :parseConfig="parseConfig"
            :fileMimeTypes="['text/csv']"
            accept="text/csv"
            class="form-control"
            @change="importDataReset"
          ></vue-csv-input>
          <vue-csv-errors></vue-csv-errors>
          <div class="card card-body mt-3">
            <vue-csv-map
              :noThead="true"
              :selectAttributes="{ class: 'form-select' }"
            ></vue-csv-map>
          </div>
        </vue-csv-import>
        <div v-if="hasData">
          <div class="text-end my-2">件数：{{ calls.length }}</div>
        </div>
      </div>

      <div class="col-lg-9">
        <!-- calls -->
        <div v-if="hasData">
          <div class="table-responsive">
            <table class="table table-bordered bg-white">
              <thead>
                <tr>
                  <th scope="col" class="col-sm-1">ID</th>
                  <th scope="col" class="col-sm-4">型式・型番</th>
                  <th scope="col" class="col-sm-3">検査種別</th>
                  <th scope="col" class="col-sm-4">検査シート</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(call, index) in calls" :key="index">
                  <td class="text-center">{{ call.id }}</td>
                  <td>{{ call.code }}</td>
                  <td
                    class="text-center"
                    :class="{ 'text-danger': !call.inspection_type_id }"
                  >
                    {{ call.inspection_type_name }}
                  </td>
                  <td :class="{ 'text-danger': !call.inspection_id }">
                    {{ call.inspection_name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p v-else>CSVファイルを選択してください。</p>
      </div>
    </div>
  </main>
</template>

<script>
import store from '../store'
import {
  VueCsvSubmit,
  VueCsvMap,
  VueCsvInput,
  VueCsvErrors,
  VueCsvImport,
} from 'vue-csv-import'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, integer } from '@vuelidate/validators'

export default {
  name: 'ManageInspectionCallImport',
  components: {
    VueCsvSubmit,
    VueCsvMap,
    VueCsvInput,
    VueCsvErrors,
    VueCsvImport,
  },

  setup: () => ({
    v$: useVuelidate({ $stopPropagation: true, $scope: false }),
  }),

  data() {
    return {
      fields: {
        id: { required: false, label: 'ID' },
        code: { required: true, label: '型式・型番' },
        inspection_type_name: { required: false, label: '検査種別' },
        inspection_name: { required: false, label: '検査シート' },
      },
      headers: false,
      parseConfig: { encoding: 'sjis' },
      calls: [],
      hasData: false,
    }
  },
  computed: {
    inspection_types() {
      return store.getters['inspection_type/list']
    },
    inspections() {
      return store.getters['inspection/list']
    },
  },
  validations() {
    return {
      calls: {
        $each: helpers.forEach({
          id: { integer },
          code: { required },
          inspection_type_id: { required, integer },
          inspection_id: { required, integer },
        }),
      },
    }
  },

  watch: {
    calls: function () {
      this.hasData = this.calls && this.calls.length > 0
      this.checkObjectInArray(this.calls)
    },
  },

  async created() {
    await this.fetchInspectionTypeList()
    await this.fetchInspectionList()
  },

  methods: {
    fetchInspectionTypeList() {
      return store.dispatch('inspection_type/fetchList')
    },
    fetchInspectionList() {
      return store.dispatch('inspection/fetchList')
    },
    formReset() {
      this.$router.go({ path: this.$router.currentRoute.path, force: true })
    },
    async formSubmit() {
      const result = await this.v$.$validate()
      if (!result) {
        this.$store.dispatch('alert/setErrorMessage', {
          message: 'データに不備があります。CSVの内容を確認してください。',
        })
        return
      }
      await store
        .dispatch('inspection_call/bulkPostOrPatch', this.calls)
        .then(() => {
          this.$store.dispatch('alert/setSuccessMessage', {
            message: '保存しました。',
          })
          window.setTimeout(async () => {
            await this.$router.push({
              name: 'ManageInspectionCall',
            })
          }, this.$store.getters['alert/timeout'])
        })
    },
    importDataReset() {
      this.calls = []
      this.$refs.csvImport.VueCsvImportData.map = {}
    },
    checkObjectInArray() {
      const typeNames = this.inspection_types.map((item) => item.name)
      const inspectionNames = this.inspections.map((item) => item.name)

      this.calls.forEach((obj) => {
        // インポートしたデータの検査種別がAPIで取得した配列に含まれているかチェック
        if (typeNames.includes(obj.inspection_type_name)) {
          obj.inspection_type_id = this.inspection_types.find(
            (item) => item.name === obj.inspection_type_name
          ).id
        } else {
          obj.inspection_type_id = null
        }
        // インポートしたデータの検査シート名がAPIで取得した配列に含まれているかチェック
        if (inspectionNames.includes(obj.inspection_name)) {
          obj.inspection_id = this.inspections.find(
            (item) => item.name === obj.inspection_name
          ).id
        } else {
          obj.inspection_id = null
        }
      })
    },
  },
}
</script>
