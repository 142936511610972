import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  inspection_item_types: [],
  inspection_result_number_rules: [],
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  inspection_item_types: (state) => state.inspection_item_types,
  inspection_result_number_rules: (state) =>
    state.inspection_result_number_rules,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setInspectionItemTypes: (state, inspection_item_types) =>
    (state.inspection_item_types = inspection_item_types),
  clearInspectionItemTypes: (state) => (state.inspection_item_types = []),
  setInspectionResultNumberRules: (state, inspection_result_number_rules) =>
    (state.inspection_result_number_rules = inspection_result_number_rules),
  clearInspectionResultNumberRules: (state) =>
    (state.inspection_result_number_rules = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearInspectionItemTypes')
      commit('clearInspectionResultNumberRules')
      resolve()
    })
  },
  async fetchInspectionItemTypeList({ state, commit }) {
    if (state.inspection_item_types.length > 0) {
      return
    }

    const response = await api.get('manage/inspection_item_types/list/')
    commit('setInspectionItemTypes', response.data)
  },
  async fetchInspectionResultNumberRuleList({ state, commit }) {
    if (state.inspection_result_number_rules.length > 0) {
      return
    }

    const response = await api.get('manage/inspection_result_number_rules/list/')
    commit('setInspectionResultNumberRules', response.data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
