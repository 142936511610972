<template>
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modalTitle">氏名を変更</slot>
          </h5>
        </div>
        <div class="modal-body">
          <div class="row g-3">
            <div class="col-6">
              <label :for="modalId + '_form_last_name'" class="form-label"
                >姓</label
              >
              <input
                v-model="formData.last_name"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.last_name }"
                :id="modalId + '_form_last_name'"
              />
              <div v-if="formErrors?.last_name" class="invalid-feedback">
                {{ formErrors.last_name }}
              </div>
            </div>
            <div class="col-6">
              <label :for="modalId + '_form_first_name'" class="form-label"
                >名</label
              >
              <input
                v-model="formData.first_name"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.first_name }"
                :id="modalId + '_form_first_name'"
              />
              <div v-if="formErrors?.first_name" class="invalid-feedback">
                {{ formErrors.first_name }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            閉じる
          </button>
          <button
            type="button"
            class="btn btn-dark"
            v-on:click.prevent="formSubmit()"
          >
            <slot name="submitButton">保存</slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { mapActions } from 'vuex'
import { randomString } from '@/utils/stringUtils.js'

export default {
  name: 'ProfileChangeName',
  components: {},

  emits: ['hiddenModal', 'afterSubmit'],

  data() {
    return {
      modal: null,
      formData: {},
      formErrors: {},
    }
  },
  computed: {
    modalId: function () {
      return 'form-modal-' + this.randomString()
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.formData = {}
        this.formErrors = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    randomString,
    ...mapActions({
      patchData: 'auth/patchData',
    }),
    showModal() {
      this.modal.show()
    },
    async formSubmit() {
      try {
        await this.patchData(this.formData)
        this.$store.dispatch('alert/setSuccessMessage', {
          message: '保存しました。',
        })
        await this.$emit('afterSubmit')
        this.modal.hide()
      } catch (e) {
        if (e.response.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            this.setErrorMessage(key, e.response.data[key])
          })
        }
      }
    },
    setErrorMessage(key, errorData) {
      if (Array.isArray(errorData)) {
        this.formErrors[key] = errorData.join('\n')
      } else {
        this.formErrors[key] = errorData
      }
    },
  },
}
</script>
