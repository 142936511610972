<template>
  <nav class="navbar navbar-expand navbar-dark bg-dark border-bottom">
    <div class="container-fluid">
      <span class="navbar-brand">
        <slot name="formTitle"></slot>
      </span>
      <div class="d-flex">
        <button class="btn btn-primary me-2" @click.prevent="$emit('submit')">
          保存
        </button>
        <button class="btn btn-secondary" @click.prevent="$emit('cancel')">
          キャンセル
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavbarForm',
  emits: ['submit', 'cancel'],
}
</script>
