<template>
  <div v-for="(file, index) in files" :key="file.id" class="col">
    <div class="position-relative">
      <div class="ratio ratio-16x9 bg-white border">
        <img
          @click="showLightBox(index)"
          :src="displayImage(file)"
          :class="thumbnail_class"
        />
      </div>
      <div class="position-absolute top-0 start-0 w-100">
        <slot
          name="thumbnail-operation-icons"
          :file="file"
          :index="index"
        ></slot>
      </div>
    </div>
  </div>

  <vue-easy-lightbox
    :visible="visible"
    :index="index"
    :imgs="imgs"
    teleport="body"
    @hide="hideLightBox"
  ></vue-easy-lightbox>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import { isVideo } from '@/utils/fileTypeUtils.js'

export default {
  name: 'AppImageGallery',
  components: {
    VueEasyLightbox,
  },

  props: {
    files: {
      type: [Array, null],
      required: true,
    },
    show_lightbox: {
      type: Boolean,
      require: false,
      default: true,
    },
    thumbnail_class: {
      type: String,
      require: false,
      default: 'object-fit-cover',
    },
  },

  data() {
    return {
      visible: false,
      index: 0,
    }
  },
  computed: {
    imgs() {
      return this.files.map((file) => {
        if (!isVideo(file) && file?.file_path && file?.file_type) {
          return {
            title: file.caption,
            src: file.file_path,
          }
        }
      })
    },
  },

  methods: {
    isVideo,
    displayImage(file) {
      if (file?.file_path && file?.file_type) {
        return file.file_path
      }
      return require('@/assets/no_image.svg')
    },
    showLightBox(index) {
      if (this.show_lightbox) {
        this.index = index
        this.visible = true
      }
    },
    hideLightBox() {
      this.visible = false
    },
  },
}
</script>
