<template>
  <Navbar>
    <template v-slot:navbarTitle>受注管理</template>
  </Navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="clearfix mb-3">
      <div class="float-end d-print-none">
        <router-link
          :to="{
            name: 'ManageOrderSalesList',
          }"
          class="btn btn-outline-dark"
        >
          <span class="d-block d-sm-none"
            ><i class="bi bi-arrow-left"></i
          ></span>
          <span class="d-none d-sm-block">一覧に戻る</span>
        </router-link>
      </div>
      <h5><i class="bi bi-file-earmark-text me-1"></i>売上詳細</h5>
    </div>

    <div v-if="!isLoading">
      <h5>
        受注情報
        <a
          class="fs-6"
          data-bs-toggle="collapse"
          href="#resultData"
          role="button"
          aria-expanded="true"
          aria-controls="resultData"
          ><i class="bi bi-arrows-collapse"></i>
        </a>
      </h5>

      <div class="collapse show" id="resultData">
        <div class="row g-1 g-lg-2">
          <div class="col-12 col-md-6">
            <table class="table table-bordered table-row bg-white mb-3">
              <tbody>
                <tr>
                  <th class="col-4 bg-light">受注番号</th>
                  <td class="col-8">{{ order.number }}</td>
                </tr>
                <tr>
                  <th class="bg-light">受注状態</th>
                  <td>{{ order.latest_status }}</td>
                </tr>
                <tr>
                  <th class="bg-light">顧客コード</th>
                  <td>{{ order.client_code }}</td>
                </tr>
                <tr>
                  <th class="bg-light">顧客名</th>
                  <td>{{ order.client_name }}</td>
                </tr>
                <tr>
                  <th class="bg-light">合計金額</th>
                  <td>{{ order.total_amount }}</td>
                </tr>
                <tr>
                  <th class="bg-light">備考</th>
                  <td>{{ order.memo }}</td>
                </tr>
                <tr>
                  <th class="bg-light">受注登録者</th>
                  <td>{{ order.ordered_by }}</td>
                </tr>
                <tr>
                  <th class="bg-light">受注日時</th>
                  <td>{{ order.ordered_at }}</td>
                </tr>
                <tr>
                  <th class="bg-light">作成日時</th>
                  <td>{{ order.created_at }}</td>
                </tr>
                <tr>
                  <th class="bg-light">更新日時</th>
                  <td>{{ order.updated_at }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 col-md-6">
            <div class="card">
              <div class="card-header">受注状態変更履歴</div>
              <div class="card-body p-0">
                <table class="table table-sm bg-white mb-0">
                  <tbody>
                    <tr
                      v-for="history in order.status_histories"
                      :key="history.id"
                    >
                      <th class="col-3">{{ history.status }}</th>
                      <td class="col-4">{{ history.changed_by }}</td>
                      <td class="col-5 text-end">{{ history.changed_at }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <!-- items -->
      <h5>受注品目</h5>
      <div v-if="order.order_items && order.order_items.length > 0">
        <div class="table-responsive">
          <table class="table table-bordered bg-white">
            <thead>
              <tr>
                <th scope="col" class="col-sm-1">品目コード</th>
                <th scope="col" class="col-sm-1">型式</th>
                <th scope="col" class="col-sm-4">品目名</th>
                <th scope="col" class="col-sm-1">単価</th>
                <th scope="col" class="col-sm-1">数量</th>
                <th scope="col" class="col-sm-1">小計</th>
                <th scope="col" class="col-sm-3">メモ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in order.order_items" :key="item.id">
                <td class="text-center">
                  {{ item.item?.code }}
                </td>
                <td class="text-center">{{ item.item?.item_model }}</td>
                <td>{{ item.item?.name }}</td>
                <td class="text-end">{{ item.unit_price_at_order }}</td>
                <td class="text-end">{{ item.quantity }}</td>
                <td class="text-end">{{ item.subtotal }}</td>
                <td>{{ item.memo }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p v-else class="mt-3">該当するデータはありません。</p>
    </div>
  </main>
</template>

<script>
import store from '../store'
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'ManageOrderDetail',
  components: {
    Navbar,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    order() {
      return store.getters['manage_order/detail']
    },
  },

  async created() {
    try {
      await this.getData(this.id)
    } catch (error) {
      if (error.status) {
        window.setTimeout(() => {
          this.$router.push({
            name: 'ManageOrderSalesList',
          })
        }, this.$store.getters['alert/timeout'])
      }
    } finally {
      this.isLoading = false
    }
  },

  methods: {
    getData(id) {
      return store.dispatch('manage_order/fetchData', id)
    },
  },
}
</script>
