import { createStore } from 'vuex'
import manage_purchase_defective_item from './manage_purchase_defective_item'
import manage_purchase_defective_item_cause from './manage_purchase_defective_item_cause'

const store = createStore({
  actions: {
    clearAllStores({ dispatch }) {
      dispatch('manage_purchase_defective_item/clearAll', null, { root: true })
      dispatch('manage_purchase_defective_item_cause/clearAll', null, { root: true })
    },
    clearDataStores({ dispatch }) {
      dispatch('manage_purchase_defective_item/clearAll', null, { root: true })
      dispatch('manage_purchase_defective_item_cause/clearAll', null, { root: true })
    },
  },
  modules: {
    manage_purchase_defective_item,
    manage_purchase_defective_item_cause,
  },
})

export default store
