import ManageInventoryHome from './views/Home.vue'
import ManageStockList from './views/StockList.vue'
import ManageStockDetail from './views/StockDetail.vue'
import ManagePickingList from './views/PickingList.vue'
import ManagePickingListCreate from './views/PickingListCreate.vue'
import ManagePickingListImport from './views/PickingListImport.vue'
import ManageStockLocationList from './views/StockLocationList.vue'
import ManageStockTransactionList from './views/StockTransactionList.vue'
import ManageStockImport from './views/StockImport.vue'

export default {
  routes: [
    {
      path: '/manage/inventory',
      component: ManageInventoryHome,
      name: 'ManageInventoryHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'stocks',
          component: ManageStockList,
          name: 'ManageStockList',
        },
        {
          path: 'stocks/import',
          component: ManageStockImport,
          name: 'ManageStockImport',
        },
        {
          path: 'stocks/:id([0-9]+)',
          component: ManageStockDetail,
          name: 'ManageStockDetail',
          props: (route) => ({ id: Number(route.params.id) }),
        },
        {
          path: 'stock-transactions',
          component: ManageStockTransactionList,
          name: 'ManageStockTransactionList',
        },
        {
          path: 'picking-list',
          component: ManagePickingList,
          name: 'ManagePickingList',
        },
        {
          path: 'picking-list/create',
          component: ManagePickingListCreate,
          name: 'ManagePickingListCreate',
        },
        {
          path: 'picking-list/import',
          component: ManagePickingListImport,
          name: 'ManagePickingListImport',
        },
        {
          path: 'stock-locations',
          component: ManageStockLocationList,
          name: 'ManageStockLocationList',
        },
      ],
    },
  ],
}
