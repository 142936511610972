<template>
  <Navbar>
    <template v-slot:navbarTitle>在庫管理</template>
  </Navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="d-flex gap-2 mb-3">
      <h5 class="m-0 p-0"><i class="bi bi-box me-1"></i>在庫品目一覧</h5>
      <div class="ms-auto">
        <router-link
          :to="{
            name: 'ManageStockImport',
          }"
          class="btn btn-dark me-2"
          ><span class="d-block d-sm-none"
            ><i class="bi bi-cloud-upload"></i
          ></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-cloud-upload me-1"></i>CSVから一括で登録する</span
          ></router-link
        >
        <button
          type="button"
          v-on:click.prevent="showStockForm()"
          class="btn btn-primary"
        >
          <span class="d-block d-sm-none"><i class="bi bi-plus-lg"></i></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-plus-lg me-1"></i>在庫品目を登録する</span
          >
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-start gap-2 mb-2">
      <div class="me-auto col-6 col-lg-4 col-xl-3">
        <search-form
          :query="query"
          query-key="code"
          placeholder="品目コードで検索"
          :use-search-modal="true"
          @update-search-query="onUpdateSearchQuery"
          @open-search-modal="onOpenSearchModal"
        ></search-form>
      </div>
      <Pagination :prev="prev" :next="next" @move-page="movePage" />
    </div>

    <!-- stocks -->
    <div v-if="stocks && stocks.length > 0">
      <data-table :headers="dataHeaders" :items="stocks">
        <template #code="{ item }">
          <router-link
            :to="{
              name: 'ManageStockDetail',
              params: { id: item.id },
            }"
            >{{ item.code }}</router-link
          >
        </template>
        <template #classifications="{ item }">
          {{
            item.classifications
              ? item.classifications.map((obj) => obj.name).join(', ')
              : ''
          }}
        </template>
        <template #quantity="{ item }">
          <span :class="checkQuantity(item)">{{ item.quantity }}</span>
        </template>
      </data-table>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>

    <!-- Modal -->
    <search-modal
      :query="query"
      :items="searchItems"
      @update-search-query="onUpdateSearchQuery"
      ref="searchModal"
    ></search-modal>

    <stock-form ref="stockForm">
      <template v-slot:modalTitle>在庫品目登録</template>
    </stock-form>
  </main>
</template>

<script>
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import DataTable from '@/components/common/DataTable.vue'
import SearchForm from '@/components/common/SearchForm.vue'
import SearchModal from '@/components/common/SearchModal.vue'
import StockForm from '../components/StockForm.vue'

export default {
  name: 'ManageStockList',
  components: {
    Navbar,
    Pagination,
    DataTable,
    SearchForm,
    SearchModal,
    StockForm,
  },

  data() {
    return {
      query: {},
      dataHeaders: [
        {
          key: 'code',
          label: '品目コード',
          class: 'col-2',
          format: 'code',
        },
        {
          key: 'item_model',
          label: '型式',
          class: 'col-2',
          format: 'code',
        },
        {
          key: 'name',
          label: '品目名',
          class: 'col-3',
          format: 'string',
        },
        {
          key: 'classifications',
          label: '品目分類',
          class: 'col-2',
          format: 'string',
        },
        {
          key: 'quantity',
          label: '在庫数量',
          class: 'col-1',
          format: 'number',
        },
        {
          key: 'threshold',
          label: '在庫閾値',
          class: 'col-1',
          format: 'number',
        },
        {
          key: 'updated_at',
          label: '更新日時',
          class: 'col-1',
          format: 'datetime',
        },
      ],
    }
  },
  computed: {
    stocks() {
      return store.getters['manage_stock/results']
    },
    prev() {
      return store.getters['manage_stock/prev']
    },
    next() {
      return store.getters['manage_stock/next']
    },
    saveQuery() {
      return store.getters['manage_stock/query']
    },
    searchItems() {
      return [
        {
          id: 'code',
          label: '品目コード（前方一致）',
          type: 'text',
        },
        {
          id: 'item_model',
          label: '型式（前方一致）',
          type: 'text',
        },
        {
          id: 'name',
          label: '品目名（部分一致）',
          type: 'text',
        },
      ]
    },
  },

  async created() {
    this.query = this.saveQuery
    await this.getData()
  },
  beforeRouteLeave(to, from, next) {
    // 詳細画面以外のページに移動する場合にデータをクリアする
    if (to.name !== 'ManageStockDetail') {
      this.resetQuery()
    }
    next()
  },

  methods: {
    getData() {
      return store.dispatch('manage_stock/fetchAll')
    },
    setQuery(query = {}) {
      store.commit('manage_stock/setQuery', query)
    },
    clearQuery() {
      store.commit('manage_stock/clearQuery')
    },
    clearData() {
      store.commit('manage_stock/clearData')
    },
    movePage(page) {
      Object.assign(this.query, { page: page })
      this.getQueryResult()
    },
    getQueryResult() {
      this.setQuery(this.query)
      this.getData()
    },
    resetQuery() {
      this.clearQuery()
      this.clearData()
    },
    onOpenSearchModal() {
      this.$refs.searchModal.show()
    },
    onUpdateSearchQuery(query = {}) {
      this.query = query
      this.getQueryResult()
    },
    showStockForm() {
      this.$refs.stockForm.show()
    },
    checkQuantity(data) {
      switch (true) {
        case parseFloat(data.quantity) < parseFloat(data.threshold):
          return 'text-danger fw-semibold'
        case parseFloat(data.quantity) == parseFloat(data.threshold):
          return 'text-warning fw-semibold'
      }
    },
  },
}
</script>
