<template>
  <nav class="navbar navbar-expand navbar-dark bg-dark">
    <div class="container-fluid">
      <h5 class="navbar-brand mb-0">
        <i class="bi bi-cloud-upload me-1"></i>検査指示データアップロード
      </h5>
      <div class="d-flex">
        <div v-if="hasPlan">
          <button
            class="btn btn-primary me-2"
            v-on:click.prevent="formSubmit()"
          >
            アップロード
          </button>
          <button
            class="btn btn-dark border-light me-2"
            v-on:click.prevent="formReset()"
          >
            リセット
          </button>
        </div>
        <router-link
          :to="{
            name: 'ManageInspectionPlan',
          }"
          class="btn btn-secondary"
          >キャンセル</router-link
        >
      </div>
    </div>
  </nav>
  <main id="main" class="container-fluid pt-3">
    <div class="row">
      <div class="col-lg-3">
        <vue-csv-import v-model="plans" :fields="fields" ref="csvImport">
          <vue-csv-input
            :headers="headers"
            :parseConfig="parseConfig"
            :fileMimeTypes="['text/csv']"
            accept="text/csv"
            class="form-control"
            @change="importDataReset"
          ></vue-csv-input>
          <vue-csv-errors></vue-csv-errors>
          <div class="card card-body mt-3">
            <vue-csv-map
              :noThead="true"
              :selectAttributes="{ class: 'form-select' }"
            ></vue-csv-map>
          </div>
        </vue-csv-import>
        <div v-if="hasPlan">
          <div class="text-end my-2">件数：{{ plans.length }}</div>
        </div>
      </div>

      <div class="col-lg-9">
        <!-- plans -->
        <div v-if="hasPlan">
          <div class="table-responsive">
            <table class="table table-bordered bg-white">
              <thead>
                <tr>
                  <th scope="col" class="col-sm-4">得意先名</th>
                  <th scope="col" class="col-sm-1">受注番号</th>
                  <th scope="col" class="col-sm-1">指示番号</th>
                  <th scope="col" class="col-sm-1">納期</th>
                  <th scope="col" class="col-sm-2">型式</th>
                  <th scope="col" class="col-sm-1">機種コード</th>
                  <th scope="col" class="col-sm-1">客先製品コード</th>
                  <th scope="col" class="col-sm-1">指示数量</th>
                  <th scope="col" class="col-sm-1">指示残数</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="plan in plans" :key="plan.id">
                  <td>{{ plan.client_name }}</td>
                  <td class="text-center">
                    {{ plan.order_number }}
                  </td>
                  <td class="text-center">{{ plan.inspection_plan_number }}</td>
                  <td class="text-center">{{ plan.order_deadline }}</td>
                  <td>{{ plan.product_model }}</td>
                  <td class="text-center">{{ plan.product_code }}</td>
                  <td>{{ plan.client_product_code }}</td>
                  <td class="text-end">{{ plan.number_unit }}</td>
                  <td class="text-end">{{ plan.number_remaining }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p v-else>CSVファイルを選択してください。</p>
      </div>
    </div>
  </main>
</template>

<script>
import store from '../store'
import {
  VueCsvSubmit,
  VueCsvMap,
  VueCsvInput,
  VueCsvErrors,
  VueCsvImport,
} from 'vue-csv-import'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, integer } from '@vuelidate/validators'

export default {
  name: 'ManageInspectionPlanImport',
  components: {
    VueCsvSubmit,
    VueCsvMap,
    VueCsvInput,
    VueCsvErrors,
    VueCsvImport,
  },

  setup: () => ({
    v$: useVuelidate({ $stopPropagation: true, $scope: false }),
  }),

  data() {
    return {
      fields: {
        client_name: { required: true, label: '得意先名' },
        order_number: { required: true, label: '受注番号' },
        inspection_plan_number: { required: true, label: '製作指示番号' },
        order_deadline: { required: true, label: '納期' },
        product_model: { required: true, label: '型式' },
        product_code: { required: true, label: '機種コード' },
        client_product_code: { required: true, label: '客先製品コード' },
        number_unit: { required: true, label: '製作指示数量' },
        number_remaining: { required: true, label: '製作指示残数' },
      },
      headers: false,
      parseConfig: { encoding: 'sjis' },
      plans: [],
      hasPlan: false,
    }
  },
  validations() {
    return {
      plans: {
        $each: helpers.forEach({
          order_deadline: { required },
          number_unit: { required, integer },
          number_remaining: { required, integer },
        }),
      },
    }
  },

  watch: {
    plans: function () {
      this.hasPlan = this.plans && this.plans.length > 0
    },
  },

  methods: {
    formReset() {
      this.$router.go({ path: this.$router.currentRoute.path, force: true })
    },
    async formSubmit() {
      const result = await this.v$.$validate()
      if (!result) {
        this.$store.dispatch('alert/setErrorMessage', {
          message: 'データに不備があります。CSVの内容を確認してください。',
        })
        return
      }
      await store
        .dispatch('inspection_plan/postImportData', this.plans)
        .then(() => {
          this.$router.push({
            name: 'ManageInspectionPlan',
          })
        })
    },
    importDataReset() {
      this.plans = []
      this.$refs.csvImport.VueCsvImportData.map = {}
    },
  },
}
</script>
