<template>
  <div class="card mb-3">
    <h5 class="card-header bg-secondary">在庫管理</h5>
    <div class="card-body">
      <div class="row row-cols-1 row-cols-md-2 g-1">
        <div class="col">
          <router-link
            :to="{
              name: 'ManageStockList',
            }"
            class="btn btn-lg btn-secondary w-100 py-3"
            ><i class="bi bi-box d-block"></i>在庫品目</router-link
          >
        </div>
        <div class="col">
          <router-link
            :to="{
              name: 'ManagePickingList',
            }"
            class="btn btn-lg btn-secondary w-100 py-3"
            ><i class="bi bi-card-checklist d-block"></i>ピッキングリスト</router-link
          >
        </div>
        <div class="col">
          <router-link
            :to="{
              name: 'ManageStockTransactionList',
            }"
            class="btn btn-lg btn-outline-secondary w-100 py-3"
            ><i class="bi bi-graph-up d-block"></i>変動履歴</router-link
          >
        </div>
        <div class="col">
          <router-link
            :to="{
              name: 'ManageStockLocationList',
            }"
            class="btn btn-lg btn-outline-secondary w-100 py-3"
            ><i class="bi bi-boxes d-block"></i>保管場所</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuInventory',
}
</script>
