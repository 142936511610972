<template>
  <CodeReader
    v-if="showCodeCamera"
    ref="codeReader"
    @decode="handleQRCodeData"
    @cancel="cancelCodeCamera"
  />
  <main
    v-else
    class="d-flex align-items-center vh-100 bg-light animate__animated animate__fadeIn"
  >
    <div class="card-wrapper">
      <div class="mb-3 text-center">
        <img src="@/assets/logo.svg" class="img-fluid" />
      </div>
      <div class="card">
        <div class="card-body">
          <form>
            <div class="mb-4">
              <label class="text-muted mb-1" for="username">ユーザー名</label>
              <div class="input-group mb-3">
                <input
                  v-model="form_data.username"
                  type="text"
                  class="form-control"
                  id="username"
                />
                <button
                  type="button"
                  class="btn btn-outline-secondary border"
                  v-on:click.prevent="startCodeCamera('username')"
                  tabindex="-1"
                >
                  <i class="bi bi-qr-code"></i>
                </button>
              </div>
            </div>
            <div class="mb-4">
              <label class="text-muted mb-1" for="password">パスワード</label>
              <div class="input-group mb-3">
                <input
                  v-model="form_data.password"
                  type="password"
                  class="form-control"
                  id="password"
                />
                <button
                  type="button"
                  class="btn btn-outline-secondary border"
                  v-on:click.prevent="startCodeCamera('password')"
                  tabindex="-1"
                >
                  <i class="bi bi-qr-code"></i>
                </button>
              </div>
            </div>

            <button
              class="w-100 btn btn-primary"
              v-on:click.prevent="submitLogin()"
            >
              ログイン
            </button>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CodeReader from '@/components/AppCodeReader.vue'

export default {
  name: 'AppLogin',
  components: {
    CodeReader,
  },

  data() {
    return {
      showCodeCamera: false,
      insertDecodeTarget: null,
      form_data: {},
    }
  },
  computed: {
    ...mapGetters({
      hasPerm: 'auth/hasPerm',
    }),
  },

  created() {
    this.boot()
  },

  methods: {
    ...mapActions({
      login: 'auth/login',
      boot: 'auth/boot',
    }),
    async submitLogin() {
      try {
        await this.login(this.form_data)
        if (this.hasPerm('manager')) {
          this.$router.push({
            name: 'AppHomeManage',
          })
        } else {
          this.$router.push({
            name: 'AppHomeOperate',
          })
        }
      } catch (e) {
        if (e.response?.status == 400) {
          this.$store.dispatch('alert/setErrorMessage', {
            message: 'ユーザー名またはパスワードが間違っています。',
          })
        } else {
          this.$store.dispatch('alert/setErrorMessage', {
            message: 'ログインに失敗しました。',
          })
        }
      }
    },
    startCodeCamera(targetKey) {
      this.showCodeCamera = true
      this.insertDecodeTarget = targetKey
    },
    cancelCodeCamera() {
      this.showCodeCamera = false
      this.insertDecodeTarget = null
    },
    handleQRCodeData(result) {
      if (this.insertDecodeTarget) {
        this.form_data[this.insertDecodeTarget] = result
      }
      this.showCodeCamera = false
      this.insertDecodeTarget = null
    },
  },
}
</script>

<style scoped>
.card-wrapper {
  margin: 0 auto;
  max-width: 90%;
  width: 350px;
}
</style>
