<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row g-3">
            <div class="col-12">
              <label :for="modalId + '_form_code'" class="form-label"
                >管理コード<span
                  v-if="formData.id"
                  class="badge text-bg-secondary ms-2 px-1"
                  >変更不可</span
                ></label
              >
              <input
                v-model="formData.code"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': formErrors?.code,
                  'bg-secondary': formData.id,
                }"
                :id="modalId + '_form_code'"
                :readonly="formData.id"
              />
              <div v-if="formErrors?.code" class="invalid-feedback">
                {{ formErrors.code }}
              </div>
              <div v-if="!formData.id" class="form-text text-muted small">
                未入力の場合は年月日時分秒で採番されます（例：20231007112621）
              </div>
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_deadline'" class="form-label"
                >納期</label
              >
              <input
                v-model="formData.deadline"
                type="date"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.deadline }"
                :id="modalId + '_form_deadline'"
              />
              <div v-if="formErrors?.deadline" class="invalid-feedback">
                {{ formErrors.deadline }}
              </div>
            </div>
            <div v-if="!formData?.id" class="col-12">
              <label :for="modalId + '_form_stock'" class="form-label"
                >在庫品目<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <FormSearchSelect
                v-model="formData.stock"
                :options="itemList"
                :error="formErrors.stock_id"
                :id="modalId + '_form_stock'"
                placeholder="品目コード、品目名で検索"
                @getOptions="getStockList"
              />
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_stock_location'" class="form-label"
                >保管場所<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <FormSearchSelect
                v-model="formData.stock_location"
                :options="locationList"
                :error="formErrors.stock_location_id"
                :id="modalId + '_form_stock_location'"
                placeholder="保管場所名で検索"
                @getOptions="getLocationList"
              />
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_quantity'" class="form-label"
                >指示数量<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <FormNumericInput
                v-model="formData.quantity"
                :error="formErrors.quantity"
                :id="modalId + '_form_quantity'"
              />
            </div>
            <div class="col-12">
              <label for="form_memo" class="form-label">指示補足</label>
              <input
                v-model="formData.memo"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.memo }"
                id="form_memo"
                min="0"
                step="0.01"
              />
              <div v-if="formErrors?.memo" class="invalid-feedback">
                {{ formErrors.memo }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal-footer d-flex flex-row-reverse justify-content-between"
        >
          <div class="footer-buttons">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              閉じる
            </button>
            <button
              type="button"
              class="btn btn-dark ms-1"
              v-on:click.prevent="formSubmit()"
            >
              <slot name="submitButton">保存</slot>
            </button>
          </div>
          <button
            v-if="formData?.id"
            type="button"
            class="btn btn-link text-danger"
            v-on:click.prevent="removeData()"
          >
            <slot name="cancelButton">削除</slot>
          </button>
        </div>
      </div>
    </div>
  </div>

  <Confirm ref="confirmDataDelete" @confirm-true="confirmDataDelete" />
</template>

<script>
import store from '../store'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { randomString } from '@/utils/stringUtils.js'
import Confirm from '@/components/TheConfirm.vue'
import FormSearchSelect from '@/components/common/FormSearchSelect.vue'
import FormNumericInput from '@/components/common/FormNumericInput.vue'

export default {
  name: 'PickingListForm',
  components: {
    Confirm,
    FormSearchSelect,
    FormNumericInput,
  },

  emits: ['hiddenModal', 'afterSubmit', 'afterDelete'],

  data() {
    return {
      modal: null,
      formData: {},
      formErrors: {},
    }
  },
  computed: {
    itemList() {
      return store.getters['manage_stock/list']
    },
    locationList() {
      return store.getters['manage_stock_location/list']
    },
    modalId: function () {
      return 'form-modal-' + this.randomString()
    },
    pickingData() {
      return store.getters['manage_picking_list/detail']
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.formErrors = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    randomString,
    getStockList(query = {}) {
      return store.dispatch('manage_stock/fetchList', query)
    },
    getLocationList(query = {}) {
      return store.dispatch('manage_stock_location/fetchList', query)
    },
    getData(id) {
      return store.dispatch('manage_picking_list/fetchData', id)
    },
    postOrPatch(formData) {
      return store.dispatch('manage_picking_list/postOrPatch', formData)
    },
    deleteData(id) {
      return store.dispatch('manage_picking_list/deleteData', id)
    },
    async show(formData = {}) {
      if (formData.id) {
        await this.getData(formData.id)
        this.formData = Object.assign({}, this.pickingData)
      } else {
        this.formData = {}
      }
      this.modal.show()
    },
    async formSubmit() {
      try {
        this.formErrors = {}
        if (!this.formData.stock) {
          this.formErrors.stock_id = '品目を選択してください。'
        }
        if (!this.formData.stock_location) {
          this.formErrors.stock_location_id = '保管場所を選択してください。'
        }
        if (!this.formData.quantity) {
          this.formErrors.quantity = '数量を入力してください。'
        }
        if (Object.keys(this.formErrors).length > 0) {
          return
        }

        if (this.formData.id) {
          await this.postOrPatch(this.formData)
          this.$store.dispatch('alert/setSuccessMessage', {
            message: '保存しました。',
          })
        }
        await this.$emit('afterSubmit', this.formData)
        this.modal.hide()
      } catch (e) {
        if (e.response.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            this.setErrorMessage(key, e.response.data[key])
          })
        }
      }
    },
    removeData() {
      this.$refs.confirmDataDelete.showConfirm(
        'データを削除',
        'このデータを削除しますか？'
      )
    },
    async confirmDataDelete() {
      try {
        await this.deleteData(this.formData.id)
        await this.$emit('afterDelete')
        await this.modal.hide()
      } catch (e) {
        let message = '削除できませんでした。'
        this.$store.dispatch('alert/setErrorMessage', {
          message: message,
        })
      }
    },
  },
}
</script>
