<template>
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modalTitle">ユーザー</slot>
          </h5>
        </div>
        <div class="modal-body">
          <div class="row g-3">
            <div class="col-12">
              <label :for="modalId + '_form_username'" class="form-label"
                >ユーザー名<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <input
                v-model="formData.username"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.username }"
                :id="modalId + '_form_username'"
              />
              <div v-if="formErrors?.username" class="invalid-feedback">
                {{ formErrors.username }}
              </div>
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_password'" class="form-label"
                >パスワード<span
                  v-if="formData.id"
                  class="badge text-bg-secondary ms-2 px-1"
                  >変更する場合のみ入力</span
                ><span v-else class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <div class="input-group">
                <input
                  v-model="formData.password"
                  :type="passwordFieldType"
                  class="form-control"
                  :class="{ 'is-invalid': formErrors?.password }"
                  :id="modalId + '_form_password'"
                />
                <button
                  class="btn btn-outline-secondary border"
                  type="button"
                  tabindex="-1"
                  @click="togglePasswordVisibility"
                >
                  <i
                    :class="
                      passwordFieldType === 'password'
                        ? 'bi bi-eye-fill'
                        : 'bi bi-eye-slash-fill'
                    "
                  ></i>
                </button>
                <div v-if="formErrors?.password" class="invalid-feedback">
                  {{ formErrors.password }}
                </div>
              </div>
              <div
                v-if="!formErrors?.new_password"
                class="form-text text-muted small"
              >
                パスワードは8文字以上かつ 0-9, a-z, 記号 #$%& を含めてください
              </div>
            </div>
            <div class="col-6">
              <label :for="modalId + '_form_last_name'" class="form-label"
                >姓</label
              >
              <input
                v-model="formData.last_name"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.last_name }"
                :id="modalId + '_form_last_name'"
              />
              <div v-if="formErrors?.last_name" class="invalid-feedback">
                {{ formErrors.last_name }}
              </div>
            </div>
            <div class="col-6">
              <label :for="modalId + '_form_first_name'" class="form-label"
                >名</label
              >
              <input
                v-model="formData.first_name"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.first_name }"
                :id="modalId + '_form_first_name'"
              />
              <div v-if="formErrors?.first_name" class="invalid-feedback">
                {{ formErrors.first_name }}
              </div>
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_group_id'" class="form-label"
                >権限グループ<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <select
                v-model="formData.group_id"
                class="form-select"
                :class="{ 'is-invalid': formErrors?.group_id }"
                :id="modalId + '_form_group_id'"
              >
                <option :value="undefined">---</option>
                <option v-for="group in groups" :key="group" :value="group.id">
                  {{ group.name }}
                </option>
              </select>
              <div v-if="formErrors?.group_id" class="invalid-feedback">
                {{ formErrors.group_id }}
              </div>
            </div>
            <div v-if="formData.id" class="col-12">
              <div class="form-check">
                <input
                  v-model="formData.is_active"
                  class="form-check-input"
                  type="checkbox"
                  :id="modalId + '_form_is_active'"
                />
                <label
                  :for="modalId + '_form_is_active'"
                  class="form-check-label"
                >
                  有効
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div v-if="formErrors?.form" class="text-danger">
            {{ formErrors.form }}
          </div>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            閉じる
          </button>
          <button
            type="button"
            class="btn btn-dark"
            v-on:click.prevent="formSubmit()"
          >
            <slot name="submitButton">保存</slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { mapGetters, mapActions } from 'vuex'
import { randomString } from '@/utils/stringUtils.js'

export default {
  name: 'UserForm',
  components: {},

  emits: ['hiddenModal', 'afterSubmit'],

  data() {
    return {
      modal: null,
      formData: {},
      formErrors: {},
      passwordFieldType: 'password',
    }
  },
  computed: {
    ...mapGetters({
      groups: 'master/groups',
    }),
    modalId: function () {
      return 'form-modal-' + this.randomString()
    },
  },

  async created() {
    await this.fetchGroupList()
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.formData = {}
        this.formErrors = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    randomString,
    ...mapActions({
      postOrPatch: 'user/postOrPatch',
      fetchGroupList: 'master/fetchGroupList',
    }),
    showModal(formData = {}) {
      this.formData = Object.assign({}, formData)
      if (formData.groups) {
        this.formData.group_id = this.groups.find(
          (g) => g.name === formData.groups[0]
        ).id
      }

      this.modal.show()
    },
    async formSubmit() {
      try {
        this.formErrors = {}
        if (!this.formData.username) {
          this.formErrors.username = 'ユーザー名を入力してください'
        }
        if (!this.formData.id && !this.formData.password) {
          this.formErrors.password = 'パスワードを入力してください'
        }
        if (!this.formData.group_id) {
          this.formErrors.group_id = '権限グループを選択してください'
        }

        if (Object.keys(this.formErrors).length > 0) {
          return
        }

        await this.postOrPatch(this.formData)
        this.$store.dispatch('alert/setSuccessMessage', {
          message: '保存しました。',
        })
        await this.$emit('afterSubmit')
        this.modal.hide()
      } catch (e) {
        if (e.response?.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            this.setErrorMessage(key, e.response.data[key])
          })
        } else {
          this.setErrorMessage('form', '保存できませんでした')
        }
      }
    },
    setErrorMessage(key, errorData) {
      if (Array.isArray(errorData)) {
        this.formErrors[key] = errorData.join('\n')
      } else {
        this.formErrors[key] = errorData
      }
    },
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    },
  },
}
</script>
