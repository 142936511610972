import { createStore } from 'vuex'
import operate_order from './order'

const store = createStore({
  actions: {
    clearAllStores({ dispatch }) {
      dispatch('operate_order/clearAll', null, { root: true })
    },
    clearDataStores({ dispatch }) {
      dispatch('operate_order/clearAll', null, { root: true })
    },
  },
  modules: {
    operate_order,
  },
})

export default store
