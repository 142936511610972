import OperateOrderHome from './views/Home.vue'
import OperateOrder from './views/OperateOrder.vue'
import OperateOrderList from './views/OperateOrderList.vue'
import OperateOrderDetail from './views/OperateOrderDetail.vue'
import OperateOrderCreate from './views/OperateOrderCreate.vue'

export default {
  routes: [
    {
      path: '/operate/order',
      component: OperateOrderHome,
      name: 'OperateOrderHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          component: OperateOrder,
          name: 'OperateOrder',
        },
        {
          path: 'orders',
          component: OperateOrderList,
          name: 'OperateOrderList',
        },
        {
          path: 'orders/:id([0-9]+)',
          component: OperateOrderDetail,
          name: 'OperateOrderDetail',
          props: (route) => ({ id: Number(route.params.id) }),
        },
        {
          path: 'orders/create',
          component: OperateOrderCreate,
          name: 'OperateOrderCreate',
        },
      ],
    },
  ],
}
