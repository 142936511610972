import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
  detail: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  results: (state) => state.data.results,
  count: (state) => state.data.count,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  current_page: (state) => state.data.current_page,
  total_pages: (state) => state.data.total_pages,
  detail: (state) => state.detail,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = []),
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearDetail')
      resolve()
    })
  },
  async fetchAll({ commit }, queryParams) {
    const response = await api.get('manage/picking-list/', {
      params: queryParams,
    })
    commit('setData', response.data)
  },
  async fetchData({ state, commit }, id) {
    if (Object.keys(state.detail).length > 0 && state.detail.id == id) {
      return
    }

    const response = await api.get('manage/picking-list/' + id + '/')
    commit('setDetail', response.data)
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {object}
   */
  async postData({ state }, formData) {
    try {
      const response = await api.post('manage/picking-list/', formData)
      state.data.results.push(response.data)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {object}
   */
  async patchData({ state, commit }, formData) {
    try {
      const response = await api.patch(
        'manage/picking-list/' + formData.id + '/',
        formData
      )
      commit('setDetail', Object.assign(state.detail, response.data))
    } catch (error) {
      throw error
    }
  },
  /**
   * データを作成または更新する
   *
   * @param {object}
   * @return {object}
   */
  async postOrPatch({ dispatch }, formData) {
    if ('stock' in formData && formData['stock']) {
      formData['stock_id'] = formData['stock']['id']
    }
    if ('stock_location' in formData && formData['stock_location']) {
      formData['stock_location_id'] = formData['stock_location']['id']
    }

    // 必要なデータのみにする
    const requiredKeys = [
      'id',
      'code',
      'deadline',
      'stock_id',
      'stock_location_id',
      'quantity',
      'memo',
    ]
    const submitData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) =>
        requiredKeys.includes(key)
      )
    )

    try {
      if ('id' in submitData && submitData['id']) {
        await dispatch('patchData', submitData)
      } else {
        await dispatch('postData', submitData)
      }
    } catch (error) {
      throw error
    }
  },
  /**
   * データを一括作成する
   *
   * @param {array}
   * @return {void}
   */
  async bulkPostData({}, formData) {
    // 必要なデータのみにする
    const requiredKeys = [
      'code',
      'deadline',
      'stock_id',
      'stock_location_id',
      'quantity',
      'memo',
    ]

    const submitData = formData.map((item) => {
      if ('stock' in item && item['stock']) {
        item['stock_id'] = item['stock']['id']
      }
      if ('stock_location' in item && item['stock_location']) {
        item['stock_location_id'] = item['stock_location']['id']
      }

      return Object.fromEntries(
        Object.entries(item).filter(([key, value]) =>
          requiredKeys.includes(key)
        )
      )
    })

    try {
      await api.post('manage/picking-list/bulk-create/', submitData)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {void}
   */
  async deleteData({ dispatch }, id) {
    await api.delete('manage/picking-list/' + id + '/')
    dispatch('clearAll')
  },
  /**
   * CSVファイルをインポートする
   *
   * @param {object}
   * @return {object}
   */
  async importData({}, formData) {
    try {
      await api.post('manage/picking-list/import/', formData)
    } catch (error) {
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
