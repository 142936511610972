<template>
  <div class="row row-cols-1 gy-3">
    <div class="col">
      <textarea
        class="form-control"
        placeholder="入力してください。"
        id="description"
        rows="5"
      ></textarea>
      <button class="btn btn-lg btn-success w-100 py-4 mt-1">
        <i class="bi bi-check-circle-fill me-2"></i> 入力しました
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InspectionInput',
}
</script>
