<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="modal_id"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modalTitle">検査種別を編集</slot>
          </h5>
        </div>
        <div class="modal-body">
          <div class="row g-3">
            <div class="col-12">
              <label for="update_inspection_type_name" class="form-label"
                >検査種別名</label
              >
              <input
                v-model="inspection_type.name"
                type="text"
                class="form-control"
                id="update_inspection_type_name"
                readonly
              />
            </div>
            <div class="col-12">
              <label
                for="update_inspection_result_number_rule"
                class="form-label"
                >番号生成ルール</label
              >
              <select
                v-model="inspection_type.inspection_result_number_rule_id"
                class="form-select"
                :class="{
                  'is-invalid':
                    v$.inspection_type.inspection_result_number_rule_id.$error,
                }"
                id="update_inspection_result_number_rule"
              >
                <option :value="undefined">---</option>
                <option
                  v-for="inspection_result_number_rule in inspection_result_number_rules"
                  :key="inspection_result_number_rule"
                  :value="inspection_result_number_rule.id"
                >
                  {{ inspection_result_number_rule.rule_name }}
                </option>
              </select>
            </div>
            <div class="col-12">
              <div class="form-check">
                <input
                  v-model="inspection_type.is_active"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="is_active"
                />
                <label class="form-check-label" for="is_active"> 有効 </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            閉じる
          </button>
          <button
            type="button"
            class="btn btn-dark"
            v-on:click.prevent="update()"
          >
            <slot name="submitButton">保存</slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { useVuelidate } from '@vuelidate/core'
import { required, integer } from '@vuelidate/validators'

export default {
  name: 'InspectionTypeUpdateForm',
  components: {},

  setup() {
    return { v$: useVuelidate() }
  },

  emits: ['updateType'],

  data() {
    return {
      modal: null,
      inspection_type: {},
    }
  },
  computed: {
    inspection_result_number_rules() {
      return store.getters['inspection_master/inspection_result_number_rules']
    },
    modal_id: function () {
      let chars = 'abcdefghijklmnopqrstuvwxyz0123456789'
      let rand_str = ''
      for (let i = 0; i < 8; i++) {
        rand_str += chars.charAt(Math.floor(Math.random() * chars.length))
      }
      return 'form-modal-' + rand_str
    },
  },
  validations() {
    return {
      inspection_type: {
        inspection_result_number_rule_id: { required, integer },
      },
    }
  },

  async created() {
    await this.fetchInspectionResultNumberRules()
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modal_id))
    document
      .getElementById(this.modal_id)
      .addEventListener('hidden.bs.modal', this.cleanForm)
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    fetchInspectionResultNumberRules() {
      return store.dispatch(
        'inspection_master/fetchInspectionResultNumberRuleList'
      )
    },
    show(data) {
      this.inspection_type = {
        id: data.id,
        name: data.name,
        inspection_result_number_rule_id: data.inspection_result_number_rule_id,
        is_active: data.is_active,
      }
      this.modal.show()
    },
    async update() {
      const result = await this.v$.$validate()
      if (!result) {
        return
      }
      const data = await store.dispatch(
        'inspection_type/patchData',
        this.inspection_type
      )
      this.$emit('updateType', data)
      this.modal.hide()
    },
    cleanForm() {
      this.v$.$reset()
      this.inspection_type = {}
    },
  },
}
</script>
