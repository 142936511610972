<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="formModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div v-if="item" class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title">
            <span v-if="is_edit">検査項目を編集</span>
            <span v-else>検査項目を追加</span>
          </h5>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body row">
              <div class="col-6">
                <div class="mb-3">
                  <label for="title" class="form-label">項目名</label>
                  <input
                    v-model="item.title"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': v$.item.title.$error }"
                    id="title"
                  />
                </div>
                <div class="mb-3">
                  <label for="itemType" class="form-label">検査項目種別</label>
                  <select
                    v-model="item.type"
                    class="form-select"
                    :class="{ 'is-invalid': v$.item.type.$error }"
                    id="itemType"
                  >
                    <option
                      v-for="item_type in item_types"
                      :key="item_type"
                      :value="item_type.value"
                    >
                      {{ item_type.name }}
                    </option>
                  </select>
                </div>
                <div v-if="item.type == 'select'" class="mb-3">
                  <label class="form-label">選択肢（OK）</label>
                  <div class="row">
                    <div
                      v-for="(success_options, index) in item.success_options"
                      :key="index"
                      class="col-6"
                    >
                      <div class="input-group mb-2">
                        <input
                          v-model="item.success_options[index]"
                          type="text"
                          class="form-control"
                        />
                        <span
                          class="input-group-text bg-transparent"
                          @click="removeInput('success', index)"
                          ><i class="bi bi-trash3 link-secondary"></i
                        ></span>
                      </div>
                    </div>
                    <div class="col-6">
                      <span
                        class="form-control-plaintext"
                        @click="addInput('success')"
                        ><i class="bi bi-plus-circle-fill link-secondary"></i
                      ></span>
                    </div>
                  </div>
                </div>
                <div v-if="item.type == 'select'" class="mb-3">
                  <label class="form-label">選択肢（NG）</label>
                  <div class="row">
                    <div
                      v-for="(failure_option, index) in item.failure_options"
                      :key="index"
                      class="col-6"
                    >
                      <div class="input-group mb-2">
                        <input
                          v-model="item.failure_options[index]"
                          type="text"
                          class="form-control"
                        />
                        <span
                          class="input-group-text bg-transparent"
                          @click="removeInput('failure', index)"
                          ><i class="bi bi-trash3 link-secondary"></i
                        ></span>
                      </div>
                    </div>
                    <div class="col-6">
                      <span
                        class="form-control-plaintext"
                        @click="addInput('failure')"
                        ><i class="bi bi-plus-circle-fill link-secondary"></i
                      ></span>
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="description" class="form-label">説明文</label>
                  <textarea
                    v-model="item.description"
                    class="form-control"
                    id="description"
                    rows="3"
                  ></textarea>
                </div>
                <div class="mb-3">
                  <label for="attention" class="form-label">注意事項</label>
                  <input
                    v-model="item.attention"
                    type="text"
                    class="form-control"
                    id="attention"
                  />
                </div>
              </div>
              <div class="col-6">
                <div v-if="item.image">
                  <label class="form-label">参考画像</label>
                  <div class="border mb-1">
                    <img :src="item.image" class="img-fluid" />
                  </div>
                  <p class="mb-0">
                    <span
                      class="link-text link-danger"
                      @click.prevent="deleteImage"
                      ><i class="bi bi-trash3 text-danger me-1"></i
                      >画像を削除する</span
                    >
                  </p>
                </div>
                <ImageSelect v-else @selected-func="selectedFile" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-if="is_edit"
            type="button"
            class="btn btn-success"
            @click="update"
          >
            編集
          </button>
          <button v-else type="button" class="btn btn-primary" @click="insert">
            追加
          </button>
          <button type="button" class="btn btn-secondary" @click="cancel">
            キャンセル
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import ImageSelect from './ImageSelect.vue'

export default {
  name: 'InspectionItemForm',
  components: {
    ImageSelect,
  },

  setup() {
    return { v$: useVuelidate() }
  },

  emits: ['insertItem', 'updateItem'],
  props: {
    item_types: {
      type: Array,
      required: true,
    },
    is_edit: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      modal: null,
      item: {},
      item_index: null,
    }
  },
  validations() {
    return {
      item: {
        title: { required },
        type: { required },
      },
    }
  },

  mounted() {
    this.modal = new Modal(document.getElementById('formModal'))
    document
      .getElementById('formModal')
      .addEventListener('hidden.bs.modal', this.cleanForm)
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    show(item, index) {
      this.item_index = index
      this.item = {
        type: item.type,
        title: item.title,
        description: item.description,
        image: item.image,
        attention: item.attention,
        success_options: Object.keys(item.success_options).map(function (key) {
          return item.success_options[key]
        }),
        failure_options: Object.keys(item.failure_options).map(function (key) {
          return item.failure_options[key]
        }),
      }
      if ('id' in item) {
        this.item.id = item.id
      }
      this.modal.show()
    },
    async insert() {
      const result = await this.v$.$validate()
      if (!result) {
        return
      }
      this.$emit('insertItem', this.item)
      this.modal.hide()
    },
    async update() {
      const result = await this.v$.$validate()
      if (!result) {
        return
      }
      this.$emit('updateItem', this.item, this.item_index)
      this.modal.hide()
    },
    cancel() {
      this.modal.hide()
    },
    addInput(type) {
      switch (type) {
        case 'success':
          this.item.success_options.push('')
          break
        case 'failure':
          this.item.failure_options.push('')
          break
      }
    },
    removeInput(type, index) {
      switch (type) {
        case 'success':
          this.item.success_options.splice(index, 1)
          break
        case 'failure':
          this.item.failure_options.splice(index, 1)
          break
      }
    },
    cleanForm() {
      this.v$.$reset()
      this.item.success_options = this.item.success_options.filter(Boolean)
      this.item.failure_options = this.item.failure_options.filter(Boolean)
    },
    deleteImage() {
      this.item.image = ''
    },
    selectedFile(dataURL) {
      this.item.image = dataURL
    },
  },
}
</script>
