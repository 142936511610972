<template>
  <code-reader
    v-if="showCodeCamera"
    ref="codeReader"
    @decode="onDecodeCodeData"
    @cancel="onCancelCodeCamera"
    class="animate__animated animate__fadeIn"
  ></code-reader>

  <div v-else>
    <navbar :show-display-name="false">
      <template v-slot:navbarTitle>
        <h1 class="h5 m-0">指示検査対象製品</h1>
      </template>
      <template v-slot:navbarButton>
        <router-link
          :to="{
            name: 'AppHomeOperate',
          }"
          class="btn btn-dark"
          tabindex="-1"
          ><i class="bi bi-0-square me-1"></i>戻る</router-link
        >
      </template>
    </navbar>

    <main id="main" class="bg-light">
      <div class="container py-3 py-lg-4">
        <div class="row row-cols-1 gy-2 gy-lg-3">
          <div class="col">
            <form class="card">
              <div class="card-body p-2 p-lg-3">
                <search-form
                  :query="query"
                  query-key="q"
                  placeholder="指示番号で検索"
                  :use-code-reader="true"
                  @start-code-camera="onStartCodeCamera"
                  @update-search-query="onUpdateSearchQuery"
                >
                  <template v-slot:searchButton> 検索 </template>
                </search-form>
              </div>
            </form>
          </div>

          <div class="col">
            <div v-if="items && items.length > 0">
              <div class="row row-cols-1 row-cols-md-2 g-1 g-lg-2 mb-3">
                <div v-for="(item, index) in items" :key="item.id" class="col">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                        <div class="m-0">
                          <h5 class="card-subtitle mb-1">
                            <router-link
                              :ref="'link' + (index + 1)"
                              :to="{
                                name: 'OperateInspectionPlanProductDetail',
                                params: { id: item.id },
                              }"
                              class="stretched-link text-decoration-none"
                              ><i
                                class="bi me-1"
                                :class="
                                  'bi-' + parseFloat(index + 1) + '-square'
                                "
                              ></i
                              >{{ item.code }}</router-link
                            >
                          </h5>
                          <h5 class="card-title m-0">
                            {{ item.name }}
                          </h5>
                          <h6 class="card-subtitle text-muted m-0">
                            {{ item.item_model }}&nbsp;
                          </h6>
                        </div>
                        <div>
                          <span v-if="item.has_image" class="badge bg-primary"
                            >画像あり</span
                          ><span v-else class="badge bg-secondary"
                            >画像なし</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="card-footer d-flex justify-content-end">
                      <div class="small text-end">
                        {{
                          item.classifications
                            ? item.classifications
                                .map((obj) => obj.name)
                                .join(', ')
                            : ''
                        }}&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center gap-2 mb-2">
                <pagination
                  :prev="prev"
                  :next="next"
                  ref="pagination"
                  @move-page="movePage"
                ></pagination>
              </div>
            </div>
            <div v-else class="alert alert-warning" role="alert">
              <span v-if="isSearching">該当するデータはありません</span>
              <span v-else>指示番号を入力してください</span>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import store from '../store'
import CodeReader from '@/components/AppCodeReader.vue'
import Navbar from '@/components/operate/OperateNavbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import SearchForm from '@/components/common/SearchForm.vue'

export default {
  name: 'OperateInspectionPlanProductList',
  components: {
    CodeReader,
    Navbar,
    Pagination,
    SearchForm,
  },

  data() {
    return {
      query: {},
      showCodeCamera: false,
      isSearching: false,
    }
  },
  computed: {
    items() {
      return store.getters['operate_inspection_plan_product/results']
    },
    prev() {
      return store.getters['operate_inspection_plan_product/prev']
    },
    next() {
      return store.getters['operate_inspection_plan_product/next']
    },
    saveQuery() {
      return store.getters['operate_inspection_plan_product/query']
    },
  },

  mounted() {
    window.addEventListener('keydown', this.handleKeyDown)
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  },
  async created() {
    this.query = this.saveQuery
    await this.setQuery(this.query)
  },
  beforeRouteLeave(to, from, next) {
    // 詳細画面以外のページに移動する場合にデータをクリアする
    if (to.name !== 'OperateInspectionPlanProductDetail') {
      this.clearQuery()
      this.clearData()
    }
    next()
  },

  methods: {
    getData() {
      this.isSearching = true
      return store.dispatch('operate_inspection_plan_product/fetchAll')
    },
    setQuery(query = {}) {
      store.commit('operate_inspection_plan_product/setQuery', query)
    },
    clearQuery() {
      store.commit('operate_inspection_plan_product/clearQuery')
    },
    clearData() {
      this.isSearching = false
      store.commit('operate_inspection_plan_product/clearData')
    },
    async movePage(page) {
      this.query = Object.assign(this.query, { page: page })
      await this.setQuery(this.query)
      await this.getData()
    },
    onStartCodeCamera() {
      this.showCodeCamera = true
    },
    onCancelCodeCamera() {
      this.showCodeCamera = false
    },
    async onDecodeCodeData(result) {
      this.query.q = result
      await this.onCancelCodeCamera()
      await this.setQuery(this.query)
      await this.getData()
    },
    async onUpdateSearchQuery(query = {}) {
      this.query = query
      await this.setQuery(this.query)
      if (!this.query.q && this.isSearching) {
        this.clearData()
      } else {
        await this.getData()
      }
    },
    handleKeyDown(event) {
      if (document.activeElement.tagName.toLowerCase() === 'input') {
        return
      }
      switch (event.key) {
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6': {
          const linkRef = this.$refs['link' + event.key]
          if (linkRef && linkRef[0]) {
            linkRef[0].$el.click()
          }
          break
        }
        case '9':
          this.onStartCodeCamera()
          break
        case '0':
          this.$router.push({ name: 'AppHomeOperate' })
          break
        case '+':
          this.$refs.pagination.moveNext()
          break
        case '-':
          this.$refs.pagination.movePrev()
          break
        case 'Escape':
        case 'Backspace':
          this.$refs.codeReader.cancelStream()
          break
        default:
          break
      }
    },
  },
}
</script>
