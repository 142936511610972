import OperatePurchaseHome from './views/Home.vue'
import OperatePurchase from './views/OperatePurchase.vue'
import OperatePurchaseDefectiveItemCreate from './views/OperatePurchaseDefectiveItemCreate.vue'

export default {
  routes: [
    {
      path: '/operate/purchase',
      component: OperatePurchaseHome,
      name: 'OperatePurchaseHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          component: OperatePurchase,
          name: 'OperatePurchase',
        },
        {
          path: 'defective-items/create',
          component: OperatePurchaseDefectiveItemCreate,
          name: 'OperatePurchaseDefectiveItemCreate',
        },
      ],
    },
  ],
}
