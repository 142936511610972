<template>
  <Navbar>
    <template v-slot:navbarTitle>品目管理</template>
  </Navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="clearfix mb-3">
      <div class="float-end">
        <button
          type="button"
          v-on:click.prevent="removeData()"
          class="btn btn-outline-danger me-1"
        >
          <span class="d-block d-sm-none"><i class="bi bi-x"></i></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-x me-1"></i>削除する</span
          >
        </button>
        <button
          type="button"
          v-on:click.prevent="showItemForm()"
          class="btn btn-outline-success me-1"
        >
          <span class="d-block d-sm-none"><i class="bi bi-pencil"></i></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-pencil me-1"></i>編集する</span
          >
        </button>
        <router-link
          :to="{
            name: 'ItemList',
          }"
          class="btn btn-outline-dark"
        >
          <span class="d-block d-sm-none"
            ><i class="bi bi-arrow-left"></i
          ></span>
          <span class="d-none d-sm-block">一覧に戻る</span>
        </router-link>
      </div>
      <h5><i class="bi bi-box me-1"></i>品目詳細</h5>
    </div>

    <div v-if="!isLoading">
      <h5>
        品目情報
        <a
          class="fs-6"
          data-bs-toggle="collapse"
          href="#resultData"
          role="button"
          aria-expanded="true"
          aria-controls="resultData"
          ><i class="bi bi-arrows-collapse"></i>
        </a>
      </h5>

      <div class="collapse show" id="resultData">
        <div class="row g-1 g-lg-2">
          <div class="col-12 col-md-6 col-lg-8">
            <table class="table table-bordered table-row bg-white mb-3">
              <tbody>
                <tr>
                  <th class="col-sm-4 bg-light">品目コード</th>
                  <td class="col-sm-8">{{ item.code }}</td>
                </tr>
                <tr>
                  <th class="bg-light">型式</th>
                  <td>{{ item.item_model }}</td>
                </tr>
                <tr>
                  <th class="bg-light">品目名</th>
                  <td>{{ item.name }}</td>
                </tr>
                <tr>
                  <th class="bg-light">品目分類</th>
                  <td>
                    {{
                      item.classifications
                        ? item.classifications.map((obj) => obj.name).join(', ')
                        : ''
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="bg-light">メモ</th>
                  <td>{{ item.memo }}</td>
                </tr>
                <tr>
                  <th class="bg-light">登録日時</th>
                  <td>{{ item.created_at }}</td>
                </tr>
                <tr>
                  <th class="bg-light">更新日時</th>
                  <td>{{ item.updated_at }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="ol-12 col-md-6 col-lg-4">
            <div class="row row-cols-2 g-1 g-md-2">
              <ImageGallery v-if="item.images" :files="item.images">
                <template v-slot:thumbnail-operation-icons="{ file }">
                  <div class="d-flex justify-content-end p-1">
                    <button
                      class="btn btn-sm btn-outline-danger border-0"
                      @click="removeImage(file.id)"
                    >
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </template>
              </ImageGallery>
              <div v-if="!item.images || item.images.length < 4" class="col">
                <button
                  type="button"
                  class="btn btn-outline-secondary w-100"
                  @click="showImageForm()"
                >
                  <i class="bi bi-plus"></i> 画像追加
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <!-- detail -->
      <h5>関連品目</h5>
      <div v-if="item.related_items && item.related_items.length > 0">
        <div class="table-responsive">
          <table class="table table-bordered bg-white">
            <thead>
              <tr>
                <th scope="col" class="col-sm-3">品目コード</th>
                <th scope="col" class="col-sm-3">型式</th>
                <th scope="col" class="col-sm-6">品目名</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="related_item in item.related_items"
                :key="related_item.id"
              >
                <td class="text-center">
                  <router-link
                    :to="{
                      name: 'ItemDetail',
                      params: {
                        id: related_item.id,
                      },
                    }"
                    >{{ related_item.code }}</router-link
                  >
                </td>
                <td class="text-center">{{ related_item.item_model }}</td>
                <td>{{ related_item.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p v-else class="mt-3">該当するデータはありません。</p>
    </div>
    <DeleteDialog ref="confirmDataDelete" @confirm-true="confirmDataDelete" />
    <Confirm ref="confirmImageDelete" @confirm-true="confirmImageDelete" />
    <ImageModal ref="imageModal" />
    <ItemForm ref="itemForm" @after-submit="afterSubmit">
      <template v-slot:modalTitle>品目編集</template>
    </ItemForm>
    <PreviewUpload
      ref="itemImageForm"
      accept="image/*"
      @upload-complete="imageUploaded"
    />
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import DeleteDialog from '@/components/AppDeleteDialog.vue'
import Confirm from '@/components/TheConfirm.vue'
import ImageModal from '@/components/TheImageModal.vue'
import ItemForm from '@/components/manage/ItemForm.vue'
import PreviewUpload from '@/components/common/PreviewUpload.vue'
import ImageGallery from '@/components/AppImageGallery.vue'

export default {
  name: 'ItemDetail',
  components: {
    Navbar,
    DeleteDialog,
    Confirm,
    ImageModal,
    ItemForm,
    PreviewUpload,
    ImageGallery,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
      deleteImageId: null,
    }
  },
  computed: {
    ...mapGetters({
      item: 'item/detail',
    }),
  },

  async created() {
    try {
      await this.getData(this.id)
    } catch (error) {
      if (error.status) {
        window.setTimeout(() => {
          this.$router.push({
            name: 'ItemList',
          })
        }, this.$store.getters['alert/timeout'])
      }
    } finally {
      this.isLoading = false
    }
  },

  methods: {
    ...mapActions({
      getData: 'item/fetchData',
      deleteData: 'item/deleteData',
      imagePost: 'item_image/postData',
      imageDelete: 'item_image/deleteData',
    }),
    showImage(url) {
      this.$refs.imageModal.showModal(url)
    },
    showItemForm() {
      this.$refs.itemForm.show(this.item)
    },
    showImageForm() {
      this.$refs.itemImageForm.show()
    },
    async imageUploaded(fileData) {
      try {
        fileData.item_id = this.id
        await this.imagePost(fileData)
        await this.getData(this.id)
      } catch (e) {
        if (e.response?.status == 400) {
          this.$store.dispatch('alert/setErrorMessage', {
            message: e.response.data,
          })
        }
      }
    },
    removeImage(imageId) {
      this.deleteImageId = imageId
      this.$refs.confirmImageDelete.showConfirm('', 'この画像を削除しますか？')
    },
    async confirmImageDelete() {
      try {
        await this.imageDelete(this.deleteImageId)
        this.deleteImageId = null
        await this.getData(this.id)
      } catch (e) {
        if (e.response?.status == 400) {
          this.$store.dispatch('alert/setErrorMessage', {
            message: e.response.data,
          })
        }
      }
    },
    afterSubmit() {
      this.getData(this.id)
    },
    removeData() {
      this.$refs.confirmDataDelete.showDialog(
        '品目データ',
        this.item.code,
        ['単価', '在庫品目', 'ピッキングリスト'],
        ['受注']
      )
    },
    async confirmDataDelete() {
      try {
        await this.deleteData(this.id)
        await this.$router.push({
          name: 'ItemList',
        })
      } catch (e) {
        if (e.response.status == 400) {
          this.$store.dispatch('alert/setErrorMessage', {
            message: e.response.data,
          })
        }
      }
    },
  },
}
</script>
