<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row g-3">
            <div v-if="!formData?.id" class="col-12">
              <label :for="modalId + '_form_cause'" class="form-label"
                >不良原因<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <select
                v-model="formData.cause"
                class="form-select"
                :class="{ 'is-invalid': formErrors?.cause_id }"
                :id="modalId + '_form_cause'"
              >
                <option :value="undefined">---</option>
                <option
                  v-for="cause in causeList"
                  :key="cause.id"
                  :value="cause"
                >
                  {{ cause.cause }}
                </option>
              </select>
              <div v-if="formErrors?.cause_id" class="invalid-feedback">
                {{ formErrors.cause_id }}
              </div>
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_cause_detail'" class="form-label"
                >不良原因詳細</label
              >
              <input
                v-model="formData.cause_detail"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.cause_detail }"
                :id="modalId + '_form_cause_detail'"
              />
              <div class="text-muted small">
                不良の原因について詳細を入力してください。
              </div>
              <div v-if="formErrors?.cause_detail" class="invalid-feedback">
                {{ formErrors.cause_detail }}
              </div>
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_quantity'" class="form-label"
                >数量<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <FormNumericInput
                v-model="formData.quantity"
                :error="formErrors.quantity"
                :id="modalId + '_form_quantity'"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            閉じる
          </button>
          <button
            type="button"
            class="btn btn-dark"
            v-on:click.prevent="formSubmit"
          >
            <slot name="submitButton">追加</slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { randomString } from '@/utils/stringUtils.js'
import FormNumericInput from '@/components/common/FormNumericInput.vue'

export default {
  name: 'DefectiveItemForm',
  components: {
    FormNumericInput,
  },

  emits: ['hiddenModal', 'afterSubmit'],

  data() {
    return {
      modal: null,
      formData: {},
      formErrors: {},
    }
  },
  computed: {
    causeList() {
      return store.getters['operate_purchase_defective_item_cause/list']
    },
    modalId: function () {
      return 'form-modal-' + this.randomString()
    },
  },

  async created() {
    await store.dispatch('operate_purchase_defective_item_cause/fetchList', {
      limit: 0,
    })
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.formErrors = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    randomString,
    show(formData = {}) {
      this.formData = Object.assign({}, formData)
      this.modal.show()
    },
    formSubmit() {
      this.formErrors = {}
      if (!this.formData.cause) {
        this.formErrors.cause_id = '原因を選択してください。'
      }
      if (!this.formData.quantity || this.formData.quantity.length === 0) {
        this.formErrors.quantity = '数量を入力してください。'
      }
      if (Object.keys(this.formErrors).length > 0) {
        return
      }

      this.$emit('afterSubmit', this.formData)
      this.modal.hide()
    },
  },
}
</script>
