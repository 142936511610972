import ManageInspectionPlanHome from './views/Home.vue'
import ManageInspectionPlan from './views/InspectionPlan.vue'
import ManageInspectionPlanImport from './views/InspectionPlanImport.vue'
import ManageInspectionPlanDetail from './views/InspectionPlanDetail.vue'
import ManageInspectionPlanResultDetail from './views/InspectionPlanResultDetail.vue'

export default {
  routes: [
    {
      path: '/manage/inspection_plan',
      component: ManageInspectionPlanHome,
      name: 'ManageInspectionPlanHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'plans',
          component: ManageInspectionPlan,
          name: 'ManageInspectionPlan',
        },
        {
          path: 'plans/import',
          component: ManageInspectionPlanImport,
          name: 'ManageInspectionPlanImport',
        },
        {
          path: 'plans/:id([0-9a-zA-Z]{26})',
          component: ManageInspectionPlanDetail,
          name: 'ManageInspectionPlanDetail',
          props: (route) => ({ id: route.params.id }),
        },
        {
          path: 'plans/:plan_id([0-9a-zA-Z]{26})/results/:result_id([0-9a-zA-Z]{26})',
          component: ManageInspectionPlanResultDetail,
          name: 'ManageInspectionPlanResultDetail',
          props: (route) => ({
            plan_id: route.params.plan_id,
            result_id: route.params.result_id,
          }),
        },
      ],
    },
  ],
}
