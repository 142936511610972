<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row g-3">
            <div class="col-12">
              <p class="text-center m-0 p-0 lead">{{ message }}</p>
            </div>
            <div class="col-6">
              <button
                type="button"
                class="btn btn-secondary w-100"
                data-bs-dismiss="modal"
              >
                いいえ
              </button>
            </div>
            <div class="col-6">
              <button
                type="button"
                class="btn btn-dark w-100"
                v-on:click.prevent="formSubmit()"
              >
                <slot name="submitButton">はい</slot>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { randomString } from '@/utils/stringUtils.js'

export default {
  name: 'OrderStatusUpdateForm',
  components: {},

  emits: ['hiddenModal', 'afterSubmit'],

  data() {
    return {
      modal: null,
      formData: {},
      formErrors: {},
      message: '受注ステータスを更新しますか？',
    }
  },
  computed: {
    modalId: function () {
      return 'form-modal-' + this.randomString()
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.formErrors = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    randomString,
    statusUpdate(formData) {
      return store.dispatch('operate_order/statusUpdate', formData)
    },
    show(formData = {}, message = null) {
      this.formData = Object.assign({}, formData)
      if (message) {
        this.message = message
      }
      this.modal.show()
    },
    async formSubmit() {
      this.formErrors = {}
      if (Object.keys(this.formErrors).length > 0) {
        return
      }
      try {
        this.formErrors = {}
        await this.statusUpdate(this.formData)
        window.setTimeout(async () => {
          await this.$emit('afterSubmit')
          await this.modal.hide()
        }, 1000)
      } catch (e) {
        if (e.response.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            this.setErrorMessage(key, e.response.data[key])
          })
        }
      }
    },
    setErrorMessage(key, errorData) {
      if (Array.isArray(errorData)) {
        this.formErrors[key] = errorData.join('\n')
      } else {
        this.formErrors[key] = errorData
      }
    },
  },
}
</script>
