import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  results: (state) => state.data.results,
  count: (state) => state.data.count,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  current_page: (state) => state.data.current_page,
  total_pages: (state) => state.data.total_pages,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  /**
   * すべてのデータをリセットする
   *
   * @return {void}
   */
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      resolve()
    })
  },
  /**
   * 一覧データを取得する
   *
   * @return {void}
   */
  async fetchAll({ commit }, query_params) {
    // パラメーターの値が空の場合は削除する
    Object.keys(query_params).forEach(
      (key) => query_params[key] === '' && delete query_params[key]
    )
    const response = await api.get('manage/inspection_calls/', {
      params: query_params,
    })
    commit('setData', response.data)
  },
  /**
   * エクスポートデータを取得する
   *
   * @param {object}
   * @return {object}
   */
  async fetchExportData({}, query_params) {
    const response = await api.get('manage/inspection_calls/export/', {
      params: query_params,
    })
    return response.data
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {object}
   */
  async postData({}, form_data) {
    try {
      const response = await api.post('manage/inspection_calls/', form_data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {object}
   */
  async patchData({}, form_data) {
    try {
      const response = await api.patch(
        'manage/inspection_calls/' + form_data.id + '/',
        form_data
      )
      return response.data
    } catch (error) {
      throw error
    }
  },
  /**
   * データを作成または更新する
   *
   * @param {object}
   * @return {void}
   */
  async postOrPatch({ dispatch }, form_data) {
    // 必要なデータのみにする
    const required_keys = ['id', 'inspection_type_id', 'inspection_id', 'code']
    const submit_data = Object.fromEntries(
      Object.entries(form_data).filter(([key, value]) =>
        required_keys.includes(key)
      )
    )

    try {
      if ('id' in submit_data && submit_data['id']) {
        await dispatch('patchData', submit_data)
      } else {
        await dispatch('postData', submit_data)
      }
    } catch (error) {
      throw error
    }
  },
  /**
   * データをまとめて作成または更新する
   *
   * @param {array}
   * @return {void}
   */
  async bulkPostOrPatch({ dispatch }, array_data) {
    array_data.forEach(async (form_data) => {
      await dispatch('postOrPatch', form_data)
    })
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {object}
   */
  async deleteData({}, id) {
    const response = await api.delete('manage/inspection_calls/' + id + '/')
    return response.data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
