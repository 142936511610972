<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">{{ object.name }}を削除しますか？</h6>
        </div>
        <div class="modal-body">
          <p class="m-0">【{{ object.data }}】を削除します。</p>
          <div v-if="object?.relations.length > 0">
            <p class="m-0">
              {{ object.data }}に関連する次のデータも削除されます。
            </p>
            <ul>
              <li v-for="relation in object.relations" :key="relation">
                {{ relation }}
              </li>
            </ul>
          </div>
          <div v-if="object?.protect_relations.length > 0">
            <p class="m-0">
              {{ object.data }}に関連する次のデータがある場合は削除できません。
            </p>
            <ul>
              <li v-for="relation in object.protect_relations" :key="relation">
                {{ relation }}
              </li>
            </ul>
          </div>
          <p class="m-0">削除されたデータは元に戻せません。</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            キャンセル
          </button>
          <button
            type="button"
            class="btn btn-danger"
            v-on:click.prevent="confirmTrue"
          >
            削除
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { randomString } from '@/utils/stringUtils.js'

export default {
  name: 'DeleteDialog',

  emits: ['hiddenModal', 'confirmTrue'],

  data() {
    return {
      modal: null,
      object: {
        name: '',
        data: '',
        relations: [],
        protect_relations: [],
      },
    }
  },
  computed: {
    modalId: function () {
      return 'form-modal-' + this.randomString()
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    randomString,
    showDialog(name, data, relations = [], protect_relations = []) {
      this.object = {
        name: name,
        data: data,
        relations: relations,
        protect_relations: protect_relations,
      }
      this.modal.show()
    },
    confirmTrue() {
      this.$emit('confirmTrue')
      this.modal.hide()
    },
  },
}
</script>
