<template>
  <VueMultiselect
    v-model="selectedValue"
    @update:modelValue="updateValue"
    :options="options"
    :searchable="true"
    :loading="isLoading"
    :internal-search="false"
    @select="selected"
    @search-change="loadOptions"
    :custom-label="customLabel"
    :show-label="false"
    track-by="id"
    :placeholder="placeholder"
    :selectLabel="''"
    :selectedLabel="'選択中'"
    :deselectLabel="'選択解除'"
    :showNoOptions="false"
    :showNoResults="false"
    :id="id"
    :class="{ 'is-invalid text-danger': error }"
  >
  </VueMultiselect>
  <div v-if="error" class="invalid-feedback">
    {{ error }}
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'

export default {
  name: 'FormSearchSelect',
  components: {
    VueMultiselect,
  },

  props: {
    modelValue: {
      type: [String, Number, Object],
      default: null,
    },
    options: {
      type: Array,
      default: [],
    },
    error: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '品目コード・品目名で検索',
    },
    selected: {
      type: Function,
      default: () => {},
    },
  },

  emits: ['update:modelValue', 'getOptions'],

  data() {
    return {
      isLoading: false,
      selectedValue: this.modelValue,
    }
  },
  watch: {
    modelValue(newVal) {
      this.selectedValue = newVal
    },
  },

  async created() {
    await this.$emit('getOptions')
  },

  methods: {
    async loadOptions(query) {
      if (query && query.length > 1) {
        this.isLoading = true
        await this.$emit('getOptions', { q: query })
        this.isLoading = false
      }
      if (!query) {
        this.isLoading = true
        await this.$emit('getOptions')
        this.isLoading = false
      }
    },
    customLabel({ code, name }) {
      if (code) {
        return `[${code}] ${name}`
      } else {
        return name
      }
    },
    updateValue(value) {
      this.$emit('update:modelValue', value)
    },
  },
}
</script>
