<template>
  <CodeReader
    v-if="showCodeCamera"
    ref="codeReader"
    @decode="onDecodeCodeData"
    @cancel="onCancelCodeCamera"
    class="animate__animated animate__fadeIn"
  />

  <div v-else>
    <Navbar>
      <template v-slot:navbarTitle>受注管理</template>
    </Navbar>

    <main id="main" class="container-fluid pt-3">
      <div class="d-flex gap-2 mb-3">
        <h5 class="m-0 p-0">
          <i class="bi bi-file-earmark-text me-1"></i>売上一覧
        </h5>
        <div class="ms-auto">
          <button
            type="button"
            v-on:click.prevent="convertToCsvConfirm()"
            class="btn btn-dark"
          >
            <span class="d-block d-sm-none"
              ><i class="bi bi-arrow-repeat"></i
            ></span>
            <span class="d-none d-sm-block"
              ><i class="bi bi-arrow-repeat me-1"></i
              >データをCSVに変換する</span
            >
          </button>
        </div>
      </div>

      <div class="d-flex justify-content-start gap-2 mb-2">
        <div class="me-auto col-6 col-lg-4 col-xl-3">
          <search-form
            :query="query"
            query-key="number"
            placeholder="受注番号で検索"
            :use-code-reader="true"
            :use-search-modal="true"
            @update-search-query="onUpdateSearchQuery"
            @start-code-camera="onStartCodeCamera"
            @open-search-modal="onOpenSearchModal"
          ></search-form>
        </div>
        <Pagination :prev="prev" :next="next" @move-page="movePage" />
      </div>

      <!-- orders -->
      <div v-if="orders && orders.length > 0">
        <data-table :headers="dataHeaders" :items="orders">
          <template #number="{ item }">
            <router-link
              :to="{
                name: 'ManageOrderSalesDetail',
                params: { id: item.id },
              }"
              >{{ item.number }}</router-link
            >
          </template>
        </data-table>
      </div>
      <p v-else class="mt-3">該当するデータはありません。</p>

      <!-- Modal -->
      <search-modal
        :query="query"
        :items="searchItems"
        @update-search-query="onUpdateSearchQuery"
        ref="searchModal"
      ></search-modal>

      <Confirm ref="confirmConvertData" @confirm-true="convertToCsv" />
    </main>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import Confirm from '@/components/TheConfirm.vue'
import CodeReader from '@/components/AppCodeReader.vue'
import DataTable from '@/components/common/DataTable.vue'
import SearchForm from '@/components/common/SearchForm.vue'
import SearchModal from '@/components/common/SearchModal.vue'

export default {
  name: 'ManageOrderList',
  components: {
    Navbar,
    Pagination,
    Confirm,
    CodeReader,
    DataTable,
    SearchForm,
    SearchModal,
  },

  data() {
    return {
      query: {},
      showCodeCamera: false,
      dataHeaders: [
        {
          key: 'number',
          label: '受注番号',
          class: 'col-1',
          format: 'code',
        },
        {
          key: 'client_code',
          label: '顧客コード',
          class: 'col-1',
          format: 'code',
        },
        {
          key: 'client_name',
          label: '顧客名',
          class: 'col-3',
          format: 'string',
        },
        {
          key: 'total_amount',
          label: '合計金額',
          class: 'col-1',
          format: 'number',
        },
        {
          key: 'ordered_at',
          label: '受注日時',
          class: 'col-2',
          format: 'datetime',
        },
        {
          key: 'sold_at',
          label: '売上日時',
          class: 'col-2',
          format: 'datetime',
        },
        {
          key: 'updated_at',
          label: '更新日時',
          class: 'col-2',
          format: 'datetime',
        },
      ],
    }
  },
  computed: {
    orders() {
      return store.getters['manage_order/results']
    },
    prev() {
      return store.getters['manage_order/prev']
    },
    next() {
      return store.getters['manage_order/next']
    },
    saveQuery() {
      return store.getters['manage_order/query']
    },
    statusList() {
      return store.getters['manage_order/statusList']
    },
    searchItems() {
      return [
        {
          id: 'number',
          label: '受注番号（前方一致）',
          type: 'text',
        },
        {
          id: 'client_code',
          label: '顧客コード（完全一致）',
          type: 'text',
        },
        {
          id: 'client_name',
          label: '顧客名（部分一致）',
          type: 'text',
        },
        {
          id: 'order_date',
          label: '受注日',
          type: 'date',
        },
        {
          id: 'sold_date',
          label: '売上日',
          type: 'date',
        },
      ]
    },
  },

  async created() {
    this.query = this.saveQuery
    await this.getQueryResult()
  },
  beforeRouteLeave(to, from, next) {
    // 詳細画面以外のページに移動する場合にデータをクリアする
    if (to.name !== 'ManageOrderSalesDetail') {
      this.resetQuery()
    }
    next()
  },

  methods: {
    ...mapActions({
      postExportData: 'export_data/postExportData',
    }),
    getData() {
      return store.dispatch('manage_order/fetchAll')
    },
    setQuery(query = {}) {
      store.commit('manage_order/setQuery', query)
    },
    clearQuery() {
      store.commit('manage_order/clearQuery')
    },
    clearData() {
      store.commit('manage_order/clearData')
    },
    movePage(page) {
      Object.assign(this.query, { page: page })
      this.getQueryResult()
    },
    getQueryResult() {
      this.setQuery(Object.assign({ latest_status: 'sold' }, this.query))
      this.getData()
    },
    resetQuery() {
      this.clearQuery()
      this.clearData()
    },
    onOpenSearchModal() {
      this.$refs.searchModal.show()
    },
    onUpdateSearchQuery(query = {}) {
      this.query = query
      this.getQueryResult()
    },
    onStartCodeCamera() {
      this.showCodeCamera = true
    },
    onCancelCodeCamera() {
      this.showCodeCamera = false
    },
    onDecodeCodeData(result) {
      this.query.number = result
      this.showCodeCamera = false
    },
    convertToCsvConfirm() {
      this.$refs.confirmConvertData.showConfirm(
        'データを変換',
        'この内容でCSVに変換しますか？'
      )
    },
    convertToCsv() {
      const formData = {
        application: 'orders',
        conditions: Object.assign({ latest_status: 'sold' }, this.query),
      }
      try {
        this.postExportData(formData)
        this.$store.dispatch('alert/setSuccessMessage', {
          message:
            'CSVファイルへの変換を開始しました。エクスポート管理からダウンロードしてください',
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
