<template>
  <Navbar>
    <template v-slot:navbarTitle>
      <h1 class="h5 m-0">受注詳細</h1>
    </template>
    <template v-slot:navbarButton>
      <router-link
        :to="{
          name: 'OperateOrderList',
        }"
        class="btn btn-dark"
        >戻る</router-link
      >
    </template>
  </Navbar>

  <main id="main" class="bg-light">
    <div v-if="!isLoading" class="container py-3 py-lg-4">
      <div class="d-flex justify-content-between">
        <h5>{{ order.number }}</h5>
        <div>
          <span class="badge" :class="checkOrderStatus(order.latest_status)">{{
            order.latest_status
          }}</span>
        </div>
      </div>

      <div class="row g-1 g-lg-2">
        <div class="col-12 col-md-6">
          <table class="table table-bordered table-row bg-white mb-3">
            <tbody>
              <tr>
                <th class="bg-light">顧客コード</th>
                <td>{{ order.client_code }}</td>
              </tr>
              <tr>
                <th class="bg-light">顧客名</th>
                <td>{{ order.client_name }}</td>
              </tr>
              <tr>
                <th class="bg-light">合計金額</th>
                <td>{{ toCommaSeparatedNumber(order.total_amount) }}</td>
              </tr>
              <tr>
                <th class="bg-light">備考</th>
                <td>{{ order.memo }}</td>
              </tr>
              <tr>
                <th class="bg-light">受注日時</th>
                <td>{{ order.ordered_at }}</td>
              </tr>
              <tr>
                <th class="bg-light">売上日時</th>
                <td>{{ order.sold_at }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- items -->
      <div
        v-if="order.order_items && order.order_items.length > 0"
        class="row row-cols-1 g-2 g-lg-3"
      >
        <div v-for="item in order.order_items" :key="item.id" class="col">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title m-0">
                {{ item.item?.name }}
              </h5>
              <h6 class="card-subtitle text-muted m-0">
                【{{ item.item?.code }}】{{ item.item?.item_model }}
              </h6>
              <p>{{ item.memo }}</p>
              <div class="d-flex justify-content-between">
                <div>
                  <span class="small text-muted d-block">数量</span
                  >{{ item.quantity }}
                </div>
                <div class="text-end">
                  <span class="small text-muted d-block">小計</span
                  >{{ toCommaSeparatedNumber(item.subtotal) }}
                </div>
              </div>
              <div
                v-if="
                  activeApp('inventory') && !order.is_closed && item.stock_id
                "
                class="d-flex justify-content-end mt-3"
              >
                <button
                  v-on:click.prevent="showIncomingForm(item.stock_id)"
                  type="button"
                  class="btn btn-outline-dark me-2"
                >
                  入庫
                </button>
                <button
                  v-on:click.prevent="showOutgoingForm(item.stock_id)"
                  type="button"
                  class="btn btn-dark"
                >
                  出庫
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!order.is_closed" class="col-12 col-md-6">
          <button
            v-on:click.prevent="
              showOrderStatusUpdateForm('cancelled', '受注を取り消しますか？')
            "
            type="button"
            class="btn btn-lg btn-outline-primary w-100"
          >
            <i class="bi bi-x-circle me-1"></i>受注を取り消す
          </button>
        </div>
        <div v-if="!order.is_closed" class="col-12 col-md-6">
          <button
            v-on:click.prevent="
              showOrderStatusUpdateForm('sold', '売上済みにしますか？')
            "
            type="button"
            class="btn btn-lg btn-primary w-100"
          >
            <i class="bi bi-check-circle-fill me-1"></i>売上済みにする
          </button>
        </div>
      </div>
    </div>

    <StockFlowForm ref="stockIncomingForm" submit-method="incoming">
      <template v-slot:modalTitle>在庫入庫</template>
      <template v-slot:submitButton>入庫</template>
    </StockFlowForm>
    <StockFlowForm ref="stockOutgoingForm" submit-method="outgoing">
      <template v-slot:modalTitle>在庫出庫</template>
      <template v-slot:submitButton>出庫</template>
    </StockFlowForm>
    <OrderStatusUpdateForm ref="orderStatusUpdateForm" />
  </main>
</template>

<script>
import store from '../store'
import { toCommaSeparatedNumber } from '@/utils/stringUtils'
import { mapGetters } from 'vuex'
import Navbar from '../components/OrderNavbar.vue'
import StockFlowForm from '../components/OrderStockFlowForm.vue'
import OrderStatusUpdateForm from '../components/OrderStatusUpdateForm.vue'

export default {
  name: 'OperateOrderDetail',
  components: {
    Navbar,
    StockFlowForm,
    OrderStatusUpdateForm,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters({
      activeApp: 'auth/activeApp',
    }),
    order() {
      return store.getters['operate_order/detail']
    },
  },

  async created() {
    try {
      await this.getData(this.id)
    } catch (error) {
      if (error.status) {
        window.setTimeout(() => {
          this.$router.push({
            name: 'OperateOrderList',
          })
        }, this.$store.getters['alert/timeout'])
      }
    } finally {
      this.isLoading = false
    }
  },

  methods: {
    toCommaSeparatedNumber,
    getData(id) {
      return store.dispatch('operate_order/fetchData', id)
    },
    showOrderStatusUpdateForm(status, message = null) {
      this.$refs.orderStatusUpdateForm.show(
        {
          id: this.id,
          status: status,
        },
        message
      )
    },
    showIncomingForm(stock_id) {
      this.$refs.stockIncomingForm.show(stock_id)
    },
    showOutgoingForm(stock_id) {
      this.$refs.stockOutgoingForm.show(stock_id)
    },
    checkOrderStatus(latest_status) {
      switch (true) {
        case latest_status == '受注':
          return 'bg-primary'
        case latest_status == '売上':
          return 'bg-success'
        case latest_status == '取消':
          return 'bg-warning'
        default:
          return 'bg-secondary'
      }
    },
  },
}
</script>
