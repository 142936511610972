<template>
  <div
    class="d-flex justify-content-center align-items-center vh-100 flex-column"
  >
    <div class="ratio ratio-1x1 camera-wrapper">
      <video :id="videoInputId" playsinline muted autoplay></video>
      <canvas
        :id="videoCanvasId"
        width="500"
        height="500"
        class="bg-dark"
      ></canvas>
    </div>

    <p class="mt-2">
      <button @click="cancelStream" type="button" class="btn btn-secondary">
        カメラを停止する
      </button>
    </p>

    <p v-if="alertMessage" class="text-danger small">
      {{ alertMessage }}
    </p>
  </div>
</template>

<script>
import jsQR from 'jsqr'
import { randomString, trimToWhiteSpace } from '@/utils/stringUtils.js'

export default {
  name: 'CodeReader',

  emits: ['decode', 'cancel'],

  data() {
    return {
      interval: 200,
      intervalId: null,
      video: null,
      videoCanvas: null,
      videoContext: null,
      alertMessage: '　',
    }
  },
  computed: {
    videoInputId: function () {
      return 'video-input-' + this.randomString()
    },
    videoCanvasId: function () {
      return 'video-canvas-' + this.randomString()
    },
  },

  mounted() {
    this.resetStream()
    this.videoCanvas = document.getElementById(this.videoCanvasId)
    this.videoContext = this.videoCanvas.getContext('2d', {
      willReadFrequently: true,
    })
    this.startStream()
  },
  beforeUnmount() {
    this.resetStream()
  },

  methods: {
    randomString,
    trimToWhiteSpace,
    cancelStream() {
      this.resetStream()
      this.$emit('cancel')
    },
    resetStream() {
      if (this.videoContext != null) {
        this.videoContext.clearRect(
          0,
          0,
          this.videoCanvas.width,
          this.videoCanvas.height
        )
      }
      this.clearStream()
    },
    clearStream() {
      clearInterval(this.intervalId)
      if (this.video != null) {
        this.video.srcObject?.getVideoTracks().forEach((track) => {
          track.stop()
          this.video.srcObject.removeTrack(track)
        })
      }
    },
    startStream() {
      this.video = document.getElementById(this.videoInputId)
      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            aspectRatio: 1,
            facingMode: 'environment',
          },
        })
        .then((stream) => {
          this.video.srcObject = stream
          this.intervalId = setInterval(() => {
            this.scanCode()
          }, this.interval)
        })
        .catch((error) => {
          this.onInit(error)
        })
    },
    scanCode() {
      const currentSize = this.getCurrentSize(this.videoCanvas)
      this.videoContext.drawImage(
        this.video,
        0,
        0,
        this.video.videoWidth,
        this.video.videoHeight,
        currentSize.x,
        currentSize.y,
        currentSize.w,
        currentSize.h
      )
      const imageData = this.videoContext.getImageData(
        0,
        0,
        currentSize.w,
        currentSize.h
      )
      const code = jsQR(imageData.data, currentSize.w, currentSize.h)
      if (code) {
        this.$emit('decode', this.trimToWhiteSpace(code.data))
      }
    },
    getCurrentSize(targetCanvas) {
      let currentW = targetCanvas.width,
        currentH = targetCanvas.height,
        currentX = 0,
        currentY = 0

      if (this.video.videoWidth < this.video.videoHeight) {
        // 縦向き・幅に合わせる
        const videoR = targetCanvas.width / this.video.videoWidth
        currentW = targetCanvas.width
        currentH = this.video.videoHeight * videoR
      }
      if (this.video.videoWidth > this.video.videoHeight) {
        // 横向き
        const orgR = 9 / 16
        const videoR = this.video.videoHeight / this.video.videoWidth
        if (orgR < videoR) {
          // 比率が大きい場合は高さに合わせる
          const differenceW = targetCanvas.width - targetCanvas.width * videoR
          currentW = targetCanvas.width * videoR
          currentH = targetCanvas.height
          currentX = differenceW / 2
        }
        if (orgR > videoR) {
          // 比率が小さい場合は幅に合わせる
          const differenceH = targetCanvas.height - targetCanvas.height * videoR
          currentW = targetCanvas.width
          currentH = targetCanvas.height * videoR
          currentY = differenceH / 2
        }
      }

      return {
        w: currentW,
        h: currentH,
        x: currentX,
        y: currentY,
      }
    },
    onInit(error) {
      switch (error.name) {
        case 'NotAllowedError':
          this.alertMessage = 'カメラへのアクセスを許可してください'
          break
        case 'NotFoundError':
          this.alertMessage = 'このデバイスにはカメラがありません'
          break
        case 'NotSupportedError':
          this.alertMessage = '接続環境が安全ではありません'
          break
        case 'NotReadableError':
          this.alertMessage = 'カメラはすでに使用されています'
          break
        case 'OverconstrainedError':
          this.alertMessage = '接続されたカメラは適切ではありません'
          break
        case 'StreamApiNotSupportedError':
          this.alertMessage = 'このブラウザはサポートされていません'
          break
      }
    },
  },
}
</script>

<style scoped>
.camera-wrapper {
  margin: 0 auto;
  max-width: 90%;
  width: 300px;
}
</style>
