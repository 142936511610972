<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row g-3">
            <div v-if="!formData?.id" class="col-12">
              <label :for="modalId + '_form_item'" class="form-label"
                >品目<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <FormSearchSelect
                v-model="formData.item"
                :options="itemList"
                :error="formErrors.item_id"
                :id="modalId + '_form_item'"
                placeholder="品目コード、品目名で検索"
                :selected="setCurrentUnitPrice"
                @getOptions="getItemList"
              />
            </div>
            <div class="col-4">
              <label :for="modalId + '_form_unit_price'" class="form-label"
                >単価<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <FormNumericInput
                v-model="formData.unit_price_at_order"
                :error="formErrors.unit_price_at_order"
                :id="modalId + '_form_unit_price'"
              />
            </div>
            <div class="col-4">
              <label :for="modalId + '_form_quantity'" class="form-label"
                >数量<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <FormNumericInput
                v-model="formData.quantity"
                :error="formErrors.quantity"
                :id="modalId + '_form_quantity'"
              />
            </div>
            <div class="col-4">
              <label :for="modalId + '_form_subtotal'" class="form-label"
                >小計</label
              >
              <input
                v-model="formData.subtotal"
                type="text"
                class="form-control-plaintext"
                :id="modalId + '_form_subtotal'"
                readonly
              />
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_memo'" class="form-label"
                >メモ</label
              >
              <input
                v-model="formData.memo"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.memo }"
                :id="modalId + '_form_memo'"
              />
              <div v-if="formErrors?.memo" class="invalid-feedback">
                {{ formErrors.memo }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            閉じる
          </button>
          <button
            type="button"
            class="btn btn-dark"
            v-on:click.prevent="formSubmit"
          >
            <slot name="submitButton">追加</slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { mapGetters, mapActions } from 'vuex'
import { randomString } from '@/utils/stringUtils.js'
import FormSearchSelect from '@/components/common/FormSearchSelect.vue'
import FormNumericInput from '@/components/common/FormNumericInput.vue'

export default {
  name: 'OrderItemForm',
  components: {
    FormSearchSelect,
    FormNumericInput,
  },

  props: {
    client: {
      type: Object,
      required: false,
    },
  },
  emits: ['hiddenModal', 'afterSubmit'],

  data() {
    return {
      modal: null,
      formData: {},
      formErrors: {},
    }
  },
  computed: {
    ...mapGetters({
      itemList: 'item/list',
    }),
    modalId: function () {
      return 'form-modal-' + this.randomString()
    },
  },
  watch: {
    'formData.unit_price_at_order': function (val) {
      this.calcSubtotal()
    },
    'formData.quantity': function (val) {
      this.calcSubtotal()
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.formErrors = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    randomString,
    ...mapActions({
      getItemList: 'item/fetchList',
      getCurrentPrice: 'item_price/fetchCurrentPrice',
    }),
    show(formData = {}) {
      this.formData = Object.assign({}, formData)
      this.modal.show()
    },
    formSubmit() {
      this.formErrors = {}
      if (!this.formData.item) {
        this.formErrors.item_id = '品目を選択してください。'
      }
      if (
        !this.formData.unit_price_at_order &&
        this.formData.unit_price_at_order !== 0
      ) {
        this.formErrors.unit_price_at_order = '単価を入力してください。'
      }
      if (!this.formData.quantity) {
        this.formErrors.quantity = '数量を入力してください。'
      }
      if (Object.keys(this.formErrors).length > 0) {
        return
      }

      this.$emit('afterSubmit', this.formData)
      this.modal.hide()
    },
    // 単価と個数で小計を計算
    calcSubtotal() {
      if (this.formData.unit_price_at_order && this.formData.quantity) {
        this.formData.subtotal =
          this.formData.unit_price_at_order * this.formData.quantity
      } else {
        this.formData.subtotal = 0
      }
    },
    setCurrentUnitPrice() {
      let params = {
        item_id: this.formData.item.id,
        client_id: this.client?.id,
      }
      this.getCurrentPrice(params)
        .then((response) => {
          this.formData.unit_price_at_order = response.data.unit_price
        })
        .catch((error) => {
          this.formData.unit_price_at_order = 0
        })
        .finally(() => {
          this.calcSubtotal()
        })
    },
  },
}
</script>
