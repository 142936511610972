<template>
  <navbar>
    <template v-slot:navbarTitle>品目管理</template>
  </navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="d-flex gap-2 mb-3">
      <h5 class="m-0 p-0"><i class="bi bi-tags me-1"></i>品目分類一覧</h5>
      <div class="ms-auto">
        <button
          type="button"
          v-on:click.prevent="showItemClassificationForm()"
          class="btn btn-primary"
        >
          <span class="d-block d-sm-none"><i class="bi bi-plus-lg"></i></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-plus-lg me-1"></i>品目分類を登録する</span
          >
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-end gap-2 mb-2">
      <pagination :prev="prev" :next="next" @move-page="movePage"></pagination>
    </div>

    <!-- classifications -->
    <div v-if="classifications && classifications.length > 0">
      <data-table :headers="dataHeaders" :items="classifications">
        <template #actions="{ item }">
          <a
            href="#"
            v-on:click.prevent="showItemClassificationForm(item)"
            class="text-success text-decoration-none"
            >編集</a
          >｜<a
            v-on:click.prevent="removeData(item)"
            href="#"
            class="text-danger text-decoration-none"
            >削除</a
          >
        </template>
      </data-table>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>

    <delete-dialog
      ref="confirmDataDelete"
      @confirm-true="confirmDataDelete"
    ></delete-dialog>
    <item-classification-form
      ref="itemClassificationForm"
    ></item-classification-form>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import DeleteDialog from '@/components/AppDeleteDialog.vue'
import DataTable from '@/components/common/DataTable.vue'
import ItemClassificationForm from '@/components/manage/ItemClassificationForm.vue'

export default {
  name: 'ItemClassificationList',
  components: {
    Navbar,
    Pagination,
    DeleteDialog,
    DataTable,
    ItemClassificationForm,
  },

  data() {
    return {
      query: {},
      currentId: null,
      dataHeaders: [
        {
          key: 'name',
          label: '種別名',
          class: 'col-6',
          format: 'string',
        },
        {
          key: 'created_at',
          label: '作成日時',
          class: 'col-2',
          format: 'datetime',
        },
        {
          key: 'updated_at',
          label: '更新日時',
          class: 'col-2',
          format: 'datetime',
        },
        {
          key: 'actions',
          label: '',
          class: 'col-2',
          format: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      classifications: 'item_classification/results',
      prev: 'item_classification/prev',
      next: 'item_classification/next',
      saveQuery: 'item_classification/query',
    }),
  },

  async created() {
    this.query = this.saveQuery
    await this.setQuery(this.query)
    await this.getData()
  },
  beforeRouteLeave(to, from, next) {
    this.clearQuery()
    this.clearData()
    next()
  },

  methods: {
    ...mapActions({
      getData: 'item_classification/fetchAll',
      deleteData: 'item_classification/deleteData',
    }),
    ...mapMutations({
      setQuery: 'item_classification/setQuery',
      clearQuery: 'item_classification/clearQuery',
      clearData: 'item_classification/clearData',
    }),
    async movePage(page) {
      this.query = Object.assign(this.query, { page: page })
      await this.setQuery(this.query)
      await this.getData()
    },
    showItemClassificationForm(formData) {
      this.$refs.itemClassificationForm.show(formData)
    },
    removeData(object) {
      this.currentId = object.id
      this.$refs.confirmDataDelete.showDialog('品目分類データ', object.name)
    },
    async confirmDataDelete() {
      try {
        await this.deleteData(this.currentId)
        await this.clearQuery()
        await this.getData()
      } catch (e) {
        let message = '削除できませんでした。'
        this.$store.dispatch('alert/setErrorMessage', {
          message: message,
        })
      }
      this.currentId = null
    },
  },
}
</script>
