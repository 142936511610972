import ManageOrderHome from './views/Home.vue'
import ManageOrderList from './views/OrderList.vue'
import ManageOrderDetail from './views/OrderDetail.vue'
import ManageOrderCreate from './views/OrderCreate.vue'
import ManageOrderSalesList from './views/OrderSalesList.vue'
import ManageOrderSalesDetail from './views/OrderSalesDetail.vue'

export default {
  routes: [
    {
      path: '/manage/order',
      component: ManageOrderHome,
      name: 'ManageOrderHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'orders',
          component: ManageOrderList,
          name: 'ManageOrderList',
        },
        {
          path: 'orders/:id([0-9]+)',
          component: ManageOrderDetail,
          name: 'ManageOrderDetail',
          props: (route) => ({ id: Number(route.params.id) }),
        },
        {
          path: 'orders/create',
          component: ManageOrderCreate,
          name: 'ManageOrderCreate',
        },
        {
          path: 'orders/sales',
          component: ManageOrderSalesList,
          name: 'ManageOrderSalesList',
        },
        {
          path: 'orders/sales/:id([0-9]+)',
          component: ManageOrderSalesDetail,
          name: 'ManageOrderSalesDetail',
          props: (route) => ({ id: Number(route.params.id) }),
        },
      ],
    },
  ],
}
