<template>
  <Navbar />
  <main id="main" class="container-fluid pt-3">
    <div class="row gx-3 gy-1 gy-lg-2">
      <div class="col-12 col-md-8">
        <div class="row row-cols-1 gx-3 gy-1">
          <div
            v-if="activeApp('inspection') || activeApp('inspection_plan')"
            class="col"
          >
            <MenuInspection />
          </div>
          <div v-if="activeApp('inventory')" class="col">
            <MenuInventory />
          </div>
          <div v-if="activeApp('order')" class="col">
            <MenuOrder />
          </div>
          <div v-if="activeApp('purchase')" class="col">
            <MenuPurchase />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card border-dark mb-3">
          <h5 class="card-header bg-dark text-light">マスタ管理</h5>
          <div class="card-body">
            <div class="row row-cols-1 g-1">
              <div class="col">
                <router-link
                  :to="{
                    name: 'ItemClassificationList',
                  }"
                  class="btn btn-lg btn-outline-dark w-100 py-3"
                  >品目分類</router-link
                >
              </div>
              <div class="col">
                <router-link
                  :to="{
                    name: 'ItemList',
                  }"
                  class="btn btn-lg btn-outline-dark w-100 py-3"
                  >品目</router-link
                >
              </div>
              <div
                v-if="activeApp('item_price') || activeApp('order')"
                class="col"
              >
                <router-link
                  :to="{
                    name: 'ItemPriceList',
                  }"
                  class="btn btn-lg btn-outline-dark w-100 py-3"
                  >単価</router-link
                >
              </div>
              <div
                v-if="activeApp('item_price') || activeApp('order')"
                class="col"
              >
                <router-link
                  :to="{
                    name: 'ClientList',
                  }"
                  class="btn btn-lg btn-outline-dark w-100 py-3"
                  >顧客</router-link
                >
              </div>
              <div v-if="activeApp('purchase')" class="col">
                <router-link
                  :to="{
                    name: 'SupplierList',
                  }"
                  class="btn btn-lg btn-outline-dark w-100 py-3"
                  >仕入先</router-link
                >
              </div>
              <div class="col">
                <router-link
                  :to="{
                    name: 'UserList',
                  }"
                  class="btn btn-lg btn-outline-dark w-100 py-3"
                  >ユーザー</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <router-link
          :to="{
            name: 'ExportDataList',
          }"
          class="btn btn-lg btn-secondary w-100 py-3"
          >エクスポート管理</router-link
        >
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import MenuInspection from '@/apps/inspection/manage/components/InspectionMenu.vue'
import MenuOrder from '@/apps/order/manage/components/OrderMenu.vue'
import MenuInventory from '@/apps/inventory/manage/components/InventoryMenu.vue'
import MenuPurchase from '@/apps/purchase/manage/components/PurchaseMenu.vue'

export default {
  name: 'AppHomeManage',
  components: {
    Navbar,
    MenuInspection,
    MenuInventory,
    MenuOrder,
    MenuPurchase,
  },

  computed: {
    ...mapGetters({
      activeApp: 'auth/activeApp',
    }),
  },
}
</script>
