import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
  query: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  users: (state) => state.data.results,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  query: (state) => state.query,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  updateData: (state, data) => {
    const index = state.data.results.findIndex(
      (result) => result.id === data.id
    )
    if (index !== -1) {
      state.data.results[index] = data
    }
  },
  clearData: (state) => (state.data = {}),
  setQuery: (state, query) => (state.query = query),
  clearQuery: (state) => (state.query = {}),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearQuery')
      resolve()
    })
  },
  /**
   * データ一覧を取得する
   *
   * @return {void}
   */
  async fetchAll({ commit, state }) {
    const response = await api.get('dashboard/users/', {
      params: state.query,
    })
    commit('setData', response.data)
  },
  /**
   * データを追加または更新する
   *
   * @param {object}
   * @return {object}
   */
  async postOrPatch({ commit, dispatch }, formData) {
    try {
      // 必要なデータのみにする
      const requiredKeys = [
        'id',
        'username',
        'password',
        'last_name',
        'first_name',
        'group_id',
        'is_active',
      ]
      const submitData = Object.fromEntries(
        Object.entries(formData).filter(([key]) => requiredKeys.includes(key))
      )

      let response
      if ('id' in submitData && submitData['id']) {
        response = await dispatch('patchData', submitData)
        commit('updateData', response)
      } else {
        response = await dispatch('postData', submitData)
        await commit('clearQuery')
        await dispatch('fetchAll')
      }
      return response
    } catch (error) {
      console.error('Failed to post or patch')
      throw error
    }
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {object}
   */
  async postData(_, formData) {
    try {
      const response = await api.post('dashboard/users/', formData)
      return response.data
    } catch (error) {
      console.error('Failed to post data:', error)
      throw error
    }
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {object}
   */
  async patchData(_, formData) {
    try {
      const response = await api.patch(
        'dashboard/users/' + formData.id + '/',
        formData
      )
      return response.data
    } catch (error) {
      console.error('Failed to patch data:', error)
      throw error
    }
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {number}
   */
  async deleteData({ commit, dispatch }, id) {
    try {
      await api.delete('dashboard/users/' + id + '/')
      await commit('clearQuery')
      await dispatch('fetchAll')
      return id
    } catch (error) {
      console.error('Failed to delete data:', error)
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
