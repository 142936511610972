<template>
  <Navbar>
    <template v-slot:navbarTitle>製品検査</template>
  </Navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="clearfix mb-3">
      <div class="float-end">
        <router-link
          :to="{
            name: 'ManageInspectionPlan',
          }"
          class="btn btn-outline-dark"
        >
          <span class="d-block d-sm-none"
            ><i class="bi bi-arrow-left"></i
          ></span>
          <span class="d-none d-sm-block">一覧に戻る</span>
        </router-link>
      </div>
      <h5><i class="bi bi-clipboard-data me-1"></i>検査指示詳細</h5>
    </div>

    <h5>
      検査指示情報
      <a
        class="fs-6"
        data-bs-toggle="collapse"
        href="#resultData"
        role="button"
        aria-expanded="true"
        aria-controls="resultData"
        ><i class="bi bi-arrows-collapse"></i>
      </a>
    </h5>

    <div class="collapse show" id="resultData">
      <table class="table table-bordered table-row bg-white mb-3">
        <tbody>
          <tr>
            <th class="col-sm-4 bg-light">得意先名</th>
            <td class="col-sm-8">{{ result.client_name }}</td>
          </tr>
          <tr>
            <th class="bg-light">受注番号</th>
            <td>{{ result.order_number }}</td>
          </tr>
          <tr>
            <th class="bg-light">指示番号</th>
            <td>{{ result.inspection_plan_number }}</td>
          </tr>
          <tr>
            <th class="bg-light">納期</th>
            <td>{{ result.order_deadline }}</td>
          </tr>
          <tr>
            <th class="bg-light">型式</th>
            <td>{{ result.product_model }}</td>
          </tr>
          <tr>
            <th class="bg-light">機種コード</th>
            <td>{{ result.product_code }}</td>
          </tr>
          <tr>
            <th class="bg-light">客先製品コード</th>
            <td>{{ result.client_product_code }}</td>
          </tr>
          <tr>
            <th class="bg-light">指示数量</th>
            <td>{{ result.number_unit }}</td>
          </tr>
          <tr>
            <th class="bg-light">指示残数</th>
            <td>{{ result.number_remaining }}</td>
          </tr>
          <tr>
            <th class="bg-light">取込日時</th>
            <td>{{ result.created_at }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr />

    <!-- results -->
    <h5>
      検査結果
      <span class="small"
        >（該当件数: {{ this.plan_results?.length || 0 }}）</span
      >
    </h5>
    <!-- 検査種別の数だけ用意 -->
    <ul class="nav nav-tabs mb-2">
      <li class="nav-item">
        <button
          type="button"
          class="nav-link tab-item active"
          @click="dataFilter($event)"
        >
          すべて
        </button>
      </li>
      <li
        v-for="inspection_type in inspection_types"
        :key="inspection_type.id"
        class="nav-item"
      >
        <button
          type="button"
          class="nav-link tab-item"
          @click="dataFilter($event, inspection_type.name)"
        >
          {{ inspection_type.name }}
        </button>
      </li>
    </ul>

    <div v-if="plan_results && plan_results.length > 0">
      <div class="table-responsive">
        <table class="table table-bordered bg-white">
          <thead>
            <tr>
              <th scope="col" class="col-sm-2">検査番号</th>
              <th scope="col" class="col-sm-2">検査種別</th>
              <th scope="col" class="col-sm-4">検査シート</th>
              <th scope="col" class="col-sm-2">検査員</th>
              <th scope="col" class="col-sm-2">検査開始日時</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="plan_result in plan_results" :key="plan_result.id">
              <td scope="row" class="text-center">
                <router-link
                  :to="{
                    name: 'ManageInspectionPlanResultDetail',
                    params: { plan_id: result.id, result_id: plan_result.id },
                  }"
                  >{{ plan_result.inspection_result_number }}</router-link
                >
              </td>
              <td class="text-center">
                {{ plan_result.inspection_type_name }}
              </td>
              <td>{{ plan_result.inspection_name }}</td>
              <td class="text-center">{{ plan_result.worker }}</td>
              <td class="text-end">{{ plan_result.begin_datetime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>
  </main>
</template>

<script>
import store from '../store'
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'ManageInspectionPlanDetail',
  components: {
    Navbar,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      plan_results: [],
    }
  },

  computed: {
    inspection_types() {
      return store.getters['inspection_type/list']
    },
    result() {
      return store.getters['inspection_plan/detail']
    },
  },

  async created() {
    await this.fetchInspectionTypeList()
    await this.getData(this.id).catch((error) => {
      if (error.status) {
        window.setTimeout(() => {
          this.$router.push({
            name: 'ManageInspectionPlan',
          })
        }, this.$store.getters['alert/timeout'])
      }
    })
    this.plan_results = this.result?.results || []
  },

  methods: {
    fetchInspectionTypeList() {
      return store.dispatch('inspection_type/fetchList')
    },
    getData(id) {
      return store.dispatch('inspection_plan/fetchData', id)
    },
    dataFilter(event, key) {
      const tabItem = document.querySelectorAll('.tab-item')
      for (let i = 0; i < tabItem.length; i++) {
        tabItem[i].classList.remove('active')
      }
      event.target.classList.toggle('active')

      if (key && this.result?.results) {
        this.plan_results = this.result.results.filter(
          (result) => result.inspection_type_name === key
        )
      } else {
        this.plan_results = this.result.results
      }
    },
  },
}
</script>
