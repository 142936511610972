<template>
  <NavbarForm @submit="formSubmit" @cancel="formCancel">
    <template v-slot:formTitle>受注登録</template>
  </NavbarForm>

  <main id="main" class="container-fluid py-3">
    <div class="card card-body bg-light mb-3">
      <form>
        <div class="row gy-1">
          <label for="form_client" class="col-sm-3 col-form-label"
            >顧客<span class="badge text-bg-danger ms-2 px-1">必須</span></label
          >
          <div class="col-sm-9">
            <FormSearchSelect
              v-model="formData.client"
              :options="clientList"
              :error="formErrors.client_id"
              id="form_client"
              placeholder="顧客コード・顧客名で検索"
              @getOptions="getClientList"
            />
          </div>
          <label for="form_number" class="col-sm-3 col-form-label"
            >受注番号<span class="badge text-bg-danger ms-2 px-1"
              >必須</span
            ></label
          >
          <div class="col-sm-9">
            <input
              v-model="formData.number"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': formErrors?.number,
              }"
              id="form_number"
            />
            <div v-if="formErrors?.number" class="invalid-feedback">
              {{ formErrors.number }}
            </div>
          </div>
          <label for="form_ordered_at" class="col-sm-3 col-form-label"
            >受注日時</label
          >
          <div class="col-sm-9">
            <input
              v-model="formData.ordered_at"
              type="datetime-local"
              class="form-control"
              :class="{
                'is-invalid': formErrors?.ordered_at,
              }"
              id="form_ordered_at"
            />
            <div v-if="formErrors?.ordered_at" class="invalid-feedback">
              {{ formErrors.ordered_at }}
            </div>
          </div>
          <label for="form_memo" class="col-sm-3 col-form-label">備考</label>
          <div class="col-sm-9">
            <input
              v-model="formData.memo"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': formErrors?.memo,
              }"
              id="form_memo"
            />
            <div v-if="formErrors?.memo" class="invalid-feedback">
              {{ formErrors.memo }}
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="clearfix">
      <div class="d-flex justify-content-end">
        <a href="#" class="link-primary" @click="showOrderItemForm()"
          ><i class="bi bi-plus-circle me-1"></i>品目を追加する</a
        >
      </div>
    </div>

    <div
      v-if="formData.order_items && formData.order_items.length > 0"
      class="row row-cols-1 g-2 g-lg-3 mt-3"
    >
      <div v-for="item in formData.order_items" :key="item.id" class="col">
        <div class="card h-100">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <h5 class="card-title m-0">
                {{ item.item?.name }}
              </h5>
              <a href="#" class="link-danger" @click="deleteItem(index)"
                ><i class="bi bi-trash3 link-danger ms-3"></i
              ></a>
            </div>
            <h6 class="card-subtitle text-muted m-0">
              【{{ item.item?.code }}】{{ item.item?.item_model }}
            </h6>
            <p>{{ item.memo }}</p>
            <div class="d-flex justify-content-between">
              <div>
                <span class="small text-muted d-block">単価 * 数量</span
                >{{ item.unit_price_at_order }} * {{ item.quantity }}
              </div>
              <div class="text-end">
                <span class="small text-muted d-block">小計</span
                >{{ toCommaSeparatedNumber(item.subtotal) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mt-3">
      <p>品目を追加してください。</p>
      <div v-if="formErrors?.order_items">
        <div class="text-danger">品目は必ず1つ以上追加してください。</div>
      </div>
    </div>

    <OrderItemForm
      ref="orderItemForm"
      @afterSubmit="insertItem"
      :client="formData.client"
    />
  </main>
</template>

<script>
import store from '../store'
import { mapGetters, mapActions } from 'vuex'
import { toCommaSeparatedNumber } from '@/utils/stringUtils'
import FormSearchSelect from '@/components/common/FormSearchSelect.vue'
import NavbarForm from '@/components/NavbarForm.vue'
import OrderItemForm from '../components/OrderItemForm.vue'

export default {
  name: 'OperateOrderCreate',
  inject: ['dayjs'],
  components: {
    FormSearchSelect,
    NavbarForm,
    OrderItemForm,
  },

  data() {
    return {
      formData: {
        client: null,
        order_number: '',
        ordered_at: this.dayjs().format('YYYY-MM-DDTHH:mm'),
        memo: '',
        order_items: [],
      },
      formErrors: {},
    }
  },
  computed: {
    ...mapGetters({
      clientList: 'client/list',
    }),
  },

  methods: {
    toCommaSeparatedNumber,
    ...mapActions({
      getClientList: 'client/fetchList',
    }),
    postOrPatch(formData) {
      return store.dispatch('operate_order/postOrPatch', formData)
    },
    async formSubmit() {
      try {
        this.formErrors = {}
        if (!this.formData.client) {
          this.formErrors.client_id = '顧客を選択してください。'
        }
        if (!this.formData.number) {
          this.formErrors.number = '受注番号を入力してください。'
        }
        if (this.formData.order_items.length == 0) {
          this.formErrors.order_items = '品目は必ず1つ以上追加してください。'
        }
        if (Object.keys(this.formErrors).length > 0) {
          return
        }

        await this.postOrPatch(this.formData)
        this.$store.dispatch('alert/setSuccessMessage', {
          message: '保存しました。',
        })
        window.setTimeout(async () => {
          await this.$router.push({
            name: 'OperateOrderList',
          })
        }, this.$store.getters['alert/timeout'])
      } catch (e) {
        if (e.response?.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            this.setErrorMessage(key, e.response.data[key])
          })
        }
      }
    },
    formCancel() {
      this.$router.push({
        name: 'OperateOrderList',
      })
    },
    setErrorMessage(key, errorData) {
      if (Array.isArray(errorData)) {
        this.formErrors[key] = errorData.join('\n')
      } else {
        this.formErrors[key] = errorData
      }
    },
    showOrderItemForm() {
      this.$refs.orderItemForm.show()
    },
    insertItem(item) {
      if (!this.formData.order_items) {
        this.formData.order_items = []
      }
      this.formData.order_items.push(item)
    },
    deleteItem(index) {
      this.formData.order_items.splice(index, 1)
    },
  },
}
</script>
