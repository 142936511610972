<template>
    <div class="d-none d-sm-block">
      <div class="input-group">
        <!-- QR Code Button -->
        <button
          v-if="useCodeReader"
          type="button"
          class="btn btn-outline-secondary"
          v-on:click.prevent="startCamera()"
        >
          <i class="bi bi-qr-code"></i>
        </button>
        <!-- Input Field -->
        <input
          v-model="localQuery[queryKey]"
          type="text"
          class="form-control border-secondary border-end-0"
          :placeholder="placeholder"
          :id="'search_form_' + queryKey"
        />
        <!-- Reset Button -->
        <button
          v-on:click.prevent="resetQuery()"
          class="btn bg-white text-secondary border-secondary border-start-0"
          type="button"
        >
          <slot name="resetButton">
            <i class="bi bi-x"></i>
          </slot>
        </button>
        <!-- Search Button -->
        <button
          v-on:click.prevent="searchQuery()"
          class="btn btn-outline-secondary"
          type="button"
        >
          <slot name="searchButton">
            <i class="bi bi-search"></i>
          </slot>
        </button>
        <!-- Filter Button -->
        <button
          v-if="useSearchModal"
          v-on:click.prevent="openModal()"
          class="btn btn-outline-secondary"
          :class="{ 'btn-dark text-light': isSearch }"
          type="button"
        >
          <i class="bi bi-filter"></i>
        </button>
      </div>
    </div>
    <div v-if="useSearchModal" class="d-block d-sm-none">
      <button
        v-on:click.prevent="openModal()"
        class="btn btn-outline-secondary"
        :class="{ 'btn-dark text-light': isSearch }"
        type="button"
      >
        <i class="bi bi-filter"></i>
      </button>
    </div>
</template>

<script>
export default {
  name: 'SearchForm',

  props: {
    query: {
      type: Object,
      default: () => ({}),
    },
    queryKey: {
      type: String,
      default: 'code',
    },
    placeholder: {
      type: String,
      default: 'コードで検索',
    },
    useCodeReader: {
      type: Boolean,
      default: false,
    },
    useSearchModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateSearchQuery', 'startCodeCamera', 'openSearchModal'],

  data() {
    return {
      localQuery: {},
    }
  },
  computed: {
    isSearch: function () {
      let searchQuery = Object.assign({}, this.localQuery)
      delete searchQuery['page']
      return !!Object.keys(searchQuery).length
    },
  },

  created() {
    this.localQuery = Object.assign({}, this.query)
  },
  watch: {
    query: {
      handler(newValue) {
        this.localQuery = Object.assign({}, newValue)
      },
      deep: true,
    },
  },

  methods: {
    resetQuery() {
      this.localQuery = {}
      this.$emit('updateSearchQuery', this.localQuery)
    },
    searchQuery() {
      const newQuery = { ...this.localQuery, page: 1 }
      this.$emit('updateSearchQuery', newQuery)
    },
    startCamera() {
      this.$emit('startCodeCamera')
    },
    openModal() {
      this.$emit('openSearchModal')
    },
  },
}
</script>
