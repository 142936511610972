<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modalTitle">在庫更新</slot>
          </h5>
        </div>
        <div class="modal-body">
          <div class="row g-3">
            <div class="col-12">
              <label :for="modalId + '_form_stock_location'" class="form-label"
                >保管場所<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <FormSearchSelect
                v-model="formData.stock_location"
                :options="locationList"
                :error="formErrors.stock_location_id"
                :id="modalId + '_form_stock_location'"
                placeholder="保管場所名で検索"
                @getOptions="getLocationList"
              />
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_quantity'" class="form-label"
                >数量<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <FormNumericInput
                v-model="formData.quantity"
                :error="formErrors.quantity"
                :id="modalId + '_form_quantity'"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            閉じる
          </button>
          <button
            type="button"
            class="btn btn-dark"
            v-on:click.prevent="formSubmit()"
          >
            <slot name="submitButton">保存</slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/apps/inventory/operate/store'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { randomString } from '@/utils/stringUtils.js'
import FormSearchSelect from '@/components/common/FormSearchSelect.vue'
import FormNumericInput from '@/components/common/FormNumericInput.vue'

export default {
  name: 'OrderStockFlowForm',
  components: {
    FormSearchSelect,
    FormNumericInput,
  },

  emits: ['hiddenModal', 'afterSubmit'],
  props: {
    submitMethod: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      modal: null,
      formData: {
        stock_location: null,
        quantity: '',
        reason: '受注操作による在庫更新',
      },
      formErrors: {},
    }
  },
  computed: {
    locationList() {
      return store.getters['operate_stock_location/list']
    },
    modalId: function () {
      return 'form-modal-' + this.randomString()
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.formData = {
          stock_location: null,
          quantity: '',
          reason: '受注操作による在庫更新',
        }
        this.formErrors = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    randomString,
    async getLocationList(query = {}) {
      return await store.dispatch('operate_stock_location/fetchList', query)
    },
    incoming(formData) {
      return store.dispatch('operate_stock/incoming', formData)
    },
    outgoing(formData) {
      return store.dispatch('operate_stock/outgoing', formData)
    },
    show(stock_id) {
      store.commit('operate_stock/setDetail', { id: stock_id })
      this.modal.show()
    },
    async formSubmit() {
      this.formErrors = {}
      if (!this.formData.stock_location) {
        this.formErrors.stock_location_id = '保管場所を選択してください。'
      }
      if (this.formData.quantity.length === 0) {
        this.formErrors.quantity = '数量を入力してください。'
      }
      if (Object.keys(this.formErrors).length > 0) {
        return
      }
      try {
        if (typeof this[this.submitMethod] === 'function') {
          await this[this.submitMethod](this.formData)
          this.$store.dispatch('alert/setSuccessMessage', {
            message: '保存しました。',
          })
          this.$emit('afterSubmit', this.formData)
          this.modal.hide()
        } else {
          console.error('未知のメソッドまたは関数ではありません')
        }
      } catch (e) {
        if (e.response.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            this.setErrorMessage(key, e.response.data[key])
          })
        }
      }
    },
    setErrorMessage(key, errorData) {
      if (Array.isArray(errorData)) {
        this.formErrors[key] = errorData.join('\n')
      } else {
        this.formErrors[key] = errorData
      }
    },
  },
}
</script>
