<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modalTitle">在庫更新</slot>
          </h5>
        </div>
        <div class="modal-body">
          <div class="row g-3">
            <div v-if="!formData?.id" class="col-12">
              <label
                :for="modalId + '_form_stock_location_id'"
                class="form-label"
                >保管場所<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <select
                v-model="formData.stock_location_id"
                class="form-select"
                :class="{ 'is-invalid': formErrors?.stock_location_id }"
                :id="modalId + '_form_stock_location_id'"
              >
                <option :value="undefined">---</option>
                <option
                  v-for="location in locationList"
                  :key="location.id"
                  :value="location.id"
                >
                  {{ location.name }}
                </option>
              </select>
              <div
                v-if="formErrors?.stock_location_id"
                class="invalid-feedback"
              >
                {{ formErrors.stock_location_id }}
              </div>
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_quantity'" class="form-label"
                >数量<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <FormNumericInput
                v-model="formData.quantity"
                :error="formErrors.quantity"
                :id="modalId + '_form_quantity'"
              />
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_reason'" class="form-label"
                >メモ</label
              >
              <input
                v-model="formData.reason"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.reason }"
                :id="modalId + '_form_reason'"
              />
              <div v-if="formErrors?.reason" class="invalid-feedback">
                {{ formErrors.reason }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            閉じる
          </button>
          <button
            type="button"
            class="btn btn-dark"
            v-on:click.prevent="formSubmit()"
          >
            <slot name="submitButton">保存</slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { randomString } from '@/utils/stringUtils.js'
import FormNumericInput from '@/components/common/FormNumericInput.vue'

export default {
  name: 'StockFlowForm',
  components: {
    FormNumericInput,
  },

  emits: ['hiddenModal', 'afterSubmit'],
  props: {
    submitMethod: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      modal: null,
      formData: {},
      formErrors: {},
    }
  },
  computed: {
    locationList() {
      return store.getters['manage_stock_location/list']
    },
    modalId: function () {
      return 'form-modal-' + this.randomString()
    },
  },

  async created() {
    await this.getLocationList()
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.formErrors = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    randomString,
    getLocationList() {
      return store.dispatch('manage_stock_location/fetchList')
    },
    incoming(formData) {
      return store.dispatch('manage_stock/incoming', formData)
    },
    outgoing(formData) {
      return store.dispatch('manage_stock/outgoing', formData)
    },
    show(formData = {}) {
      this.formData = Object.assign({}, formData)
      this.modal.show()
    },
    async formSubmit() {
      try {
        this.formErrors = {}
        if (typeof this[this.submitMethod] === 'function') {
          await this[this.submitMethod](this.formData)
        } else {
          console.error('未知のメソッドまたは関数ではありません')
        }

        this.$store.dispatch('alert/setSuccessMessage', {
          message: '保存しました。',
        })
        await this.$emit('afterSubmit', this.formData)
        await this.modal.hide()
      } catch (e) {
        if (e.response.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            this.setErrorMessage(key, e.response.data[key])
          })
        }
      }
    },
    setErrorMessage(key, errorData) {
      if (Array.isArray(errorData)) {
        this.formErrors[key] = errorData.join('\n')
      } else {
        this.formErrors[key] = errorData
      }
    },
  },
}
</script>
