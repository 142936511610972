<template>
  <NavbarForm @submit="formSubmit" @cancel="formCancel">
    <template v-slot:formTitle>検査シート作成</template>
  </NavbarForm>
  <main id="main" class="container-fluid pt-3">
    <div class="card card-body bg-light mb-3">
      <form>
        <div class="row">
          <label for="inspectionName" class="col-sm-3 col-form-label"
            >シート名</label
          >
          <div class="col-sm-9">
            <input
              v-model="inspection.name"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v$.inspection.name.$error }"
              id="inspectionName"
            />
          </div>
        </div>
      </form>
    </div>

    <div class="clearfix">
      <div class="float-end">
        <span class="link-primary" @click="createItem"
          ><i class="bi bi-plus-circle me-1"></i>項目を追加する</span
        >
      </div>
      <h5>検査項目</h5>
    </div>
    <div v-if="inspection.items && inspection.items.length > 0">
      <div
        class="card mb-2"
        v-for="(item, index) in inspection.items"
        :key="index"
      >
        <div class="card-header">
          <i
            class="bi bi-caret-up-fill me-1 link-primary"
            @click="upItem(index)"
          ></i>
          <i
            class="bi bi-caret-down-fill me-1 link-primary"
            @click="downItem(index)"
          ></i>
          【{{ typeName(item.type) }}】 {{ item.title }}
          <div class="float-end">
            <i
              class="bi bi-pencil link-success"
              @click="editItem(item, index)"
            ></i>
            <i
              class="bi bi-eye link-primary ms-3"
              @click="previewItem(item)"
            ></i>
            <i
              class="bi bi-trash3 link-danger ms-3"
              @click="deleteItem(index)"
            ></i>
          </div>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <div class="row">
              <div class="col-3 fw-bold">説明文</div>
              <div class="col-9">{{ item.description }}</div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col-3 fw-bold">注意事項</div>
              <div class="col-9">{{ item.attention }}</div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col-3 fw-bold">参考画像</div>
              <div class="col-9">
                <i v-if="item.image" class="bi bi-circle"></i
                ><i v-else class="bi bi-x"></i>
              </div>
            </div>
          </li>
          <li v-if="item.type == 'select'" class="list-group-item">
            <div class="row">
              <div class="col-3 fw-bold">選択肢（OK）</div>
              <div class="col-9">
                {{ item.success_options.join() }}
              </div>
            </div>
          </li>
          <li v-if="item.type == 'select'" class="list-group-item">
            <div class="row">
              <div class="col-3 fw-bold">選択肢（NG）</div>
              <div class="col-9">
                {{ item.failure_options.join() }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="mt-3">
      <p>項目を追加してください。</p>
      <div v-if="v$.inspection.items.$errors.length > 0">
        <div class="text-danger">項目は必ず1つ以上追加してください。</div>
      </div>
    </div>

    <InspectionItemForm
      ref="itemForm"
      @insertItem="insertItem"
      @updateItem="updateItem"
      :item_types="item_types"
      :is_edit="is_item_edit"
    />
    <InspectionItemPreview ref="itemPreview" />
  </main>
</template>

<script>
import store from '../store'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import NavbarForm from '@/components/NavbarForm.vue'
import InspectionItemForm from '../components/InspectionItemForm.vue'
import InspectionItemPreview from '../components/InspectionItemPreview.vue'

export default {
  name: 'ManageInspectionDuplicate',
  components: {
    NavbarForm,
    InspectionItemForm,
    InspectionItemPreview,
  },

  setup: () => ({
    v$: useVuelidate({ $stopPropagation: true, $scope: false }),
  }),

  data() {
    return {
      inspection: {},
      is_item_edit: false,
    }
  },
  computed: {
    item_types() {
      return store.getters['inspection_master/inspection_item_types']
    },
  },
  validations() {
    return {
      inspection: {
        name: { required },
        items: { minLength: (value) => value.length >= 1 },
      },
    }
  },

  async created() {
    await this.getInspectionItemTypes()
    this.getDuplicateData()
      .then((data) => {
        this.inspection = data
      })
      .catch(() => {
        this.$router.push({
          name: 'ManageInspection',
        })
      })
  },

  methods: {
    getInspectionItemTypes() {
      store.dispatch('inspection_master/fetchInspectionItemTypeList')
    },
    getDuplicateData() {
      return store.dispatch(
        'inspection/getDuplicateData',
        this.$route.params.id
      )
    },
    createItem() {
      this.is_item_edit = false
      this.$refs.itemForm.show(
        {
          type: '',
          title: '',
          description: '',
          image: null,
          attention: '',
          success_options: [],
          failure_options: [],
        },
        false
      )
    },
    insertItem(item) {
      if (!('items' in this.inspection)) {
        this.inspection.items = []
      }
      this.inspection.items.push(item)
    },
    editItem(item, index) {
      this.is_item_edit = true
      this.$refs.itemForm.show(item, index)
    },
    updateItem(item, index) {
      this.inspection.items.splice(index, 1, item)
    },
    previewItem(item) {
      this.$refs.itemPreview.show(item)
    },
    deleteItem(index) {
      this.inspection.items.splice(index, 1)
    },
    upItem(index) {
      if (Number.isInteger(index) && index > 0) {
        let newIndex = index - 1
        this.inspection.items.splice(
          newIndex,
          2,
          this.inspection.items[index],
          this.inspection.items[newIndex]
        )
      }
    },
    downItem(index) {
      if (Number.isInteger(index) && index < this.inspection.items.length - 1) {
        let newIndex = index + 1
        this.inspection.items.splice(
          index,
          2,
          this.inspection.items[newIndex],
          this.inspection.items[index]
        )
      }
    },
    async formSubmit() {
      const result = await this.v$.$validate()
      if (!result) {
        this.$store.dispatch('alert/setErrorMessage', {
          message: 'フォームの入力内容を確認してください。',
        })
        return
      }
      await store
        .dispatch('inspection/postOrPatch', this.inspection)
        .then(async (response) => {
          this.$store.dispatch('alert/setSuccessMessage', {
            message: '保存しました。',
          })
          window.setTimeout(async () => {
            await this.$router.push({
              name: 'ManageInspectionDetail',
              params: { id: response.id },
            })
          }, this.$store.getters['alert/timeout'])
        })
    },
    formCancel() {
      this.$router.push({
        name: 'ManageInspection',
      })
    },
    typeName(type) {
      const item_type = this.item_types.find((t) => t.value === type)
      if (typeof item_type != 'undefined') {
        return item_type.name
      }
    },
  },
}
</script>
