<template>
  <div class="modal fade" ref="searchModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">詳細検索</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row g-3">
            <div v-for="item in items" :key="item.id" class="col-12">
              <div v-if="['checkbox'].includes(item.type)">
                <div class="form-check form-check-inline">
                  <input
                    v-if="item.type === 'checkbox'"
                    v-model="localQuery[item.id]"
                    class="form-check-input"
                    type="checkbox"
                    :id="'search_modal_' + item.id"
                  />
                  <label
                    :for="'search_modal_' + item.id"
                    class="form-check-label"
                    >{{ item.label }}</label
                  >
                </div>
              </div>
              <div v-else>
                <label :for="'search_modal_' + item.id" class="form-label">{{
                  item.label
                }}</label>
                <input
                  v-if="item.type === 'text'"
                  v-model="localQuery[item.id]"
                  type="text"
                  class="form-control"
                  :id="'search_modal_' + item.id"
                />
                <select
                  v-if="item.type === 'select'"
                  v-model="localQuery[item.id]"
                  class="form-select"
                  :id="'search_modal_' + item.id"
                >
                  <option :value="undefined">---</option>
                  <option
                    v-for="(option, index) in item.options"
                    :key="index"
                    :value="option.value"
                  >
                    {{ option.name }}
                  </option>
                </select>
                <div v-if="item.type === 'date'" class="row g-0">
                  <div class="col-5">
                    <input
                      type="date"
                      v-model="localQuery[item.id + '_from']"
                      class="form-control"
                      :id="'search_modal_' + item.id"
                    />
                  </div>
                  <div class="col-2">
                    <span class="text-center form-control-plaintext">〜</span>
                  </div>
                  <div class="col-5">
                    <input
                      type="date"
                      v-model="localQuery[item.id + '_to']"
                      class="form-control"
                      :id="'search_modal_' + item.id + '_to'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            v-on:click.prevent="resetQuery()"
          >
            リセット
          </button>
          <button
            type="button"
            class="btn btn-dark"
            data-bs-dismiss="modal"
            v-on:click.prevent="searchQuery()"
          >
            検索
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'

export default {
  name: 'SearchModal',

  props: {
    query: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      required: true,
    },
  },
  emits: ['updateSearchQuery'],

  data() {
    return {
      localQuery: {},
    }
  },

  created() {
    this.localQuery = Object.assign({}, this.query)
  },
  watch: {
    query: {
      handler(newValue) {
        this.localQuery = Object.assign({}, newValue)
      },
      deep: true,
    },
  },

  methods: {
    show() {
      const modal = new Modal(this.$refs.searchModal)
      modal.show()
    },
    resetQuery() {
      this.localQuery = {}
      this.$emit('updateSearchQuery', this.localQuery)
    },
    searchQuery() {
      const { page, ...rest } = this.localQuery
      const newQuery = { ...rest }
      this.$emit('updateSearchQuery', newQuery)
    },
  },
}
</script>
