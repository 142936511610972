import UserHome from '@/views/user/Home.vue'
import UserList from '@/views/user/UserList.vue'

export default {
  routes: [
    {
      path: '/user',
      component: UserHome,
      name: 'UserHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'users',
          component: UserList,
          name: 'UserList',
        },
      ],
    },
  ],
}
