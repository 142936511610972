<template>
  <!-- Modal -->
  <div class="modal fade" id="previewModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="ratio ratio-4x3">
        <div v-if="inspection_item" class="modal-content">
          <div class="modal-header bg-light">
            <h5 class="modal-title">プレビュー</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">{{ inspection_item.title }}</h5>
                <hr />
                <p>{{ inspection_item.description }}</p>
                <div
                  v-if="inspection_item.attention"
                  class="border border-danger text-danger text-center p-1 mb-2"
                >
                  {{ inspection_item.attention }}
                </div>
                <div class="row gy-4">
                  <div class="col-lg-6">
                    <div class="ratio ratio-16x9">
                      <img
                        v-if="inspection_item.image"
                        :src="inspection_item.image"
                        class="img-fluid border"
                        :alt="inspection_item.title"
                      />
                      <img
                        v-else
                        src="@/assets/no_image.svg"
                        class="img-fluid bg-secondary"
                        :alt="inspection_item.title"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <InspectionCamera v-if="inspection_item.type == 'camera'" />
                    <InspectionConfirm
                      v-if="inspection_item.type == 'confirm'"
                    />
                    <InspectionInput v-if="inspection_item.type == 'input'" />
                    <InspectionSelect
                      v-if="inspection_item.type == 'select'"
                      :inspection_item="inspection_item"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import InspectionCamera from './InspectionCamera.vue'
import InspectionConfirm from './InspectionConfirm.vue'
import InspectionInput from './InspectionInput.vue'
import InspectionSelect from './InspectionSelect.vue'

export default {
  name: 'InspectionItemPreview',
  components: {
    InspectionCamera,
    InspectionConfirm,
    InspectionInput,
    InspectionSelect,
  },

  data() {
    return {
      modal: null,
      inspection_item: {},
    }
  },

  mounted() {
    this.modal = new Modal(document.getElementById('previewModal'))
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    show(item) {
      this.inspection_item = item
      this.modal.show()
    },
  },
}
</script>
