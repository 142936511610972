<template>
  <div>rakuwork</div>
</template>

<script>
export default {
  name: 'AppHome',
  components: {},
}
</script>
