<template>
  <nav class="navbar navbar-expand navbar-dark bg-dark">
    <div class="container-fluid">
      <h5 class="navbar-brand mb-0">
        <i class="bi bi-cloud-upload me-1"></i>仕入先データインポート
      </h5>
      <div class="d-flex">
        <div v-if="hasData">
          <button
            type="button"
            class="btn btn-primary me-2"
            v-on:click.prevent="formSubmit()"
          >
            インポート
          </button>
          <button
            type="button"
            class="btn btn-dark border-light me-2"
            v-on:click.prevent="formReset()"
          >
            リセット
          </button>
        </div>
        <router-link
          :to="{
            name: 'SupplierList',
          }"
          class="btn btn-secondary"
          >キャンセル</router-link
        >
      </div>
    </div>
  </nav>

  <main id="main" class="container-fluid pt-3">
    <div class="row g-2">
      <div class="col-8 col-lg-3">
        <input
          type="file"
          @change="handleFileChange"
          ref="fileInput"
          class="form-control"
        />
      </div>
      <div class="col-4 col-lg-1">
        <select
          v-model="selectedEncoding"
          @change="previewFile"
          class="form-select"
        >
          <option value="utf-8">UTF-8</option>
          <option value="sjis">Shift_JIS</option>
        </select>
      </div>
    </div>

    <div
      v-if="errorMessage"
      class="alert alert-danger mt-3"
      v-html="replaceNewlinesWithBr(errorMessage)"
    ></div>
    <div v-else-if="hasData" class="alert alert-info mt-3">
      仕入先コードが同じ場合は上書きされます
    </div>
    <div v-else class="alert alert-info mt-3">
      <i class="bi bi-info-circle-fill me-1"></i>CSVファイルを選択してください
    </div>

    <div class="row g-3">
      <div class="col-lg-3">
        <div v-if="hasData">
          <h5>列指定</h5>
          <div class="card card-body">
            <div
              v-for="(label, value) in mappingOptions"
              :key="value"
              class="row mb-3"
            >
              <label class="col-sm-4 col-lg-6 col-form-label">{{
                label
              }}</label>
              <div class="col-sm-8 col-lg-6">
                <select v-model="columnMappings[value]" class="form-select">
                  <option v-for="header in fileHeaders" :value="header">
                    {{ header }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-9">
        <div v-if="hasData" class="table-responsive">
          <h5>CSVデータプレビュー（10件まで表示）</h5>
          <table class="table table-bordered bg-white">
            <thead>
              <tr>
                <th
                  v-for="header in fileHeaders"
                  scope="col"
                  class="col-sm-1"
                  :class="columnMapped(header)"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, key) in fileContent" :key="key">
                <td v-for="value in line">
                  {{ value }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex'
import Navbar from '@/components/NavbarForm.vue'
import { toCamelCase, replaceNewlinesWithBr } from '@/utils/stringUtils'

export default {
  name: 'SupplierImport',
  components: {
    Navbar,
  },

  data() {
    return {
      selectedFile: null,
      selectedEncoding: 'utf-8',
      fileHeaders: [],
      fileContent: [],
      columnMappings: {},
      mappingOptions: {
        code: '仕入先コード',
        name: '仕入先名',
        kana: '仕入先名（ふりがな）',
        postalCode: '郵便番号',
        address: '住所',
        phoneNumber: '電話番号',
        email: 'メールアドレス',
        memo: 'メモ',
      },
      errorMessage: '',
    }
  },
  computed: {
    hasData() {
      return this.fileContent.length > 0
    },
  },

  methods: {
    toCamelCase,
    replaceNewlinesWithBr,
    ...mapActions({
      importData: 'supplier/importData',
    }),
    handleFileChange(event) {
      this.fileContent = []
      this.columnMappings = {}
      this.selectedFile = event.target.files[0]
      this.previewFile()
    },
    previewFile() {
      if (!this.selectedFile) return

      const reader = new FileReader()
      reader.onload = (loadEvent) => {
        const allText = loadEvent.target.result
        const lines = allText
          .split('\n')
          .filter((line) => line.trim().length > 0)
          .slice(0, 10)
          .map((line) => line.trim().split(','))
        this.fileHeaders = lines[0]
        this.fileContent = lines.slice(1)
      }
      reader.readAsText(this.selectedFile, this.selectedEncoding)
    },
    async formSubmit() {
      const formData = new FormData()
      formData.append('csv_file', this.selectedFile)
      formData.append('column_mappings', JSON.stringify(this.columnMappings))
      formData.append('encoding', this.selectedEncoding)

      try {
        await this.importData(formData)
        this.$router.push({
          name: 'SupplierList',
        })
      } catch (error) {
        if (Array.isArray(error.response.data.errors)) {
          this.errorMessage = `${error.response.data.errors.length}件のエラーがありました\n`
          this.errorMessage += this.formatErrors(error.response.data.errors)
        } else {
          this.errorMessage = error.response.data.detail
        }
      }
    },
    formatErrors(errors) {
      return errors
        .map((error) => {
          let messages = []
          for (let key in error.errors) {
            let label = this.mappingOptions[this.toCamelCase(key)]
            messages.push(`${label}: ${error.errors[key].join(', ')}`)
          }
          return `${error.line}行目: ${messages.join(', ')}`
        })
        .join('\n')
    },
    formReset() {
      this.$router.go({ path: this.$router.currentRoute.path, force: true })
    },
    columnMapped(value) {
      let count = 0

      const recursiveSearch = (innerObj) => {
        for (let key in innerObj) {
          if (count >= 2) return
          if (typeof innerObj[key] === 'object' && innerObj[key] !== null) {
            recursiveSearch(innerObj[key], value)
          } else if (innerObj[key] === value) {
            count++
          }
        }
      }
      recursiveSearch(this.columnMappings)

      if (count === 0) return 'text-danger'
      if (count === 1) return 'text-body'
      return 'text-warning'
    },
  },
}
</script>
