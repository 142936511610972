import { createStore } from 'vuex'
import operate_purchase_defective_item from './operate_purchase_defective_item'
import operate_purchase_defective_item_cause from './operate_purchase_defective_item_cause'

const store = createStore({
  actions: {
    clearAllStores({ dispatch }) {
      dispatch('operate_purchase_defective_item/clearAll', null, { root: true })
      dispatch('operate_purchase_defective_item_cause/clearAll', null, {
        root: true,
      })
    },
    clearDataStores({ dispatch }) {
      dispatch('operate_purchase_defective_item/clearAll', null, { root: true })
      dispatch('operate_purchase_defective_item_cause/clearAll', null, {
        root: true,
      })
    },
  },
  modules: {
    operate_purchase_defective_item,
    operate_purchase_defective_item_cause,
  },
})

export default store
