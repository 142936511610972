import SupplierHome from '@/views/supplier/Home.vue'
import SupplierList from '@/views/supplier/SupplierList.vue'
import SupplierDetail from '@/views/supplier/SupplierDetail.vue'
import SupplierImport from '@/views/supplier/SupplierImport.vue'

export default {
  routes: [
    {
      path: '/supplier',
      component: SupplierHome,
      name: 'SupplierHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'suppliers',
          component: SupplierList,
          name: 'SupplierList',
        },
        {
          path: 'suppliers/import',
          component: SupplierImport,
          name: 'SupplierImport',
        },
        {
          path: 'suppliers/:id([0-9]+)',
          component: SupplierDetail,
          name: 'SupplierDetail',
          props: (route) => ({ id: Number(route.params.id) }),
        },
      ],
    },
  ],
}
