<template>
  <CodeReader
    v-if="showCodeCamera"
    ref="codeReader"
    @decode="handleQRCodeData"
    @cancel="cancelCodeCamera"
    class="animate__animated animate__fadeIn"
  />

  <div v-else>
    <Navbar>
      <template v-slot:navbarTitle>
        <h1 class="h5 m-0">品目一覧</h1>
      </template>
      <template v-slot:navbarButton>
        <router-link
          :to="{
            name: 'AppHomeOperate',
          }"
          class="btn btn-dark"
          tabindex="-1"
          ><i class="bi bi-0-square me-1"></i>戻る</router-link
        >
      </template>
    </Navbar>

    <main id="main" class="bg-light">
      <div class="container py-3 py-lg-4">
        <div class="row row-cols-1 gy-2 gy-lg-3">
          <div class="col">
            <form class="card">
              <div class="card-body p-2 p-lg-3">
                <div class="input-group">
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    v-on:click.prevent="startCodeCamera"
                    tabindex="-1"
                  >
                    <i class="bi bi-qr-code"></i>
                  </button>
                  <input
                    v-model="query.q"
                    ref="searchBox"
                    type="text"
                    class="form-control"
                    id="q"
                    placeholder="品目コードまたは品目名"
                  />
                  <button
                    v-on:click.prevent="resetQuery()"
                    class="btn bg-white text-secondary border border-start-0"
                    type="button"
                    tabindex="-1"
                  >
                    <i class="bi bi-x"></i>
                  </button>
                  <button
                    type="submit"
                    class="btn btn-secondary"
                    v-on:click.prevent="getQueryResult()"
                    tabindex="-1"
                  >
                    <span class="d-block d-sm-none"
                      ><i class="bi bi-search d-block d-sm-none"></i
                    ></span>
                    <span class="d-none d-sm-block">検索</span>
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="col">
            <div v-if="items && items.length > 0">
              <div class="row row-cols-1 row-cols-md-2 g-1 g-lg-2 mb-3">
                <div v-for="(item, index) in items" :key="item.id" class="col">
                  <div class="card h-100">
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                        <div class="m-0">
                          <h5 class="card-subtitle mb-1">
                            <router-link
                              :ref="'link' + (index + 1)"
                              :to="{
                                name: 'OperateItemDetail',
                                params: { id: item.id },
                              }"
                              class="stretched-link text-decoration-none"
                              ><i
                                class="bi me-1"
                                :class="
                                  'bi-' + parseFloat(index + 1) + '-square'
                                "
                              ></i
                              >{{ item.code }}</router-link
                            >
                          </h5>
                          <h5 class="card-title m-0">
                            {{ item.name }}
                          </h5>
                          <h6 class="card-subtitle text-muted m-0">
                            {{ item.item_model }}&nbsp;
                          </h6>
                        </div>
                        <div>
                          <span v-if="item.has_image" class="badge bg-primary"
                            >画像あり</span
                          ><span v-else class="badge bg-secondary"
                            >画像なし</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="card-footer d-flex justify-content-end">
                      <div class="small text-end">
                        {{
                          item.classifications
                            ? item.classifications
                                .map((obj) => obj.name)
                                .join(', ')
                            : ''
                        }}&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center gap-2 mb-2">
                <Pagination
                  :prev="prev"
                  :next="next"
                  ref="pagination"
                  @move-page="movePage"
                />
              </div>
            </div>
            <div v-else class="alert alert-warning" role="alert">
              品目コードまたは品目名を入力してください
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CodeReader from '@/components/AppCodeReader.vue'
import Navbar from '@/components/operate/OperateNavbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'

export default {
  name: 'OperateItemList',
  components: {
    CodeReader,
    Navbar,
    Pagination,
  },

  data() {
    return {
      showCodeCamera: false,
    }
  },
  computed: {
    ...mapGetters({
      items: 'operate_item/results',
      prev: 'operate_item/prev',
      next: 'operate_item/next',
      query: 'operate_item/query',
    }),
  },

  mounted() {
    window.addEventListener('keydown', this.handleKeyDown)
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  },
  beforeRouteLeave(to, from, next) {
    // 詳細画面以外のページに移動する場合にデータをクリアする
    if (to.name !== 'OperateItemDetail') {
      this.resetQuery()
    }
    next()
  },

  methods: {
    ...mapActions({
      getData: 'operate_item/fetchAll',
    }),
    ...mapMutations({
      clearData: 'operate_item/clearData',
      setQuery: 'operate_item/setQuery',
      clearQuery: 'operate_item/clearQuery',
    }),
    startCodeCamera() {
      this.showCodeCamera = true
    },
    cancelCodeCamera() {
      this.showCodeCamera = false
    },
    async handleQRCodeData(result) {
      this.query.q = result
      await this.cancelCodeCamera()
      await this.getQueryResult()
    },
    movePage(page) {
      this.getData(Object.assign(this.query, { page: page }))
    },
    getQueryResult() {
      this.setQuery(this.query)
      this.getData()
      this.$refs.searchBox.blur()
    },
    resetQuery() {
      this.clearQuery()
      this.clearData()
    },
    setErrorMessage(key, errorData) {
      if (Array.isArray(errorData)) {
        this.formErrors[key] = errorData.join('\n')
      } else {
        this.formErrors[key] = errorData
      }
    },
    handleKeyDown(event) {
      if (document.activeElement.tagName.toLowerCase() === 'input') {
        return
      }
      switch (event.key) {
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6': {
          const linkRef = this.$refs['link' + event.key]
          if (linkRef && linkRef[0]) {
            linkRef[0].$el.click()
          }
          break
        }
        case '9':
          this.startCodeCamera()
          break
        case '0':
          this.$router.push({ name: 'AppHomeOperate' })
          break
        case '+':
          this.$refs.pagination.moveNext()
          break
        case '-':
          this.$refs.pagination.movePrev()
          break
        case 'Escape':
        case 'Backspace':
          this.$refs.codeReader.cancelStream()
          break
        default:
          break
      }
    },
  },
}
</script>
