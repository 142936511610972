<template>
  <NavbarForm @submit="formSubmit" @cancel="formCancel">
    <template v-slot:formTitle>ピッキングリスト作成</template>
  </NavbarForm>

  <main id="main" class="container-fluid py-3">
    <div class="clearfix">
      <div class="float-end">
        <button type="button" class="btn btn-link" @click="showPickingListForm">
          <i class="bi bi-plus-circle me-1"></i>品目を追加する
        </button>
      </div>
      <h5>品目</h5>
    </div>

    <div v-if="formData && formData.length > 0">
      <div class="table-responsive">
        <table class="table table-bordered bg-white">
          <thead>
            <tr>
              <th scope="col" class="col-sm-1">管理コード</th>
              <th scope="col" class="col-sm-1">納期</th>
              <th scope="col" class="col-sm-1">品目コード</th>
              <th scope="col" class="col-sm-1">型式</th>
              <th scope="col" class="col-sm-2">品目名</th>
              <th scope="col" class="col-sm-1">保管場所</th>
              <th scope="col" class="col-sm-1">指示数量</th>
              <th scope="col" class="col-sm-3">指示補足</th>
              <th scope="col" class="col-sm-1"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in formData" :key="index">
              <td scope="row" class="text-center">
                {{ item.code }}
              </td>
              <td class="text-center">{{ item.deadline }}</td>
              <td class="text-center">
                {{ item.stock?.code }}
              </td>
              <td class="text-center">
                {{ item.stock?.item_model }}
              </td>
              <td>{{ item.stock?.name }}</td>
              <td>{{ item.stock_location?.name }}</td>
              <td class="text-end">{{ item.quantity }}</td>
              <td>{{ item.memo }}</td>
              <td class="text-end">
                <a href="#" class="link-danger" @click="deleteItem(index)">
                  <i class="bi bi-trash3"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else class="mt-3">
      <p>品目を追加してください。</p>
    </div>

    <PickingListForm ref="pickingListForm" @afterSubmit="insertItem" />
  </main>
</template>

<script>
import store from '../store'
import NavbarForm from '@/components/NavbarForm.vue'
import PickingListForm from '../components/PickingListForm.vue'

export default {
  name: 'ManagePickingListCreate',
  components: {
    NavbarForm,
    PickingListForm,
  },

  data() {
    return {
      formData: [],
      formErrors: {},
      isLoading: false,
    }
  },

  methods: {
    async formSubmit() {
      try {
        this.formErrors = {}
        if (!this.formData || this.formData.length == 0) {
          this.$store.dispatch('alert/setErrorMessage', {
            message: '品目を追加してください。',
          })
          return
        }

        await store.dispatch('manage_picking_list/bulkPostData', this.formData)
        this.$store.dispatch('alert/setSuccessMessage', {
          message: '保存しました。',
        })
        window.setTimeout(async () => {
          await this.$router.push({
            name: 'ManagePickingList',
          })
        }, this.$store.getters['alert/timeout'])
      } catch (e) {
        if (e.response.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            this.setErrorMessage(key, e.response.data[key])
          })
        }
      }
    },
    formCancel() {
      this.$router.push({
        name: 'ManagePickingList',
      })
    },
    setErrorMessage(key, errorData) {
      if (Array.isArray(errorData)) {
        this.formErrors[key] = errorData.join('\n')
      } else {
        this.formErrors[key] = errorData
      }
    },
    showPickingListForm() {
      this.$refs.pickingListForm.show()
    },
    insertItem(item) {
      if (!this.formData) {
        this.formData = []
      }
      this.formData.push(item)
    },
    deleteItem(index) {
      this.formData.splice(index, 1)
    },
  },
}
</script>
