<template>
  <navbar>
    <template v-slot:navbarTitle>製品検査</template>
  </navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="d-flex gap-2 mb-3">
      <h5 class="m-0 p-0"><i class="bi bi-clipboard me-1"></i>検査シート</h5>
      <div class="ms-auto">
        <router-link
          :to="{
            name: 'ManageInspectionCreate',
          }"
          class="btn btn-primary"
        >
          <span class="d-block d-sm-none"><i class="bi bi-plus-lg"></i></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-plus-lg me-1"></i>検査シートを作成する</span
          >
        </router-link>
      </div>
    </div>

    <div class="d-flex justify-content-start gap-2 mb-2">
      <div class="me-auto col-6 col-lg-4 col-xl-3">
        <search-form
          :query="query"
          query-key="name"
          placeholder="シート名で検索"
          :use-search-modal="true"
          @update-search-query="onUpdateSearchQuery"
          @open-search-modal="onOpenSearchModal"
        ></search-form>
      </div>
      <pagination :prev="prev" :next="next" @move-page="movePage"></pagination>
    </div>

    <!-- inspections -->
    <div v-if="inspections && inspections.length > 0">
      <div class="table-responsive">
        <table class="table table-bordered bg-white">
          <thead>
            <tr>
              <th scope="col" class="col-sm-9">シート名</th>
              <th scope="col" class="col-sm-1">有効</th>
              <th scope="col" class="col-sm-2">更新日時</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="inspection in inspections" :key="inspection.id">
              <td scope="row">
                <router-link
                  :to="{
                    name: 'ManageInspectionDetail',
                    params: { id: inspection.id },
                  }"
                  >{{ inspection.name }}</router-link
                >
              </td>
              <td class="text-center">
                <ActiveStatus :is_active="inspection.is_active" />
              </td>
              <td class="text-end">{{ inspection.updated_at }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>

    <!-- Modal -->
    <search-modal
      :query="query"
      :items="searchItems"
      @update-search-query="onUpdateSearchQuery"
      ref="searchModal"
    ></search-modal>
  </main>
</template>

<script>
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import ActiveStatus from '@/components/TheActiveStatus.vue'
import SearchForm from '@/components/common/SearchForm.vue'
import SearchModal from '@/components/common/SearchModal.vue'

export default {
  name: 'ManageInspection',
  components: {
    Navbar,
    Pagination,
    ActiveStatus,
    SearchForm,
    SearchModal,
  },

  data() {
    return {
      query: {},
    }
  },

  computed: {
    inspections() {
      return store.getters['inspection/inspections']
    },
    prev() {
      return store.getters['inspection/prev']
    },
    next() {
      return store.getters['inspection/next']
    },
    saveQuery() {
      return store.getters['inspection/query']
    },
    searchItems() {
      return [
        {
          id: 'name',
          label: 'シート名（前方一致）',
          type: 'text',
        },
        {
          id: 'is_active',
          label: '有効',
          type: 'checkbox',
        },
      ]
    },
  },

  async created() {
    this.query = this.saveQuery
    await this.setQuery(this.query)
    await this.getData()
  },
  beforeRouteLeave(to, from, next) {
    // 詳細画面以外のページに移動する場合にデータをクリアする
    if (to.name !== 'ManageInspectionDetail') {
      this.clearQuery()
      this.clearData()
    }
    next()
  },

  methods: {
    getData() {
      return store.dispatch('inspection/fetchAll')
    },
    setQuery(query = {}) {
      store.commit('inspection/setQuery', query)
    },
    clearQuery() {
      store.commit('inspection/clearQuery')
    },
    clearData() {
      store.commit('inspection/clearData')
    },
    async movePage(page) {
      this.query = Object.assign(this.query, { page: page })
      await this.setQuery(this.query)
      await this.getData()
    },
    onOpenSearchModal() {
      this.$refs.searchModal.show()
    },
    async onUpdateSearchQuery(query = {}) {
      this.query = query
      await this.setQuery(this.query)
      await this.getData()
    },
  },
}
</script>
