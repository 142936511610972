<template>
  <Navbar>
    <template v-slot:navbarTitle>在庫管理</template>
  </Navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="d-flex gap-2 mb-3">
      <h5 class="m-0 p-0"><i class="bi bi-boxes me-1"></i>保管場所一覧</h5>
      <div class="ms-auto">
        <button
          type="button"
          v-on:click.prevent="showStockLocationForm()"
          class="btn btn-primary"
        >
          <span class="d-block d-sm-none"><i class="bi bi-plus-lg"></i></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-plus-lg me-1"></i>保管場所を登録する</span
          >
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-end gap-2 mb-2">
      <Pagination :prev="prev" :next="next" @move-page="movePage" />
    </div>

    <!-- locations -->
    <div v-if="locations && locations.length > 0">
      <data-table :headers="headers" :items="locations">
        <template #actions="{ item }">
          <a
            href="#"
            v-on:click.prevent="showStockLocationForm(item)"
            class="text-success text-decoration-none"
            >編集</a
          >｜<a
            v-on:click.prevent="removeData(item)"
            href="#"
            class="text-danger text-decoration-none"
            >削除</a
          >
        </template>
      </data-table>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>
    <DeleteDialog ref="confirmDataDelete" @confirm-true="confirmDataDelete" />

    <StockLocationForm ref="stockLocationForm" />
  </main>
</template>

<script>
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import DeleteDialog from '@/components/AppDeleteDialog.vue'
import DataTable from '@/components/common/DataTable.vue'
import StockLocationForm from '../components/StockLocationForm.vue'

export default {
  name: 'ManageStockLocationList',
  components: {
    Navbar,
    Pagination,
    DeleteDialog,
    DataTable,
    StockLocationForm,
  },

  data() {
    return {
      currentId: null,
      query: {},
      headers: [
        {
          key: 'name',
          label: '保管場所名',
          class: 'col-6',
          format: 'string',
        },
        {
          key: 'created_at',
          label: '作成日時',
          class: 'col-2',
          format: 'datetime',
        },
        {
          key: 'updated_at',
          label: '更新日時',
          class: 'col-2',
          format: 'datetime',
        },
        {
          key: 'actions',
          label: '',
          class: 'col-2',
          format: 'actions',
        },
      ],
    }
  },
  computed: {
    locations() {
      return store.getters['manage_stock_location/results']
    },
    prev() {
      return store.getters['manage_stock_location/prev']
    },
    next() {
      return store.getters['manage_stock_location/next']
    },
  },

  async created() {
    await this.getData()
  },

  methods: {
    getData(query = {}) {
      return store.dispatch('manage_stock_location/fetchAll', query)
    },
    deleteData(id) {
      return store.dispatch('manage_stock_location/deleteData', id)
    },
    movePage(page) {
      this.getData(Object.assign(this.query, { page: page }))
    },
    showStockLocationForm(formData) {
      this.$refs.stockLocationForm.show(formData)
    },
    removeData(object) {
      this.currentId = object.id
      this.$refs.confirmDataDelete.showDialog(
        '保管場所データ',
        object.name,
        [],
        ['在庫変動履歴']
      )
    },
    async confirmDataDelete() {
      try {
        await this.deleteData(this.currentId)
      } catch (e) {
        let message = '削除できませんでした。'
        if (e.response.status === 409) {
          message = 'この保管場所は使用しています。'
        }
        this.$store.dispatch('alert/setErrorMessage', {
          message: message,
        })
      }
      this.currentId = null
    },
  },
}
</script>
