<template>
  <div>
    <Navbar>
      <template v-slot:navbarTitle>仕入管理</template>
    </Navbar>

    <main id="main" class="container-fluid pt-3">
      <div class="d-flex gap-2 mb-3">
        <h5 class="m-0 p-0">
          <i class="bi bi-file-earmark-text me-1"></i>不良品集計
        </h5>
      </div>

      <div class="d-flex justify-content-start gap-2 mb-2">
        <div class="me-auto col-6 col-lg-4 col-xl-3">
          <div class="d-flex gap-0">
            <div class="col">
              <select
                v-model="query.detected_year"
                class="form-select border-secondary border-end-0"
                aria-label="年"
              >
                <option value="" disabled>年</option>
                <option v-for="year in years" :key="year" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>
            <div class="col">
              <select
                v-model="query.detected_month"
                class="form-select border-secondary border-end-0"
                aria-label="月"
              >
                <option value="" disabled>月</option>
                <option v-for="month in months" :key="month" :value="month">
                  {{ month }}
                </option>
              </select>
            </div>
            <div class="col">
              <button
                type="button"
                class="btn btn-outline-secondary"
                @click="getQueryResult"
              >
                <i class="bi bi-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div v-if="defectiveItems && Object.values(defectiveItems).length > 0">
          <div class="d-flex flex-row gap-1">
            <button
              type="button"
              class="btn btn-outline-secondary"
              :class="{ 'btn-secondary': graphDataType === 'item' }"
              @click="setGraphData"
            >
              品目別
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              :class="{ 'btn-secondary': graphDataType === 'supplier' }"
              @click="setGraphDataBySupplier"
            >
              仕入先別
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              :class="{ 'btn-secondary': graphDataType === 'cause' }"
              @click="setGraphDataByCause"
            >
              不良原因別
            </button>
          </div>
        </div>
      </div>

      <!-- defectiveItems -->
      <div v-show="defectiveItems && Object.values(defectiveItems).length > 0">
        <data-graph ref="dataGraph" dataset-label="不良品数"></data-graph>
      </div>
      <p
        v-if="!defectiveItems || Object.values(defectiveItems).length == 0"
        class="mt-3"
      >
        該当するデータはありません。
      </p>
    </main>
  </div>
</template>

<script>
import DataGraph from '@/components/common/DataGraph.vue';
import Navbar from '@/components/Navbar.vue';
import { computed, onMounted, ref, watch } from 'vue';
import store from '../store';

export default {
  name: 'ManagePurchaseDefectiveItemAggregate',
  components: {
    Navbar,
    DataGraph,
  },

  setup() {
    const query = ref({
      detected_year: '',
      detected_month: '',
    })
    const dataGraph = ref(null)
    const graphData = ref(null)
    const graphDataType = ref('item')
    const defectiveItems = computed(
      () => store.getters['manage_purchase_defective_item/list']
    )
    const years = computed(() => {
      const years = []
      const currentYear = new Date().getFullYear()
      for (let i = 0; i < 5; i++) {
        years.push(currentYear - i)
      }
      return years
    })
    const months = computed(() => {
      return Array.from({ length: 12 }, (v, k) => k + 1)
    })

    onMounted(() => {
      window.scrollTo(0, 0)
    })

    watch(graphData, (newVal) => {
      if (newVal) {
        dataGraph.value.updateChart(graphData.value)
      }
    })

    const getData = async (query = {}) => {
      await store.dispatch(
        'manage_purchase_defective_item/fetchAggregate',
        query
      )
    }

    const getQueryResult = async () => {
      if (!query.value.detected_year || !query.value.detected_month) {
        return
      }

      await getData(query.value)
      await setGraphData()
    }

    const setGraphData = () => {
      const items = defectiveItems.value
      const data = {}
      items.forEach((item) => {
        if (data[item.item_id]) {
          data[item.item_id].count += 1
        } else {
          data[item.item_id] = {
            name: item.item_name,
            count: 1,
          }
        }
      })

      graphDataType.value = 'item'
      graphData.value = data
    }

    const setGraphDataBySupplier = () => {
      const items = defectiveItems.value
      const data = {}
      items.forEach((item) => {
        if (item.supplier_id === null) {
          item.supplier_name = '未登録'
        }
        if (data[item.supplier_id]) {
          data[item.supplier_id].count += 1
        } else {
          data[item.supplier_id] = {
            name: item.supplier_name,
            count: 1,
          }
        }
      })

      graphDataType.value = 'supplier'
      graphData.value = data
    }

    const setGraphDataByCause = () => {
      const items = defectiveItems.value
      const data = {}
      items.forEach((item) => {
        if (data[item.cause_id]) {
          data[item.cause_id].count += 1
        } else {
          data[item.cause_id] = {
            name: item.cause,
            count: 1,
          }
        }
      })

      graphDataType.value = 'cause'
      graphData.value = data
    }

    return {
      query,
      defectiveItems,
      years,
      months,
      dataGraph,
      graphDataType,
      getQueryResult,
      setGraphData,
      setGraphDataBySupplier,
      setGraphDataByCause,
    }
  },
}
</script>
