import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  detail: {},
  inspection_id: null,
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  detail: (state) => state.detail,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
  setInspectionId: (state, inspection_id) =>
    (state.inspection_id = inspection_id),
  clearInspectionId: (state) => (state.inspection_id = null),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  /**
   * すべてのデータをリセットする
   *
   * @return {void}
   */
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearDetail')
      commit('clearInspectionId')
      resolve()
    })
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {object}
   */
  async postData({ rootState }, form_data) {
    const inspection_id = rootState.inspection.detail.id
    const response = await api.post(
      'manage/inspections/' + inspection_id + '/items/',
      form_data
    )
    return response
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {object}
   */
  async patchData({}, form_data) {
    const response = await api.patch(
      'manage/inspection_items/' + form_data.id + '/',
      form_data
    )
    return response.data
  },
  /**
   * データをまとめて処理する
   *
   * @param {array}
   * @return {void}
   */
  async bulkPostOrPatch({ dispatch }, array_data) {
    // 必要なデータのみにする
    const required_keys = [
      'id',
      'original_id',
      'position',
      'type',
      'title',
      'image',
      'attention',
      'success_options',
      'failure_options',
    ]
    let submit_data = {}

    array_data.forEach(async (form_data, i) => {
      form_data.position = i
      submit_data = Object.fromEntries(
        Object.entries(form_data).filter(([key, value]) =>
          required_keys.includes(key)
        )
      )

      if ('id' in submit_data && submit_data.id) {
        await dispatch('patchData', submit_data)
      } else {
        await dispatch('postData', submit_data)
      }
    })
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {void}
   */
  async deleteData({}, id) {
    await api.delete('manage/inspection_items/' + id + '/')
  },
  /**
   * データをまとめて削除する
   *
   * @param {array}
   * @return {void}
   */
  async bulkDelete({ dispatch }, ids) {
    ids.forEach(async (id) => {
      await dispatch('deleteData', id)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
