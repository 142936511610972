import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import VueQRCodeComponent from 'vue-qrcode-component-v3'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootswatch/dist/yeti/bootstrap.min.css'

import 'vue-multiselect/dist/vue-multiselect.css'

import 'animate.css/animate.min.css'

import dayjs from 'dayjs'
import 'dayjs/locale/ja'
// ロケール設定
dayjs.locale('ja')

const app = createApp(App)
app.use(router)
app.use(store)
app.use(VueCookies, {
  expireTimes: '1d',
  path: '/',
  domain: '',
  secure: true,
  sameSite: 'Lax',
})
app.provide('dayjs', dayjs)
app.component('qr-code', VueQRCodeComponent)
app.mount('#app')
