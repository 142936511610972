import ExportDataHome from '@/views/export_data/Home.vue'
import ExportDataList from '@/views/export_data/ExportDataList.vue'

export default {
  routes: [
    {
      path: '/export',
      component: ExportDataHome,
      name: 'ExportDataHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'list',
          component: ExportDataList,
          name: 'ExportDataList',
        },
      ],
    },
  ],
}
